
import React from "react";

function TermsandC() {
    return(
        <div className="privacypolicysection">
            <div className="container">
                <h2 className="posttitle">AGB</h2>
                <div className="article-post">
						<p>Mit dem Download oder der Nutzung der App gelten diese Bedingungen automatisch für Sie - Sie sollten sie daher vor der Nutzung der App sorgfältig lesen. Es ist Ihnen nicht gestattet, die App, Teile der App oder unsere Markenzeichen in irgendeiner Weise zu kopieren oder zu verändern. Sie dürfen nicht versuchen, den Quellcode der App zu extrahieren, und Sie sollten auch nicht versuchen, die App in andere Sprachen zu übersetzen oder abgeleitete Versionen zu erstellen. Die App selbst und alle damit verbundenen Marken, Urheberrechte, Datenbankrechte und sonstigen geistigen Eigentumsrechte gehören weiterhin Max Rosenblattl.</p>
						<p>Max Rosenblattl setzt sich dafür ein, dass die App so nützlich und effizient wie möglich ist. Aus diesem Grund behalten wir uns das Recht vor, jederzeit und ohne Angabe von Gründen Änderungen an der App vorzunehmen oder deren Dienste in Rechnung zu stellen. Wir werden Ihnen niemals Gebühren für die App oder ihre Dienste in Rechnung stellen, ohne Ihnen genau zu erklären, wofür Sie bezahlen.</p>
						<p>Die Simple Vegan-App speichert und verarbeitet personenbezogene Daten, die Sie uns zur Verfügung gestellt haben, um meinen Service bereitzustellen. Es liegt in Ihrer Verantwortung, Ihr Telefon und den Zugriff auf die App sicher zu halten. Wir empfehlen Ihnen daher, Ihr Telefon nicht zu jailbreaken oder zu rooten, d. h. Softwarebeschränkungen und -einschränkungen aufzuheben, die durch das offizielle Betriebssystem Ihres Geräts auferlegt werden. Es könnte Ihr Telefon anfällig für Malware/Viren/Schadprogramme machen, die Sicherheitsfunktionen Ihres Telefons beeinträchtigen und es könnte bedeuten, dass die Simple Vegan App nicht richtig oder gar nicht funktioniert.</p>
						<p>Sie sollten sich bewusst sein, dass es bestimmte Dinge gibt, für die Max Rosenblattl keine Verantwortung übernimmt. Bestimmte Funktionen der App setzen voraus, dass eine aktive Internetverbindung besteht. Die Verbindung kann Wi-Fi sein oder von Ihrem Mobilfunkanbieter bereitgestellt werden, aber Max Rosenblattl kann keine Verantwortung dafür übernehmen, dass die App nicht in vollem Umfang funktioniert, wenn Sie keinen Zugang zu Wi-Fi haben und Ihr Datenvolumen aufgebraucht ist.</p>
						<p>Wenn Sie die App außerhalb eines Gebiets mit Wi-Fi nutzen, sollten Sie daran denken, dass die Vertragsbedingungen Ihres Mobilfunkanbieters weiterhin gelten. Infolgedessen können Ihnen von Ihrem Mobilfunkanbieter die Kosten für die Daten für die Dauer der Verbindung während des Zugriffs auf die App oder andere Gebühren von Dritten in Rechnung gestellt werden. Mit der Nutzung der App akzeptieren Sie die Verantwortung für solche Gebühren, einschließlich Roaming-Datengebühren, wenn Sie die App außerhalb Ihres Heimatgebiets (d. h. Ihrer Region oder Ihres Landes) nutzen, ohne das Daten-Roaming zu deaktivieren. Wenn Sie nicht der Rechnungszahler für das Gerät sind, auf dem Sie die App verwenden, beachten Sie bitte, dass wir davon ausgehen, dass Sie die Erlaubnis des Rechnungszahlers für die Nutzung der App erhalten haben.</p>
						<p>Gleichermaßen kann Max Rosenblattl nicht immer die Verantwortung für die Art und Weise übernehmen, wie Sie die App nutzen, d.h. Sie müssen sicherstellen, dass Ihr Gerät geladen bleibt - wenn der Akku leer ist und Sie es nicht einschalten können, um den Service zu nutzen, kann Max Rosenblattl keine Verantwortung übernehmen.</p>
						<p>Im Hinblick auf die Verantwortung von Max Rosenblattl für Ihre Nutzung der App ist es wichtig, dass Sie bei der Nutzung der App bedenken, dass wir uns zwar bemühen, die App stets aktuell und korrekt zu halten, dass wir uns aber auf Dritte verlassen, die uns Informationen zur Verfügung stellen, damit wir Ihnen diese zur Verfügung stellen können. Max Rosenblattl übernimmt keine Haftung für direkte oder indirekte Schäden, die Ihnen dadurch entstehen, dass Sie sich ausschließlich auf diese Funktionalität der App verlassen.</p>
						<p>Zu einem bestimmten Zeitpunkt werden wir die App möglicherweise aktualisieren wollen. Die App ist derzeit auf iOS und Android verfügbar - die Anforderungen für dieses System (und für weitere Systeme, auf die wir die Verfügbarkeit der App ausweiten wollen) können sich ändern, und Sie müssen die Updates herunterladen, wenn Sie die App weiterhin nutzen wollen. Max Rosenblattl verspricht nicht, die App immer so zu aktualisieren, dass sie für Sie relevant ist und/oder mit der iOS oder Android Version funktioniert, die Sie auf Ihrem Gerät installiert haben. Sie versprechen jedoch, Aktualisierungen der App immer anzunehmen, wenn sie Ihnen angeboten werden. Wir können auch die Bereitstellung der App einstellen und können die Nutzung der App jederzeit beenden, ohne Sie davon in Kenntnis zu setzen. Sofern wir Ihnen nichts anderes mitteilen, enden bei einer Beendigung (a) die Ihnen in diesen Bedingungen gewährten Rechte und Lizenzen; (b) Sie müssen die Nutzung der App einstellen und sie (falls erforderlich) von Ihrem Gerät löschen.</p>
						<h3>Änderungen an diesen Bedingungen</h3>
						<p>Die Allgemeinen Geschäftsbedingungen können von Zeit zu Zeit aktualisiert werden. Wir empfehlen Ihnen daher, diese Seite regelmäßig auf Änderungen zu überprüfen. Ich werde Sie über alle Änderungen informieren, indem ich die neuen Geschäftsbedingungen auf dieser Seite veröffentliche. Diese Änderungen sind sofort nach ihrer Veröffentlichung auf dieser Seite wirksam.</p>
						<h3>Kontaktieren Sie uns</h3>
						<p>Wenn Sie Fragen oder Anregungen zu meinen Allgemeinen Geschäftsbedingungen haben, zögern Sie nicht, mich unter support@simpleveganapp.com zu kontaktieren.</p>

					</div>
        	</div>
      </div>
    );
}

export default TermsandC;