
import React from "react";

function Disclaimer() {
    return(
        <div className="privacypolicysection">
            <div className="container">
                <h2 className="posttitle">Disclaimer</h2>
				<h3>General</h3>
				<p>Welcome to Simple Vegan (“the Application”).</p>
				<p>The Application is brought to you by Max Rosenblattl (“the Company”)</p>
				<h3>Contents of the Application</h3>
				<p>All information contained, posted and/or displayed on the Application is for information purposes and entertainment only.</p>
				<p>The Company is not and will not be liable, either expressly or in an implied manner, and is not responsible for any physical and/or emotional problems that may and/or could occur from any of the information on the application.</p>
				<h3>Disclaimer of warranties</h3>
				<p>You agree that the use of the application is at your sole risk. All information and content are provided on an „as is“ and „as available“ basis.</p>
				<p>The Company, its affiliates, agents and licensors cannot and do not warrant the accuracy, completeness, correctness, non-infringement, merchantability, or fitness for a particular purpose of the information, materials and/or content available through the Application.</p>
				<p>The Company does not guarantee the Application to be error-free, secure, continuously available or free of viruses or other harmful components.</p>
				<p>You also agree that if you rely on any data or information obtained through the Application, you do so at your own risk. You are solely responsible for any damage or loss that results from your use of any material and/or data.</p>
				<p>The Company and the contents and/or information on the Application are provided with the understanding that neither the Company nor its users, while such users are participating in the Application, are engaged in rendering legal, medical, counselling or other professional services or advice. The contents and/or information on the Application are not substitute for professional services or advice.</p>
				<p>The Company, its affiliates, agents, and licensors make no warranty regarding any goods or services referred to, advertised on, or obtained through the application, other than those express warranties the Company itself specifically makes.</p>
				<h3>Limitation of liability</h3>
				<p>Under no circumstances will the Company or its affiliates, agents or licensors be liable to you or anyone else for including but not limited to any loss and/or damages arising out of your use of any of the Application, including, without limitation, liability for consequential, special, incidental, indirect, or similar damages, even if we are advised beforehand of the possibility of such damages.</p>
				<h3>Indemnification</h3>
				<p>In the event that the Company becomes a party to any third party proceedings, you agree to indemnify, defend and hold harmless, without limitations, the Company, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of without limitations, the negligence, fault, mistake, misrepresentation and/or fraud of third party.
				</p>
				<p>Effective as of 01.12.2019.</p>
        	</div>
      </div>
    );
}

export default Disclaimer;