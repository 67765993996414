
import Applelogo from '../assets/apple.webp';
import Appswelove from '../assets/appswelove.webp';
import Review from '../assets/review.webp';
import Apppv from '../assets/simpleveganmob.webp';
import {BrowserRouter as Router, Link} from 'react-router-dom';
function Main() {
  return (
    <div className="main">
      <div className="bg"></div>
      <div className="content">
        <div className="left-section">
          <h1>Vegan living. Simplified.</h1>
          <p>Simple Vegan makes your vegan transition as smooth as possible</p>
          <div className="buttons">
            <Link class="footerlink" to="/links" onClick={()=>window.scrollTo(0, 0)}>
              <button className="download" href="">Download</button>
            </Link>
            <button className="readmore" onClick={()=>window.scrollTo({top: 900,left: 0,behavior: 'smooth'})}>Read more</button>
          </div>
          <div className="achievement">
            <img src={Applelogo} alt="" className="apple" />
            <img src={Appswelove} alt="" className="nawl" />
            <img src={Review} alt="" className="reviews" />
          </div>
        </div>
        <div className="right-section">
          <div className="mainimg">
            <img src={Apppv} alt="" className="apppreview" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Main;