
import React from "react";
import "./links.css";
import SVIcon from "../assets/iconsv.webp";
import DownloadApple from "../assets/downloadas.webp";
import DownloadPS from "../assets/playstoredownload.webp";
import {BrowserRouter as Router, Link} from 'react-router-dom';

function Links() {
  return (
    <div className="links">
        <body className="pink">
            <div className="container">
                <Link class="footerlink" to="/" onClick={()=>window.scrollTo(0, 0)}>
                    <img className="avatar" src={SVIcon} alt="SimpleVegan Icon" />
              </Link>
              <h1>Simple Vegan</h1>
              <p className="username">@simplevegan</p>
              <ul>
                <li>
                  <div className="DownloadASL" href="http://apple.co/33wVQHM">
                        <a href="http://apple.co/33wVQHM"><img className="downloadas1" src={DownloadApple} background="white"/></a>
                  </div>
                </li>
                <li>
                  <div className="DownloadPL" href="">
                        <a href="https://play.google.com/store/apps/details?id=com.outson.simplevegan"><img className="playstore" src={DownloadPS}    /></a>
                  </div>
                </li>

                 {/* <li>
                  <a href="https://www.instagram.com/simpleveganapp" target="_blank">📷 Instagram</a>
                </li> */}

              </ul> 

              <footer>
                Made with 🌱 in Austria
              </footer>
            </div>
        </body>
    </div>
  );
}

export default Links;
