import React from 'react';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import { FaInstagram, FaPinterest, FaTwitter, FaTiktok } from 'react-icons/fa';
import { IconContext } from "react-icons";
import AppleDownload from "../assets/downloadas.webp";



function Footer() {
  return (
    <footer>
            <div class="row footersv">
                <div class="footerleft">
                    <div class="footmenu">
                        <a class="footermain">Help</a>
                        <Link class="footerlink" to="/support" onClick={()=>window.scrollTo(0, 0)}>
                          Support
                        </Link>
                    </div>
                    <div class="footmenu2">
                        <a class="footermain">Legal</a>
                        <Link class="footerlink" to="/terms-conditions" onClick={()=>window.scrollTo(0, 0)}>
                          Terms of Service
                        </Link>
                        <Link class="footerlink" to="/privacy-policy" onClick={()=>window.scrollTo(0, 0)}>
                          Privacy Policy
                        </Link>
                    </div>
                </div>
                <div class="footerright">
                    <div class="socialicons">
                      <IconContext.Provider value={{ color: "#0d4137", size: '25px' }} className="footericons">
                        <a href="https://www.instagram.com/simpleveganapp/"><FaInstagram/></a>
                        <a href="https://www.pinterest.at/SimpleVeganApp/"><FaPinterest/></a>
                        <a href="https://twitter.com/SimpleVeganApp"><FaTwitter/></a>
                        <a href="https://www.tiktok.com/@simpleveganapp?"><FaTiktok/></a>
                      </IconContext.Provider>
                    </div>
                </div>
              </div>
            <div class="endsection">
                <p class="madefrommobile">Made with ♡ in Austria</p>
                <p class="Copyright">Copyright © 2021 Simple Vegan. All rights reserved.</p>
                <p class="madefrom">Made with ♡ in Austria</p>
            </div>
    </footer>
  )
}
export default Footer;