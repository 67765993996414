import React, { useEffect, useState, useCallback, useRef } from "react";
import emailjs from "@emailjs/browser";

function Support() {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  function SendEmail(e) {
    e.preventDefault();
    document.getElementById("supportform").reset();
    setConfirm(false);
    setLoading(true);
    emailjs
      .sendForm(
        "service_ozny1ye",
        "template_c4u5w0s",
        e.target,
        "2xftwIyNrzD1XJFAm"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          setConfirm(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className="supportsection">
      <div className="container">
        <h1>Support</h1>

        <p>
          Send us an email:
          <a href="mailto: support@simpleveganapp.com">
            support@simpleveganapp.com
          </a>
        </p>
        {/* <form id="supportform" onSubmit={SendEmail}>
          <label htmlFor="name">Name</label>
          <input type="text" id="fname" name="name" placeholder="Your name.." />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="your@email.com"
          />

          <label htmlFor="subject">Subject</label>
          <textarea
            id="subject"
            name="subject"
            placeholder="Write something.."
          ></textarea>
          <div className="loading">
            <input type="submit" value="Submit" />
            {loading ? <div class="clock"></div> : ""}
            {confirm ? (
              <div class="swal-icon swal-icon--success">
                <span class="swal-icon--success__line swal-icon--success__line--long"></span>
                <span class="swal-icon--success__line swal-icon--success__line--tip"></span>
                <div class="swal-icon--success__ring"></div>
                <div class="swal-icon--success__hide-corners"></div>
              </div>
            ) : (
              ""
            )}
          </div>
        </form> */}
      </div>
    </div>
  );
}

export default Support;
