import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./Header/header";
import Main from "./Main/main";
import Features from "./Features/features";
import Social from "./Social/social";
import Footer from "./Footer/footer";
import Support from "./Support/support";
import ToS from "./Legal/terms-conditions";
import PrivacyPolicy from './Legal/privacy-policy';
import Disclaimer from './Legal/disclaimer';
import DisclaimerEn from './Legal/disclaimer-en';
import DisclaimerDe from './Legal/disclaimer-de';
import LicensesDe from './Legal/licenses-de';
import LicensesEn from './Legal/licenses-en';
import PrivacyPolicyDe from './Legal/privacy-policy-de';
import PrivacyPolicyEn from './Legal/privacy-policy-en';
import TermsDe from './Legal/terms-de';
import TermsEn from './Legal/terms-en';
import Links from './Legal/links';

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
              <Route exact path='/' element={
                <main>
                <Header />
                <Main />
                <Features />
                <Social />
                <Footer />
                </main>
              }/>
              <Route exact path='/support' element={
                <support>
                <Header />
                <Support/>
                <Footer />
              </support>
              }/>
              <Route exact path='/terms-conditions' element={
                <legal>
                  <Header />
              <ToS/>
              <Footer />
                </legal>
              }/>
              <Route exact path='/privacy-policy' element={
                <legal>
                  <Header />
              <PrivacyPolicy/>
              <Footer />
              </legal>
              }/>
              <Route exact path='/disclaimer' element={
              <Disclaimer/>
              }/>
              <Route exact path='/disclaimer-en' element={
              <DisclaimerEn/>
              }/>
              <Route exact path='/disclaimer-de' element={
              <DisclaimerDe/>
              }/>
              <Route exact path='/licenses-de' element={
              <LicensesDe/>
              }/>
              <Route exact path='/licenses-en' element={
              <LicensesEn/>
              }/>
              <Route exact path='/privacy-policy-de' element={
              <PrivacyPolicyDe/>
              }/>
              <Route exact path='/privacy-policy-en' element={
              <PrivacyPolicyEn/>
              }/>
              <Route exact path='/privacypolicy-de' element={
              <PrivacyPolicyDe/>
              }/>
              <Route exact path='/privacypolicy-en' element={
              <PrivacyPolicyEn/>
              }/>
              <Route exact path='/terms-de' element={
              <TermsDe/>
              }/>
              <Route exact path='/terms-en' element={
              <TermsEn/>
              }/>
              <Route exact path='/links' element={
                  <Links/>
                  }/>
            </Routes>
      </Router>
    </div>
  );
}

export default App;
