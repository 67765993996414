
import Trees from '../assets/trees.webp';
import Ecobag from '../assets/eco-bag.webp';
import Lamp from '../assets/lamp.webp';
import Travel from '../assets/travel.webp';

function Features() {
  return (
    <div className="features aboutsection">
        <h2>What is Simple Vegan?</h2>
        <div class="features">
            <li width="200px">
                <img class="imgabout" src={Trees} height="170px"/>
                <h3>Delicious recipes</h3>
                <p>Browse tons of step-by-step recipes by category. Find delicious new vegan dishes you can make from the comfort of your own home.</p>
            </li>
            <li>
                <img class="imgabout" src={Ecobag} height="170px"/>
                <h3>Adaptive meal plan</h3>
                <p>We will create an individual meal plan adjusted to your needs. You will be provided with the nutritional values for all of the planned recipes.</p>
            </li>
            <li>
                <img class="imgabout" src={Lamp} height="170px"/>
                <h3>Guides and tips</h3>
                <p>Learn why to go vegan & what to eat. Find tips for changing your eating habits and living a healthy vegan lifestyle.</p>
            </li>
            <li>
                <img class="imgabout" src={Travel} height="170px"/>
                <h3>Journaling</h3>
                <p>Keep track of your vegan journey and reveal the positive impact your switch to vegan has had on the environment.</p>
            </li>
        </div>
    </div>
  )
}
export default Features;