import React from 'react';
import Logo from '../assets/logo.webp';
import {BrowserRouter as Router, Link} from 'react-router-dom';


function Header() {
  return (
      <header className="sv-header">
          <div className="navmenu">
            <Link to="/">
              <img className="mainlogo" src={Logo} alt="" />
            </Link>
            <div className="navitems">
              <Link to="/support">
                <a href="">Support</a>
              </Link>
            </div>
          </div>
      </header>
  )
}
export default Header;