
import React from "react";

function PrivacyPolicyDe() {
    return(
        <div className="privacypolicysection">
            <div className="container">
                <h1>Datenschutzerklärung</h1>
				<br/><p>Diese Anwendung erhebt personenbezogene Daten von ihren Nutzern.</p>
				<p>Dieses Dokument kann zu Zwecken der Aufbewahrung &uuml;ber den Befehl &bdquo;Drucken&ldquo; im Browser ausgedruckt werden.</p>
				<p>Zusammenfassung der Datenschutzerkl&auml;rung</p>
				<p>Personenbezogene Daten, die zu folgenden Zwecken und unter Verwendung folgender Dienste erhoben werden:</p>
				<br/><p>Analytik</p>
				<p>Google Analytics und Google Analytics mit IP-Anonymisierung</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<p>Google Analytics Funktionen f&uuml;r Werbeberichte</p>
				<p>Personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Google Analytics: Berichte zur Leistung nach demografischen Merkmalen und Interessen</p>
				<p>Personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Tracker</p>
				<p>Google Analytics for Firebase</p>
				<p>Personenbezogene Daten: Anwendung-Ausf&uuml;hrungen; Anwendung-Updates; Anzahl der Nutzer; Anzahl der Sitzungen; Betriebssysteme; Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); erstmalige Starts; Ger&auml;teinformationen; In-App-K&auml;ufe; Nutzungsdaten; Region; Sitzungsdauer; Tracker</p>
				<br/><p>Beta-Tests</p>
				<p>App Center</p>
				<p>Personenbezogene Daten: E-Mail; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten</p>
				<p>Beta by Crashlytics</p>
				<p>Personenbezogene Daten: E-Mail; Geburtsdatum; Ger&auml;teinformationen; Ger&auml;telogs; Geschlecht; Informationen &uuml;ber die Anwendung; Land; Nachname; Nutzername; Nutzungsdaten; Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten</p>
				<p>Google Play Beta Testing</p>
				<p>Personenbezogene Daten: E-Mail; Geburtsdatum; Ger&auml;teinformationen; Ger&auml;telogs; Geschlecht; Land; Nachname; Nutzername; Nutzungsdaten; Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten</p>
				<p>TestFlight</p>
				<p>Personenbezogene Daten: E-Mail; Ger&auml;teinformationen; Ger&auml;telogs; Geschlecht; Informationen &uuml;ber die Anwendung; Land; Nachname; Nutzername; Nutzungsdaten; Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten</p>
				<p>Daten&uuml;bermittlung au&szlig;erhalb der EU</p>
				<p>Daten&uuml;bermittlung ins Ausland aufgrund einer Einwilligung, Daten&uuml;bermittlung ins Ausland auf Basis von Standardvertragsklauseln, Daten&uuml;bermittlung in L&auml;nder, die europ&auml;ische Standards gew&auml;hrleisten und Weitere Rechtsgrundlagen f&uuml;r den Datentransfer ins Ausland</p>
				<p>Personenbezogene Daten: verschiedene Datenarten</p>
				<p>Ger&auml;teberechtigungen f&uuml;r den Zugriff auf personenbezogene Daten</p>
				<p>Ger&auml;teberechtigungen f&uuml;r den Zugriff auf personenbezogene Daten</p>
				<p>Personenbezogene Daten: Berechtigung App Erinnerungen; Berechtigung zum Aktualisieren von Gesundheitsdaten ; Fotobibliothekberechtigung; Kameraberechtigung; Kameraberechtigung, ohne Speichern oder Aufzeichnen; Leseberechtigung f&uuml;r Gesundheitsdaten; Speicherberechtigung</p>
				<br/><p>Hosting und Backend-Infrastruktur</p>
				<p>Firebase Cloud Functions, Firebase Cloud Firestore, Firebase Cloud Storage und Firebase Realtime Database</p>
				<p>Personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Firebase Hosting</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<br/><p>Interaktion mit externen sozialen Netzwerken und Plattformen</p>
				<p>Tweet-Schaltfl&auml;che und soziale Widgets f&uuml;r Twitter, &bdquo;Gef&auml;llt mir&ldquo;-Schaltfl&auml;che und soziale Widgets f&uuml;r Facebook und &bdquo;Pin it&ldquo;-Schaltfl&auml;che und soziale Widgets f&uuml;r Pinterest</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<p>Kommerzielle Bindung</p>
				<p>Amazon Affiliation</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<p>Kontaktieren des Nutzers</p>
				<p>Kontaktformular</p>
				<p>Personenbezogene Daten: E-Mail; Nachname; Vorname</p>
				<br/><p>Mailingliste oder Newsletter</p>
				<p>Personenbezogene Daten: E-Mail; Geburtsdatum; Geschlecht; Land; Nachname; Vorname</p>
				<br/><p>Plattform-Dienste und Hosting</p>
				<p>Apple App Store und Google Play Store</p>
				<p>Personenbezogene Daten: Nutzungsdaten</p>
				<br/><p>Registrierung und Anmeldung</p>
				<p>Firebase Authentication</p>
				<p>Personenbezogene Daten: E-Mail; Konten in den sozialen Netzwerken; Nachname; Nutzername; Passwort; Profilbild; Telefonnummer; Vorname</p>
				<p>Facebook Authentication, Google OAuth und Instagram Authentication</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Gmail-Berechtigungen f&uuml;r den Zugriff auf Nutzerdaten (OAuth-Zusatz)</p>
				<p>Personenbezogene Daten: Einf&uuml;gen und Importieren; Metadaten lesen; Modifizieren; Nur Lesezugriff; Verfassen; Verwaltung der Grundeinstellungen; Verwaltung von sensiblen Einstellungen; Voller Zugriff</p>
				<p>Mit Apple anmelden</p>
				<p>Personenbezogene Daten: Benutzerkennung; E-Mail; Nachname; Telefonnummer; Vorname</p>
				<p>Registrierung und Anmeldung unmittelbar &uuml;ber diese Anwendung</p>
				<p>Direkte Registrierung</p>
				<p>Personenbezogene Daten: Adresse; E-Mail; Geburtsdatum; Geschlecht; Land; Nachname; Nutzername; Nutzungsdaten; Passwort; Profilbild; Sprache; Stadt; Vorname</p>
				<br/><p>Remarketing und Behavioural-Targeting</p>
				<p>AdRoll</p>
				<p>Personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Einkaufsverlauf; Ger&auml;teinformationen; Nutzungsdaten; Tracker; verschiedene Datenarten</p>
				<p>Facebook Remarketing, Google Ads Remarketing, Google Ad Manager Audience Extension und Google Signals</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<p>Facebook Custom Audience</p>
				<p>Personenbezogene Daten: E-Mail; Tracker</p>
				<br/><p>Soziale Funktionen</p>
				<p>Firebase Dynamic Links und Firebase Invites</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Einladen und Vorschlagen von Freunden</p>
				<p>Personenbezogene Daten: verschiedene Datenarten</p>
				<br/><p>&Ouml;ffentliches Profil</p>
				<p>Personenbezogene Daten: Bild; Geschlecht; Nachname; Nutzername; Vorname</p>
				<br/><p>Standortbezogene Interaktionen</p>
				<p>Geolocation und Nicht kontinuierliche Geolokalisierung</p>
				<p>Personenbezogene Daten: Geografische Position</p>
				<p>Testen der Performance von Inhalten und Funktionen (A/B-Test)</p>
				<p>Firebase Remote Config</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Google Optimize und Google Optimize 360</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<br/><p>Umgang mit Aktivit&auml;tsdaten</p>
				<p>Apple HealthKit</p>
				<p>Personenbezogene Daten: Aktivit&auml;t bez&uuml;glich Schlaf; Aktivit&auml;ten bez&uuml;glich Bewegung; Aktivit&auml;ten bez&uuml;glich Ern&auml;hrung; Allgemeine Aktivit&auml;tsdaten; Blutgruppe; Geburtsdatum; Geschlecht; K&ouml;rperma&szlig;e &amp; Indizes</p>
				<p>Vom Ger&auml;t aufgezeichnete Aktivit&auml;tsdaten</p>
				<p>Personenbezogene Daten: Aktivit&auml;ten bez&uuml;glich Ern&auml;hrung; Allgemeine Aktivit&auml;tsdaten; Geburtsdatum; Geschlecht; K&ouml;rperma&szlig;e &amp; Indizes</p>
				<br/><p>Umgang mit Zahlungen</p>
				<p>Apple Pay und PayPal</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Verwalten von Kontakten und Versenden von Nachrichten</p>
				<p>Firebase Cloud Messaging und Firebase Notifications</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<br/><p>Verwalten von RSS-Feeds</p>
				<p>Feedburner</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<br/><p>Verwaltung von Datenerfassung und Online-Umfragen</p>
				<p>Facebook lead ads</p>
				<p>Personenbezogene Daten: Adresse; E-Mail; Geburtsdatum; Geschlecht; Land; Nachname; Nutzungsdaten; Staat; Stadt; Telefonnummer; Tracker; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten</p>
				<br/><p>Werbung</p>
				<p>Google Ad Manager, Google AdSense und Google Ads &auml;hnliche Zielgruppen</p>
				<p>Personenbezogene Daten: Nutzungsdaten; Tracker</p>
				<p>AdMob</p>
				<p>Personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Nutzungsdaten; Tracker</p>
				<p>Direktwerbung</p>
				<p>Personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Ger&auml;teinformationen; Nutzungsdaten; Tracker; Universally Unique Identifier (UUID)</p>
				<p>Direct-E-Mail-Marketing (DEM)</p>
				<p>Personenbezogene Daten: E-Mail; Nachname; Vorname</p>
				<p>Pinterest Ads</p>
				<p>Personenbezogene Daten: Benutzerkennung; E-Mail; Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Ger&auml;teinformationen; Nutzungsdaten; Tracker</p>
				<p>&Uuml;berwachung der Infrastruktur</p>
				<p>Crashlytics mit User Opt-in und Crashlytics</p>
				<p>Personenbezogene Daten: Crash-Informationen; Ger&auml;teinformationen; Universally Unique Identifier (UUID)</p>
				<p>Firebase Performance Monitoring</p>
				<p>Personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben</p>
				<p>Weitere Informationen zu personenbezogenen Daten</p>
				<br/><p>Analyse und Prognosen auf Basis der Nutzerdaten (&quot;Profiling&quot;)</p>
				<p>Der Anbieter kann die &uuml;ber diese Anwendung erhobenen personenbezogenen Daten und Nutzungsdaten verwenden, um Nutzerprofile zu erstellen oder zu aktualisieren.<br/>Diese Art der Datenverarbeitung erm&ouml;glicht es dem Anbieter, die Auswahl, die Pr&auml;ferenzen und das Verhalten des Nutzers f&uuml;r die im jeweiligen Abschnitt dieses Dokuments beschriebenen Zwecke auszuwerten. Nutzerprofile k&ouml;nnen auch durch den Einsatz von automatisierten Tools wie Algorithmen erstellt werden, die auch von Dritten zur Verf&uuml;gung gestellt werden k&ouml;nnen. Um mehr &uuml;ber die durchgef&uuml;hrten Profiling-T&auml;tigkeiten zu erfahren, k&ouml;nnen die Nutzer die entsprechenden Abschnitte dieses Dokuments nachlesen.<br/>Der Nutzer hat jederzeit das Recht, dieser Art der Profilings zu widersprechen. N&auml;heres zu den Rechten des Nutzers und deren Aus&uuml;bung k&ouml;nnen dem Abschnitt &uuml;ber die Rechte des Nutzers entnommen werden.</p>
				<br/><p>Automatisierte Entscheidungsfindung</p>
				<p>Automatisierte Entscheidungsfindung bedeutet, dass eine Entscheidung, die rechtliche oder &auml;hnlich bedeutende Auswirkungen auf den Nutzer haben kann, ausschlie&szlig;lich mit automatisierten Mitteln und ohne menschliches Zutun getroffen wird.<br/>Diese Anwendung kann die personenbezogenen Daten des Nutzers verwenden, um Entscheidungen ganz oder teilweise auf der Grundlage automatisierter Prozesse im Rahmen der in diesem Dokument beschriebenen Zwecken zu treffen.<br/>Diese Anwendung nutzt automatisierte Entscheidungsprozesse, soweit dies f&uuml;r den Abschluss oder die Durchf&uuml;hrung eines Vertrages zwischen Nutzer und Anbieter erforderlich ist oder auf der Grundlage der ausdr&uuml;cklichen Zustimmung des Nutzers, sofern dies gesetzlich vorgeschrieben ist. Automatisierte Entscheidungen werden mit technologischen Mitteln - meist auf Basis von Algorithmen nach vorgegebenen Kriterien - getroffen, die auch von Dritten zur Verf&uuml;gung gestellt werden k&ouml;nnen.<br/>Die der automatisierten Entscheidungsfindung zugrunde liegende Logik ist:</p>
				<p>den Entscheidungsprozess zu erm&ouml;glichen oder anderweitig zu verbessern; den Nutzern eine faire und unvoreingenommene Behandlung auf der Grundlage gleichbleibender und einheitlicher Kriterien zu gew&auml;hrleisten; den potenziellen Schaden aufgrund menschlichen Irrtums, pers&ouml;nlicher Vorurteile oder anderer Umst&auml;nde, die zu Diskriminierung oder Missverh&auml;ltnissen in der Behandlung nat&uuml;rlicher Personen (usw.) f&uuml;hren k&ouml;nnen, zu verringern; das Risiko der Nichterf&uuml;llung der vertraglichen Verpflichtungen des Nutzers zu verringern.</p>
				<p>Um mehr &uuml;ber die Zwecke, die Dienste Dritter, falls vorhanden, und eine detaillierte Erl&auml;uterung automatisierter Entscheidungen in diesem Dienst (diese Anwendung) herauszufinden, k&ouml;nnen die Nutzer die entsprechenden Abschnitte in diesem Dokument &uuml;berpr&uuml;fen.</p>
				<p>Konsequenzen automatisierter Entscheidungsprozesse f&uuml;r die Nutzer und Rechte der betroffenen Nutzer</p>
				<p>Vor diesem Hintergrund sind Nutzer, die einer solchen Verarbeitung unterliegen, berechtigt, besondere Rechte auszu&uuml;ben, die darauf abzielen, m&ouml;gliche Auswirkungen der getroffenen automatisierten Entscheidungen zu verhindern oder anderweitig zu begrenzen.</p>
				<p>Insbesondere haben Nutzer das Recht:</p>
				<p>eine Erkl&auml;rung &uuml;ber jede Entscheidung, die aufgrund einer automatisierten Entscheidungsfindung getroffen wurde, zu erhalten und ihren eigenen Standpunkt zu dieser Entscheidung darzulegen; eine Entscheidung anzufechten, indem sie den Anbieter auffordern, sie zu &uuml;berdenken oder eine neue Entscheidung auf einer anderen Grundlage zu treffen; auf menschlichen Eingriff des Anbieters in eine solche Verarbeitung.</p>
				<p>N&auml;heres zu den Rechten des Nutzers und deren Aus&uuml;bung k&ouml;nnen dem Abschnitt &uuml;ber die Rechte des Nutzers entnommen werden.</p>
				<p>CCPA: Erhebung personenbezogener Informationen &uuml;ber Verbraucher im Alter zwischen 13 und 16</p>
				<p>Wir erheben personenbezogene Informationen zu Verbrauchern im Alter zwischen 13 und 16 und werden solche Daten nicht verkaufen, es sei denn solche Verbraucher haben dem zugestimmt.</p>
				<p>CCPA: Erhebung personenbezogener Informationen &uuml;ber Verbraucher unter 13</p>
				<p>Wir erheben personenbezogene Informationen zu Verbrauchern unter 13 und werden solche Daten nicht verkaufen, es sei denn deren Eltern oder gesetzliche Vertreter haben dem zugestimmt.</p>
				<p>CCPA: Erhebung personenbezogener Informationen &uuml;ber Minderj&auml;hrige unter 16</p>
				<p>Bewusst erheben wir keine personenbezogenen Informationen zu Verbrauchern unter 16.</p>
				<br/><p>Push-Benachrichtigungen</p>
				<p>Diese Anwendung kann Push-Benachrichtigungen an den Nutzer senden, um die in dieser Datenschutzerkl&auml;rung genannten Zwecke zu erreichen.</p>
				<p>In den meisten F&auml;llen k&ouml;nnen Nutzer den Empfang von Push-Benachrichtigungen ablehnen, indem sie ihre Ger&auml;teeinstellungen, wie beispielsweise die Mitteilungshinweise f&uuml;r Mobiltelefone, aufrufen und diese Einstellungen dann f&uuml;r diese Anwendung, f&uuml;r einige oder alle Anwendungen auf dem jeweiligen Ger&auml;t &auml;ndern.<br/>Nutzer m&uuml;ssen sich bewusst sein, dass das Deaktivieren von Push-Benachrichtigungen diese Anwendung und den Nutzen negativ beeinflussen kann.</p>
				<p>Push-Benachrichtigungen basierend auf dem geografischen Standort des Nutzers</p>
				<p>Diese Anwendung kann den geografischen Standort des Nutzers nutzen, um Push-Benachrichtigungen f&uuml;r die in dieser Datenschutzerkl&auml;rung beschriebenen Zwecke zu versenden.</p>
				<p>In den meisten F&auml;llen k&ouml;nnen Nutzer den Empfang von Push-Benachrichtigungen ablehnen, indem sie ihre Ger&auml;teeinstellungen, wie beispielsweise die Mitteilungshinweise f&uuml;r Mobiltelefone, aufrufen und diese Einstellungen dann f&uuml;r diese Anwendung, f&uuml;r einige oder alle Anwendungen auf dem jeweiligen Ger&auml;t &auml;ndern.</p>
				<p>Nutzer m&uuml;ssen sich bewusst sein, dass das Deaktivieren von Push-Benachrichtigungen diese Anwendung und den Nutzen negativ beeinflussen kann.</p>
				<br/><p>localStorage</p>
				<p>Durch localStorage kann diese Anwendung Daten im Browser des Nutzers ohne Zeitbegrenzung speichern und abrufen.</p>
				<p>Personenbezogene Daten, die nicht beim Nutzer erhoben werden</p>
				<p>Diese Anwendung bzw. der Anbieter k&ouml;nnten, im Rahmen der im Abschnitt &uuml;ber die Rechtsgrundlage der Verarbeitung genannten Grundlagen, personenbezogene Daten der Nutzer rechtm&auml;&szlig;ig ohne deren Wissen &uuml;ber Dritte beziehen oder wiederverwenden.<br/>Wenn der Anbieter personenbezogene Daten auf diese Weise erhoben hat, kann der Nutzer in den entsprechenden Abschnitten dieses Dokuments oder durch Kontaktaufnahme mit dem Anbieter spezifische Informationen &uuml;ber die Quelle in Erfahrung bringen.</p>
				<p>Push-Benachrichtigungen f&uuml;r Direktmarketing</p>
				<p>Diese Anwendung kann dem Nutzer Push-Benachrichtigungen zum Zwecke des Direktmarketings zusenden (um Dienstleistungen und Produkte anzubieten, die von Dritten angeboten werden oder nicht mit dem Produkt oder der Dienstleistung von dieser Applikation (diese Anwendung) zusammenh&auml;ngen).</p>
				<p>In den meisten F&auml;llen k&ouml;nnen Nutzer den Empfang von Push-Benachrichtigungen ablehnen, indem sie ihre Ger&auml;teeinstellungen, wie beispielsweise die Mitteilungshinweise f&uuml;r Mobiltelefone, aufrufen und diese Einstellungen dann f&uuml;r diese Anwendung, f&uuml;r einige oder alle Anwendungen auf dem jeweiligen Ger&auml;t &auml;ndern.</p>
				<p>Nutzer m&uuml;ssen sich bewusst sein, dass das Deaktivieren von Push-Benachrichtigungen diese Anwendung und den Nutzen negativ beeinflussen kann.</p>
				<p>Neben den anwendbaren Ger&auml;teeinstellungen kann der Nutzer auch von den im entsprechenden Abschnitt dieser Datenschutzerkl&auml;rung &uuml;ber die Rechte der Nutzer beschriebenen Rechte Gebrauch machen.</p>
				<p>Rechte angemeldeter Nutzer unter 18 aus Kalifornien</p>
				<p>Das Kalifornische &bdquo;Online Eraser&ldquo; Gesetz aus dem Kalifornischen Business and Professions Code Sections 22580-22582, verpflichtet Betreiber gewisser Webseiten und Online Dienste, die f&uuml;r Minderj&auml;hrige bestimmt sind, angemeldeten und in Kalifornien wohnhaften Nutzern unter 18 die M&ouml;glichkeit einzur&auml;umen, die L&ouml;schung der von ihnen geposteten Inhalte zu verlangen. Sofern ein angemeldeter Nutzer die beschriebenen Voraussetzungen erf&uuml;llt und Inhalte &uuml;ber diese Anwendung ver&ouml;ffentlicht hat, kann der Nutzer die L&ouml;schung des Inhalts verlangen, indem er mit dem Anbieter oder dessen Datenschutz Zust&auml;ndigen &uuml;ber die in diesem Dokument genannten Kontaktdaten in Verbindung tritt.</p>
				<p>Daraufhin kann der Anbieter die vom Nutzer geposteten Inhalte f&uuml;r andere Nutzer und f&uuml;r die &Ouml;ffentlichkeit unzug&auml;nglich machen (anstatt diese vollst&auml;ndig zu L&ouml;schen). In diesem Fall k&ouml;nnten die Inhalte auf den Servern des Anbieters gespeichert bleiben, bzw. &uuml;ber andere Quellen weiterhin &ouml;ffentlich zug&auml;nglich sein, sofern Dritte den Inhalt vervielf&auml;ltigt und verbreitet haben sollten.</p>
				<p>Der Dienst ist f&uuml;r Vollj&auml;hrige bestimmt</p>
				<p>Nutzer erkl&auml;ren, vollj&auml;hrig nach dem jeweils anwendbaren Recht zu sein. Minderj&auml;hrige d&uuml;rfen diese Anwendung nicht nutzen.</p>
				<p>Dieser Dienst richtet sich nicht an Kinder unter 13 Jahren</p>
				<p>Der Nutzer erkl&auml;rt, gem&auml;&szlig; den f&uuml;r ihn geltenden Rechtsvorschriften vollj&auml;hrig zu sein. Minderj&auml;hrige d&uuml;rfen diese Anwendung nur unter der Aufsicht der Eltern oder einem Vormund nutzen. Personen unter 13 Jahren d&uuml;rfen diese Anwendung unter keinen Umst&auml;nden nutzen.</p>
				<p>&Uuml;bermittlung personenbezogener Informationen au&szlig;erhalb Brasiliens auf der Grundlage Ihrer Einwilligung</p>
				<p>Wir d&uuml;rfen Ihre personenbezogenen Informationen au&szlig;erhalb Brasiliens &uuml;bermitteln, wenn Sie in die &Uuml;bermittlung einwilligen.<br/>Bei Erheben Ihrer Einwilligung, werden wir Ihnen s&auml;mtliche Informationen zur Verf&uuml;gung stellen, die Sie ben&ouml;tigen, um eine aufgekl&auml;rte Entscheidung zu treffen und die Folgen und Implikationen der Einwilligung oder der Einwilligungsverweigerung zu verstehen.<br/>Derartige Informationen werden wir in einfacher und verst&auml;ndlicher Sprache in einer Form bereithalten, damit Sie diese von allf&auml;lligen anderen Anfragen oder Erhebungen von uns klar unterscheiden k&ouml;nnen.</p>
				<p>Ihre Einwilligung k&ouml;nnen Sie jederzeit widerrufen</p>
				<p>&Uuml;bermittlung personenbezogener Informationen au&szlig;erhalb Brasiliens in L&auml;nder, die das gleiche Datenschutzniveau gem. LGPD gew&auml;hrleisten</p>
				<p>Wir d&uuml;rfen Ihre personenbezogenen Informationen au&szlig;erhalb Brasiliens &uuml;bermitteln, wenn das Land oder die internationale Organisation, die die personenbezogenen Informationen empf&auml;ngt, gem. Entscheidung der ANPD ein angemessenes Datenschutzniveau gew&auml;hrleistet.<br/>Die ANPD genehmigt eine derartige &Uuml;bermittlung, wenn sie annimmt, dass das betroffene Land das Datenschutzniveau erkennt und umsetzt, welches mit dem Schutzniveau der LGPD vergleichbar ist, unter Beachtung folgender Aspekte:</p>
				<p>die allgemeinen und bereichsspezifischen gesetzlichen Vorschriften, die im Lande oder f&uuml;r die internationale Organisation anwendbar sind; die Art der personenbezogenen Informationen, die &uuml;bermittelt werden sollen; die Einhaltung der allgemeinen Grunds&auml;tze zum Schutz der personenbezogenen Informationen und zu den Rechten Einzelner gem. LGPD; das Ergreifen geeigneter Sicherheitsma&szlig;nahmen; das Vorhandensein gerichtlicher oder institutioneller Garantien f&uuml;r die Wahrung datenschutzrechtlicher Anspr&uuml;che; und andere relevante Umst&auml;nde, die bei der Daten&uuml;bermittlung eine Rolle spielen.</p>
				<p>&Uuml;bermittlung personenbezogener Informationen au&szlig;erhalb Brasiliens auf Grundlage eines Vertrags oder einer anderen Rechtsgrundlage</p>
				<p>Wir d&uuml;rfen Ihre personenbezogenen Informationen au&szlig;erhalb Brasiliens &uuml;bermitteln, wenn wir in der Lage sind zu gew&auml;hrleisten, dass eine etwaig weitere Verarbeitung Ihrer personenbezogenen Informationen in Einklang mit den Vorschriften der LGPD und unter Wahrung Ihrer Rechte erfolgt.</p>
				<p>Um dies zu erreichen, k&ouml;nnen wir eine der folgenden Mittel einsetzen:</p>
				<p>Vertragsklauseln, die f&uuml;r die einzelne &Uuml;bermittlung gelten. Das bedeutet, dass wir Vereinbarungen mit dem Empf&auml;nger Ihrer personenbezogenen Informationen abschlie&szlig;en, die sicherstellen, dass die &Uuml;bermittlung die oben genannten Bedingungen erf&uuml;llt. Eine solche Vereinbarung unterliegt der Vorabgenehmigung der ANPD; Standardvertragsklauseln. Diese Klauseln definieren allgemeine Bedingungen f&uuml;r die &Uuml;bermittlung personenbezogener Informationen und werden von der ANPD bereitgestellt; L&auml;nder&uuml;bergreifende interne Datenschutzklauseln. Solche Klauseln definieren allgemeine Bedingungen f&uuml;r die &Uuml;bermittlung personenbezogener Informationen innerhalb einer Organisation und unterliegen einer Pr&uuml;fung der ANPD, bevor sie in Kraft treten. Genehmigungssiegel, Zertifizierungen und Verhaltensregeln, die kontinuierlich von der ANPD erlassen werden. Diese Rechtsmittel erlauben es uns, Ihre personenbezogenen Informationen unter Einhaltung der jeweiligen Vorschriften zu &uuml;bermitteln. Sie unterliegen der Vorabgenehmigung der ANPD.</p>
				<br/><p>Eindeutige Ger&auml;tekennzeichung</p>
				<p>Diese Anwendung kann Nutzer durch Speichern einer eindeutigen Kennzeichnung ihres Ger&auml;ts zu analytischen Zwecken oder zur Speicherung der Einstellungen des Nutzers nachverfolgen.</p>
				<p>Benutzeridentifikation per Universally Unique Identifier (UUID)</p>
				<p>Diese Anwendung erfasst Nutzer m&ouml;glicherweise durch die Speicherung eines so genannten Universally Unique Identifier (oder kurz UUID) f&uuml;r Analysezwecke oder zur Speicherung der Benutzereinstellungen. Dieser Identifier wird bei der Installation dieser Anwendung erstellt und bleibt zwischen Anwendungsstarts und Aktualisierungen bestehen, geht jedoch verloren, wenn der Benutzer die Anwendung l&ouml;scht. Eine Neuinstallation erstellt einen neuen UUID.</p>
				<br/><p>Kontaktdaten</p>
				<p>Anbieter und Verantwortlicher</p>
				<p>Max Rosenblattl<br/>Fischergasse 35<br/>8010 Graz</p>
				<p>E-Mail-Adresse des Anbieters:</p>
				<p>&nbsp;support@simpleveganapp.com</p>
				<p><br/></p>
				<p>Vollst&auml;ndige Datenschutzerkl&auml;rung</p>
				<br/><p>Anbieter und Verantwortlicher</p>
				<br/><p>Max Rosenblattl<br/>Fischergasse 35<br/>8010 Graz</p>
				<p>E-Mail-Adresse des Anbieters:</p>
				<p>&nbsp;support@simpleveganapp.com</p>
				<br/><p>Arten der erhobenen Daten</p>
				<p>Zu den personenbezogenen Daten, die diese Anwendung selbstst&auml;ndig oder durch Dritte verarbeitet, geh&ouml;ren: Tracker; Nutzungsdaten; Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Universally Unique Identifier (UUID); Ger&auml;teinformationen; E-Mail; Vorname; Nachname; Benutzerkennung; Region; Anzahl der Nutzer; Anzahl der Sitzungen; Sitzungsdauer; In-App-K&auml;ufe; Anwendung-Ausf&uuml;hrungen; Anwendung-Updates; erstmalige Starts; Betriebssysteme; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten; Informationen &uuml;ber die Anwendung; Land; Geburtsdatum; Ger&auml;telogs; Geschlecht; Nutzername; verschiedene Datenarten; Kameraberechtigung; Speicherberechtigung; Berechtigung App Erinnerungen; Fotobibliothekberechtigung; Leseberechtigung f&uuml;r Gesundheitsdaten; Berechtigung zum Aktualisieren von Gesundheitsdaten ; Kameraberechtigung, ohne Speichern oder Aufzeichnen; Allgemeine Aktivit&auml;tsdaten; Aktivit&auml;ten bez&uuml;glich Bewegung; K&ouml;rperma&szlig;e &amp; Indizes; Aktivit&auml;ten bez&uuml;glich Ern&auml;hrung; Aktivit&auml;t bez&uuml;glich Schlaf; Blutgruppe; Crash-Informationen; Geografische Position; Adresse; Stadt; Telefonnummer; Staat; Passwort; Profilbild; Konten in den sozialen Netzwerken; Einkaufsverlauf; Sprache; Voller Zugriff; Nur Lesezugriff; Metadaten lesen; Modifizieren; Einf&uuml;gen und Importieren; Verfassen; Verwaltung der Grundeinstellungen; Verwaltung von sensiblen Einstellungen; Bild.</p>
				<p>Vollst&auml;ndige Details zu jeder Art von verarbeiteten personenbezogenen Daten werden in den daf&uuml;r vorgesehenen Abschnitten dieser Datenschutzerkl&auml;rung oder punktuell durch Erkl&auml;rungstexte bereitgestellt, die vor der Datenerhebung angezeigt werden.<br/>Personenbezogene Daten k&ouml;nnen vom Nutzer freiwillig angegeben oder, im Falle von Nutzungsdaten, automatisch erhoben werden, wenn diese Anwendung genutzt wird.<br/>Sofern nicht anders angegeben, ist die Angabe aller durch diese Anwendung angeforderten Daten obligatorisch. Weigert sich der Nutzer, die Daten anzugeben, kann dies dazu f&uuml;hren, dass diese Anwendung dem Nutzer ihre Dienste nicht zur Verf&uuml;gung stellen kann. In F&auml;llen, in denen diese Anwendung die Angabe personenbezogener Daten ausdr&uuml;cklich als freiwillig bezeichnet, d&uuml;rfen sich die Nutzer daf&uuml;r entscheiden, diese Daten ohne jegliche Folgen f&uuml;r die Verf&uuml;gbarkeit oder die Funktionsf&auml;higkeit des Dienstes nicht anzugeben.<br/>Nutzer, die sich dar&uuml;ber im Unklaren sind, welche personenbezogenen Daten obligatorisch sind, k&ouml;nnen sich an den Anbieter wenden.<br/>Jegliche Verwendung von Cookies &ndash; oder anderer Tracking-Tools &ndash; durch diese Anwendung oder Anbieter von Drittdiensten, die durch diese Anwendung eingesetzt werden, dient dem Zweck, den vom Nutzer gew&uuml;nschten Dienst zu erbringen, und allen anderen Zwecken, die im vorliegenden Dokument und, falls vorhanden, in der Cookie-Richtlinie beschrieben sind.</p>
				<p>Die Nutzer sind f&uuml;r alle personenbezogenen Daten Dritter verantwortlich, die durch diese Anwendung beschafft, ver&ouml;ffentlicht oder weitergegeben werden, und best&auml;tigen, dass sie die Zustimmung zur &Uuml;bermittlung personenbezogener Daten etwaiger Dritter an diese Anwendung eingeholt haben.</p>
				<br/><p>Art und Ort der Datenverarbeitung</p>
				<p>Verarbeitungsmethoden</p>
				<p>Der Anbieter verarbeitet die Nutzerdaten auf ordnungsgem&auml;&szlig;e Weise und ergreift angemessene Sicherheitsma&szlig;nahmen, um den unbefugten Zugriff und die unbefugte Weiterleitung, Ver&auml;nderung oder Vernichtung von Daten zu vermeiden.<br/>Die Datenverarbeitung wird mittels Computern oder IT-basierten Systemen nach organisatorischen Verfahren und Verfahrensweisen durchgef&uuml;hrt, die gezielt auf die angegebenen Zwecke abstellen. Zus&auml;tzlich zum Verantwortlichen k&ouml;nnten auch andere Personen intern (Personalverwaltung, Vertrieb, Marketing, Rechtsabteilung, Systemadministratoren) oder extern &ndash; und in dem Fall soweit erforderlich, vom Verantwortlichen als Auftragsverarbeiter benannt (wie Anbieter technischer Dienstleistungen, Zustellunternehmen, Hosting-Anbieter, IT-Unternehmen oder Kommunikationsagenturen) - diese Anwendung betreiben und damit Zugriff auf die Daten haben. Eine aktuelle Liste dieser Beteiligten kann jederzeit vom Anbieter verlangt werden.</p>
				<p>Rechtsgrundlagen der Verarbeitung</p>
				<p>Der Anbieter darf personenbezogene Daten von Nutzern nur dann verarbeiten, wenn einer der folgenden Punkte zutrifft:</p>
				<p>Die Nutzer haben ihre Einwilligung f&uuml;r einen oder mehrere bestimmte Zwecke erteilt. Hinweis: In einigen Gesetzgebungen kann es dem Anbieter gestattet sein, personenbezogene Daten zu verarbeiten, bis der Nutzer einer solchen Verarbeitung widerspricht (&bdquo;Opt-out&ldquo;), ohne sich auf die Einwilligung oder eine andere der folgenden Rechtsgrundlagen verlassen zu m&uuml;ssen. Dies gilt jedoch nicht, wenn die Verarbeitung personenbezogener Daten dem europ&auml;ischen Datenschutzrecht unterliegt;&nbsp;die Datenerhebung ist f&uuml;r die Erf&uuml;llung eines Vertrages mit dem Nutzer und/oder f&uuml;r vorvertragliche Ma&szlig;nahmen daraus erforderlich;&nbsp;die Verarbeitung ist f&uuml;r die Erf&uuml;llung einer rechtlichen Verpflichtung, der der Anbieter unterliegt, erforderlich;&nbsp;die Verarbeitung steht im Zusammenhang mit einer Aufgabe, die im &ouml;ffentlichen Interesse oder in Aus&uuml;bung hoheitlicher Befugnisse, die dem Anbieter &uuml;bertragen wurden, durchgef&uuml;hrt wird;&nbsp;die Verarbeitung ist zur Wahrung der berechtigten Interessen des Anbieters oder eines Dritten erforderlich.</p>
				<p>In jedem Fall erteilt der Anbieter gerne Auskunft &uuml;ber die konkrete Rechtsgrundlage, auf der die Verarbeitung beruht, insbesondere dar&uuml;ber, ob die Angabe personenbezogener Daten eine gesetzliche oder vertragliche Verpflichtung oder eine Voraussetzung f&uuml;r den Abschluss eines Vertrages ist.</p>

				<br/><p>Ort</p>
				<p>Die Daten werden in der Niederlassung des Anbieters und an allen anderen Orten, an denen sich die an der Datenverarbeitung beteiligten Stellen befinden, verarbeitet.<br/><br/>Je nach Standort der Nutzer k&ouml;nnen Daten&uuml;bertragungen die &Uuml;bertragung der Daten des Nutzers in ein anderes Land als das eigene beinhalten. Um mehr &uuml;ber den Ort der Verarbeitung der &uuml;bermittelten Daten zu erfahren, k&ouml;nnen die Nutzer den Abschnitt mit den ausf&uuml;hrlichen Angaben zur Verarbeitung der personenbezogenen Daten konsultieren.</p>
				<p>Die Nutzer haben auch das Recht, sich &uuml;ber die Rechtsgrundlage der Daten&uuml;bermittlung in ein Land au&szlig;erhalb der Europ&auml;ischen Union oder an eine internationale Organisation, die dem V&ouml;lkerrecht unterliegt oder von zwei oder mehr L&auml;ndern gegr&uuml;ndet wurde, wie beispielsweise die UNO, sowie sich &uuml;ber die vom Anbieter ergriffenen Sicherheitsma&szlig;nahmen zum Schutz ihrer Daten aufkl&auml;ren zu lassen.<br/><br/>Wenn eine solche &Uuml;bermittlung stattfindet, kann der Nutzer mehr dar&uuml;ber erfahren, indem er die entsprechenden Abschnitte dieses Dokuments &uuml;berpr&uuml;ft oder sich mit dem Anbieter &uuml;ber die im Kontaktteil angegebenen Informationen in Verbindung setzt.</p>

				<br/><p>Speicherdauer</p>
				<p>Personenbezogene Daten werden so lange verarbeitet und gespeichert, wie es der Zweck erfordert, zu dem sie erhoben wurden.</p>
				<p>Daher gilt:</p>
				<p>Personenbezogene Daten, die zu Zwecken der Erf&uuml;llung eines zwischen dem Anbieter und dem Nutzer geschlossenen Vertrages erhoben werden, werden bis zur vollst&auml;ndigen Erf&uuml;llung dieses Vertrages gespeichert.&nbsp;Personenbezogene Daten, die zur Wahrung der berechtigten Interessen des Anbieters erhoben werden, werden so lange aufbewahrt, wie es zur Erf&uuml;llung dieser Zwecke erforderlich ist. Nutzer k&ouml;nnen n&auml;here Informationen &uuml;ber die berechtigten Interessen des Anbieters in den entsprechenden Abschnitten dieses Dokuments oder durch Kontaktaufnahme zum Anbieter erhalten.</p>
				<p>Dar&uuml;ber hinaus ist es dem Anbieter gestattet, personenbezogene Daten f&uuml;r einen l&auml;ngeren Zeitraum zu speichern, wenn der Nutzer in eine solche Verarbeitung eingewilligt hat, solange die Einwilligung nicht widerrufen wird. Dar&uuml;ber hinaus kann der Anbieter verpflichtet sein, personenbezogene Daten f&uuml;r einen l&auml;ngeren Zeitraum aufzubewahren, wenn dies zur Erf&uuml;llung einer gesetzlichen Verpflichtung oder auf Anordnung einer Beh&ouml;rde erforderlich ist.<br/><br/>Nach Ablauf der Aufbewahrungsfrist werden personenbezogene Daten gel&ouml;scht. Daher k&ouml;nnen das Auskunftsrecht, das Recht auf L&ouml;schung, das Recht auf Berichtigung und das Recht auf Daten&uuml;bertragbarkeit nach Ablauf der Aufbewahrungsfrist nicht geltend gemacht werden.</p>

				<br/><p>Zwecke der Verarbeitung</p>
				<p>Personenbezogene Daten &uuml;ber den Nutzer werden erhoben, damit der Anbieter den Dienst erbringen und des Weiteren seinen gesetzlichen Verpflichtungen nachkommen, auf Durchsetzungsforderungen reagieren, seine Rechte und Interessen (oder die der Nutzer oder Dritter) sch&uuml;tzen, b&ouml;swillige oder betr&uuml;gerische Aktivit&auml;ten aufdecken kann. Dar&uuml;ber hinaus werden Daten zu folgenden Zwecken erhoben: Werbung, Analytik, Beta-Tests, Kommerzielle Bindung, Kontaktieren des Nutzers, Testen der Performance von Inhalten und Funktionen (A/B-Test), Daten&uuml;bermittlung au&szlig;erhalb der EU, Ger&auml;teberechtigungen f&uuml;r den Zugriff auf personenbezogene Daten, Umgang mit Aktivit&auml;tsdaten, Umgang mit Zahlungen, Hosting und Backend-Infrastruktur, &Uuml;berwachung der Infrastruktur, Interaktion mit externen sozialen Netzwerken und Plattformen, Standortbezogene Interaktionen, Verwalten von Kontakten und Versenden von Nachrichten, Verwaltung von Datenerfassung und Online-Umfragen, Plattform-Dienste und Hosting, Registrierung und Anmeldung, Remarketing und Behavioural-Targeting, Registrierung und Anmeldung unmittelbar &uuml;ber diese Anwendung, Verwalten von RSS-Feeds und Soziale Funktionen.</p>
				<p>Nutzer k&ouml;nnen im Abschnitt &ldquo;Ausf&uuml;hrliche Angaben &uuml;ber die Verarbeitung personenbezogener Daten&rdquo; dieses Dokuments weitere detaillierte Informationen zu diesen Verarbeitungszwecken und die zu den f&uuml;r den jeweiligen Zweck verwendeten personenbezogenen Daten vorfinden.</p>

				<br/><p>Ger&auml;teberechtigungen f&uuml;r den Zugriff auf personenbezogene Daten</p>
				<p>Je nach vom Nutzer benutzten Ger&auml;t kann diese Anwendung bestimmte Berechtigungen anfragen, die es erlauben, wie unten beschrieben auf die Ger&auml;tedaten des Nutzers zuzugreifen.</p>
				<p>Standardm&auml;&szlig;ig m&uuml;ssen diese Zugriffsberechtigungen durch den Nutzer erteilt werden, bevor auf die entsprechenden Informationen zugegriffen werden kann. Eine einmal erteilte Zustimmung kann jederzeit vom Nutzer widerrufen werden. Um diese Zustimmungen zu widerrufen, k&ouml;nnen Nutzer in den Ger&auml;teeinstellungen nachsehen oder sie kontaktieren den Support des Eigent&uuml;mers unter den im vorliegenden Dokument genannten Kontaktdaten.<br/>Die genaue Vorgehensweise f&uuml;r die &Uuml;berpr&uuml;fung von App-Berechtigungen kann vom Ger&auml;t und der Software des Nutzers abh&auml;ngen.</p>
				<p>Es ist zu beachten, dass diese Anwendung durch den Widerruf solcher Berechtigungen in der ordnungsgem&auml;&szlig;en Funktion beeintr&auml;chtigt sein k&ouml;nnte.</p>
				<p>Wenn der Nutzer eine der unten genannten Berechtigungen erteilt, k&ouml;nnten die jeweiligen personenbezogenen Daten durch diese Anwendung verarbeitet werden (d.h., es kann darauf zugegriffen, sie k&ouml;nnen ver&auml;ndert oder entfernt werden).</p>

				<p>Berechtigung App Erinnerungen</p>
				<p>Wird f&uuml;r den Zugriff auf die App Erinnerungen auf dem Ger&auml;t des Nutzers verwendet und schlie&szlig;t das Lesen, Hinzuf&uuml;gen und Entfernen von Eintr&auml;gen mit ein.</p>

				<p>Berechtigung zum Aktualisieren von Gesundheitsdaten</p>
				<p>Erm&ouml;glicht die Aktualisierung der Gesundheitsdaten des Nutzers.</p>

				<p>Fotobibliothekberechtigung</p>
				<p>Erm&ouml;glicht den Zugriff auf die Fotobibliothek des Nutzers.</p>

				<p>Kameraberechtigung</p>
				<p>Wird f&uuml;r den Zugriff auf die Kamera oder die Aufnahme von Bildern und Videos &uuml;ber das Ger&auml;t ben&ouml;tigt.</p>

				<p>Kameraberechtigung, ohne Speichern oder Aufzeichnen</p>
				<p>Wird f&uuml;r den Zugriff auf die Kamera oder die Aufnahme von Bildern und Videos &uuml;ber das Ger&auml;t ben&ouml;tigt.<br/>Diese Anwendung speichert oder zeichnet die Kameraausgabe nicht auf.</p>

				<p>Leseberechtigung f&uuml;r Gesundheitsdaten</p>
				<p>Erm&ouml;glicht das Lesen der Gesundheitsdaten des Nutzers.</p>

				<p>Speicherberechtigung</p>
				<p>Wird dazu verwendet, um auf gemeinsam genutzten externen Speicher zuzugreifen, einschlie&szlig;lich dem Lesen und Hinzuf&uuml;gen von Objekten.</p>

				<p>Ausf&uuml;hrliche Angaben &uuml;ber die Verarbeitung personenbezogener Daten</p>
				<p>Personenbezogene Daten werden zu folgenden Zwecken unter Inanspruchnahme folgender Dienstleistungen erhoben:</p>
				<br/><p>Analytik</p>
				<p>Mit den in diesem Abschnitt aufgef&uuml;hrten Dienstleistungen kann der Anbieter den Datenverkehr &uuml;berwachen und analysieren sowie das Verhalten von Nutzern nachverfolgen.</p>

				<p>Google Analytics (Google LLC)</p>

				<p>Google Analytics ist ein Webanalysedienst von Google LLC (&bdquo;Google&ldquo;). Google verwendet die erhobenen Daten, um nachzuverfolgen und zu untersuchen, wie diese Anwendung genutzt wird, Berichte &uuml;ber ihre Aktivit&auml;ten zu verfassen und diese gemeinsam mit anderen Google-Diensten zu nutzen.<br/>Google kann die erhobenen Daten verwenden, um die Anzeigen seines eigenen Werbenetzwerks zu kontextualisieren und personalisieren.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=de">Opt Out</a>.</p>

				<p>Google Analytics Funktionen f&uuml;r Werbeberichte (Google LLC)</p>
				<p>Google Analytics auf dieser Applikation (diese Anwendung) aktiviert Werbeberichtsfunktionen, die zus&auml;tzliche Informationen aus dem DoubleClick-Cookie (Webaktivit&auml;t) und aus Ger&auml;te-Werbe-IDs (App-Aktivit&auml;t) sammeln. Es erm&ouml;glicht dem Eigent&uuml;mer, spezifische Verhaltens- und Interessendaten (Verkehrsdaten und Interaktionsdaten der Nutzeranzeigen) und, falls aktiviert, demografische Daten (Informationen &uuml;ber Alter und Geschlecht) zu analysieren.</p>
				<p>Nutzer k&ouml;nnen die Verwendung von Cookies durch Google ablehnen, indem sie die <a href="https://adssettings.google.com/authenticated">Anzeigeneinstellungen</a> von Google aufrufen.</p>
				<p>Verarbeitete personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=de">Opt Out</a>.</p>

				<p>Google Analytics: Berichte zur Leistung nach demografischen Merkmalen und Interessen (Google LLC)</p>
				<p>Google Analytics: Berichte zur Leistung nach demografischen Merkmalen und Interessen ist eine Google Werbeberichte-Funktion, die Daten zu demographischen Merkmalen und Interessen innerhalb von Google Analytics f&uuml;r diese Anwendung zur Verf&uuml;gung stellt (Demographie bedeutet Alters- und Geschlechtsdaten).&nbsp;</p>
				<p>Nutzer k&ouml;nnen die Verwendung von Cookies durch Google ablehnen, indem sie die <a href="https://adssettings.google.com/authenticated">Anzeigeneinstellungen</a> von Google aufrufen.</p>
				<p>Verarbeitete personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout">Opt Out</a>.</p>

				<p>Google Analytics for Firebase (Google LLC)</p>
				<p>Google Analytics f&uuml;r Firebase oder Firebase Analytics ist ein Analyse-Dienst von Google LLC.</p>
				<p>Weitere Informationen zur Verwendung von Daten bei Google sind in der&nbsp;</p>
				<p><a href="https://www.google.com/intl/de/policies/privacy/partners/">Partner-Richtlinie von Google einsehbar</a>.&nbsp;</p>
				<p>Firebase Analytics kann Daten mit anderen von Firebase bereitgestellten Tools wie Crash Reporting, Authentication, Remote Config oder Notifications gemeinsam nutzen. Der Nutzer kann diese Datenschutzerkl&auml;rung &uuml;berpr&uuml;fen, um eine ausf&uuml;hrliche Erl&auml;uterung zu den anderen vom Eigent&uuml;mer verwendeten Tools zu finden.</p>
				
				<p>Diese Anwendung verwendet Identifikatoren f&uuml;r mobile Ger&auml;te und Cookie-&auml;hnliche Technologien f&uuml;r die Ausf&uuml;hrung des Google Analytics for Firebase-Dienstes.</p>
				
				<p>Nutzer k&ouml;nnen sich &uuml;ber die entsprechenden Ger&auml;teeinstellungen mobiler Ger&auml;te von bestimmten Firebase-Funktionen abmelden, wie etwa &uuml;ber die Werbeeinstellungen f&uuml;r mobile Ger&auml;te, oder indem sie gegebenenfalls den Anweisungen anderer Abschnitte dieser Datenschutzrichtlinie bez&uuml;glich Firebase folgen.</p>
				<p>Verarbeitete personenbezogene Daten: Anwendung-Ausf&uuml;hrungen; Anwendung-Updates; Anzahl der Nutzer; Anzahl der Sitzungen; Betriebssysteme; Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); erstmalige Starts; Ger&auml;teinformationen; In-App-K&auml;ufe; Nutzungsdaten; Region; Sitzungsdauer; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Google Analytics mit IP-Anonymisierung (Google LLC)</p>
				<p>Google Analytics ist ein Webanalysedienst von Google LLC (&bdquo;Google&ldquo;). Google verwendet die erhobenen Daten, um nachzuverfolgen und zu untersuchen, wie diese Anwendung genutzt wird, Berichte &uuml;ber ihre Aktivit&auml;ten zu verfassen und diese gemeinsam mit anderen Google-Diensten zu nutzen.<br/>Google kann die erhobenen Daten verwenden, um die Anzeigen seines eigenen Werbenetzwerks zu kontextualisieren und personalisieren.<br/>Auf dieser Webseite wurde die IP-Anonymisierung aktiviert, so dass die IP-Adresse der Nutzer von Google innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt wird. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=de">Opt Out</a>.</p>
				<br/><p>Beta-Tests</p>
				<p>Diese Art von Diensten erm&ouml;glicht es, den Nutzerzugriff auf diese Anwendung oder Teile davon zu verwalten, um eine bestimmte Funktion oder die gesamte Anwendung zu testen.<br/>Der Dienstanbieter kann automatisch Daten &uuml;ber Abst&uuml;rze und Statistiken im Zusammenhang mit der Nutzung von dieser Anwendung (diese Anwendung) durch den Nutzer in einer pers&ouml;nlich identifizierbaren Form sammeln.</p>

				<p>App Center (Microsoft Corporation)</p>
				<p>App Center ist ein Beta-Test-Dienst von Microsoft Corporation.<br/>Diese Anwendung kann Nutzern Push-Benachrichtigungen senden.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">Datenschutzerkl&auml;rung</a>.</p>

				<p>Beta by Crashlytics (Google LLC)</p>
				<p>Beta by Crashlytics ist ein Beta-Test-Dienst von Google LLC.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Geburtsdatum; Ger&auml;teinformationen; Ger&auml;telogs; Geschlecht; Informationen &uuml;ber die Anwendung; Land; Nachname; Nutzername; Nutzungsdaten; Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Google Play Beta Testing (Google LLC)</p>
				<p>Google Play Beta Testing ist ein Beta-Test-Dienst von Google LLC.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Geburtsdatum; Ger&auml;teinformationen; Ger&auml;telogs; Geschlecht; Land; Nachname; Nutzername; Nutzungsdaten; Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>TestFlight (Apple Inc.)</p>
				<p>TestFlight ist ein Beta-Test-Dienst von Apple Inc.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Ger&auml;teinformationen; Ger&auml;telogs; Geschlecht; Informationen &uuml;ber die Anwendung; Land; Nachname; Nutzername; Nutzungsdaten; Tracker; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.apple.com/legal/internet-services/itunes/testflight/sren/terms.html">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Daten&uuml;bermittlung au&szlig;erhalb der EU</p>
				<p>Der Anbieter darf personenbezogene Daten, die innerhalb der EU erhoben werden, nur gem&auml;&szlig; einer bestimmten Rechtsgrundlage in Drittl&auml;nder (d.h. in L&auml;nder, die nicht der EU angeh&ouml;ren) &uuml;bermitteln. Eine solche Daten&uuml;bermittlung beruht auf einer der nachfolgend beschriebenen Rechtsgrundlagen. Der Nutzer kann sich beim Anbieter dar&uuml;ber erkundigen, welche Rechtsgrundlage f&uuml;r welche Dienstleistung gilt.</p>

				<p>Daten&uuml;bermittlung ins Ausland aufgrund einer Einwilligung (diese Anwendung)</p>
				<p>Wenn dies die Rechtsgrundlage ist, werden personenbezogene Daten von Nutzern nur dann aus der EU in Drittl&auml;nder &uuml;bermittelt, wenn der Nutzer dieser &Uuml;bermittlung ausdr&uuml;cklich zugestimmt hat, nachdem er &uuml;ber die m&ouml;glichen Risiken aufgrund des Fehlens eines Angemessenheitsbeschlusses und angemessener Garantien informiert wurde.<br/>In solchen F&auml;llen wird der Anbieter den Nutzer entsprechend informieren und seine ausdr&uuml;ckliche Einwilligung &uuml;ber diese Anwendung einholen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten.</p>

				<p>Daten&uuml;bermittlung ins Ausland auf Basis von Standardvertragsklauseln (diese Anwendung)</p>
				<p>Wenn dies die Rechtsgrundlage ist, erfolgt die &Uuml;bermittlung personenbezogener Daten aus der EU in Drittl&auml;nder durch den Anbieter gem&auml;&szlig; der Europ&auml;ischen Kommission erstellten &quot;Standardvertragsklauseln&quot;.<br/>Dies bedeutet, dass sich die Datenempf&auml;nger verpflichtet haben, personenbezogene Daten in &Uuml;bereinstimmung mit den Datenschutzstandards der EU-Datenschutzvorschriften zu verarbeiten. F&uuml;r weitere Informationen k&ouml;nnen sich Nutzer &uuml;ber die in diesem Dokument angegebenen Kontaktdaten an den Anbieter wenden.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten.</p>

				<p>Daten&uuml;bermittlung in L&auml;nder, die europ&auml;ische Standards gew&auml;hrleisten (diese Anwendung)</p>
				<p>Wenn dies die Rechtsgrundlage ist, erfolgt die &Uuml;bermittlung personenbezogener Daten aus der EU in Drittl&auml;nder aufgrund eines Angemessenheitsbeschlusses der Europ&auml;ischen Kommission.<br/>Die Europ&auml;ische Kommission erl&auml;sst Angemessenheitsbeschl&uuml;sse f&uuml;r bestimmte L&auml;nder, wenn sie der Auffassung ist, dass diese Datenschutzstandards gew&auml;hrleisten, die mit denen vergleichbar sind, die durch EU-Datenschutzvorschriften festgelegt sind. Eine aktualisierte Liste aller Angemessenheitsbeschl&uuml;sse kann der Nutzer auf der Website der Europ&auml;ischen Kommission einsehen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten.</p>

				<p>Weitere Rechtsgrundlagen f&uuml;r den Datentransfer ins Ausland (diese Anwendung)</p>
				<p>Sofern keine andere Rechtsgrundlage greift, werden personenbezogene Daten nur dann aus der EU in Drittl&auml;nder &uuml;bermittelt, wenn mindestens eine der folgenden Bedingungen erf&uuml;llt ist:</p>
				<p>die &Uuml;bertragung ist f&uuml;r die Erf&uuml;llung eines Vertrages zwischen dem Nutzer und dem Anbieter oder f&uuml;r auf Verlangen des Nutzers ergriffene vorvertragliche Ma&szlig;nahmen erforderlich;&nbsp;die &Uuml;bertragung ist f&uuml;r den Abschluss oder die Erf&uuml;llung eines im Interesse des Nutzers geschlossenen Vertrages zwischen dem Anbieter und einer anderen nat&uuml;rlichen oder juristischen Person erforderlich;&nbsp;die &Uuml;bertragung ist aus wichtigen Gr&uuml;nden des &ouml;ffentlichen Interesses erforderlich;&nbsp;die &Uuml;bertragung ist zur Begr&uuml;ndung, Aus&uuml;bung oder Abwehr von Rechtsanspr&uuml;chen erforderlich;&nbsp;die &Uuml;bermittlung ist notwendig, um die lebenswichtigen Interessen der betroffenen Person oder anderer Personen zu sch&uuml;tzen, wenn die betroffene Person physisch oder rechtlich nicht in der Lage ist, ihre Einwilligung zu erteilen. In solchen F&auml;llen wird der Anbieter den Nutzer &uuml;ber die Rechtsgrundlagen, auf denen die &Uuml;bertragung beruht, mittels informieren.</p>
				<br/><p>Verarbeitete personenbezogene Daten: verschiedene Datenarten.</p>
				<p>Ger&auml;teberechtigungen f&uuml;r den Zugriff auf personenbezogene Daten</p>
				<p>Diese Anwendung fordert bestimmte Zustimmungen von Nutzern an, die es erlauben, wie unten beschrieben auf die Ger&auml;tedaten des Nutzers zuzugreifen.</p>

				<p>Ger&auml;teberechtigungen f&uuml;r den Zugriff auf personenbezogene Daten (diese Anwendung)</p>
				<p>Diese Anwendung fordert bestimmte Zustimmungen von Nutzern an, die es erlauben, wie in diesem Dokument beschrieben auf die Ger&auml;tedaten des Nutzers zuzugreifen.</p>
				<p>Verarbeitete personenbezogene Daten: Berechtigung App Erinnerungen; Berechtigung zum Aktualisieren von Gesundheitsdaten ; Fotobibliothekberechtigung; Kameraberechtigung; Kameraberechtigung, ohne Speichern oder Aufzeichnen; Leseberechtigung f&uuml;r Gesundheitsdaten; Speicherberechtigung.</p>
				<br/><p>Hosting und Backend-Infrastruktur</p>
				<p>Diese Art von Diensten haben zum Zweck, Daten und Dateien zu hosten, damit diese Anwendung verwaltet und verwendet werden kann. Des Weiteren k&ouml;nnen diese Angebote eine vorgefertigte Infrastruktur zur Verf&uuml;gung stellen, die spezifische Funktionen oder ganze Bestandteile f&uuml;r diese Anwendung abwickelt.</p>
				<p>Einige der unten aufgef&uuml;hrten Dienste k&ouml;nnen, m&uuml;ssen aber nicht, &uuml;ber geografisch verteilte Server funktionieren, was es schwierig macht, den tats&auml;chlichen Speicherort der personenbezogenen Daten zu bestimmen.</p>

				<p>Firebase Cloud Functions</p>

				<p>Firebase Cloud Functions ist ein Webhosting und Backend Dienst, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Cloud Firestore</p>
				<p>Firebase Cloud Firestore ist ein Webhosting und Backend Dienst, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Hosting</p>
				<p>Firebase Hosting ist ein Webhosting Dienst, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Cloud Storage</p>
				<p>Firebase Cloud Storage ist ein Webhosting Dienst, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Realtime Database</p>
				<p>Firebase Realtime Database ist ein Webhosting und Backend Dienst, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Interaktion mit externen sozialen Netzwerken und Plattformen</p>
				<p>Diese Art von Diensten erm&ouml;glichen die Interaktion mit sozialen Netzwerken oder sonstigen externen Plattformen direkt &uuml;ber diese Anwendung.<br/>Die Interaktion sowie die &uuml;ber diese Anwendung erhobenen Informationen unterliegen stets den von den Nutzern f&uuml;r das jeweilige soziale Netzwerk vorgenommenen Privatsph&auml;re-Einstellungen.<br/>Diese Art von Dienst kann weiterhin Web-Traffic-Daten f&uuml;r die Seiten sammeln, auf denen der Dienst installiert ist, auch wenn die Nutzer ihn nicht verwenden.<br/>Es wird empfohlen, sich von den jeweiligen Diensten abzumelden, um sicherzustellen, dass die verarbeiteten Daten &uuml;ber diese Anwendung nicht wieder mit dem Profil des Nutzers verbunden werden.</p>

				<p>Tweet-Schaltfl&auml;che und soziale Widgets f&uuml;r Twitter (Twitter, Inc.)</p>

				<p>Die Tweet-Schaltfl&auml;che und sozialen Widgets f&uuml;r Twitter sind Dienste f&uuml;r die Interaktion mit dem sozialen Netzwerk Twitter von Twitter, Inc.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://twitter.com/de/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>&bdquo;Gef&auml;llt mir&ldquo;-Schaltfl&auml;che und soziale Widgets f&uuml;r Facebook</p>
				<p>Die &bdquo;Gef&auml;llt mir&ldquo;-Schaltfl&auml;che und sozialen Widgets f&uuml;r Facebook sind Dienste f&uuml;r die Interaktion mit dem sozialen Netzwerk Facebook von Facebook, Inc. oder von Facebook Ireland Ltd, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird,</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.facebook.com/privacy/explanation">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a>.</p>

				<p>&bdquo;Pin it&ldquo;-Schaltfl&auml;che und soziale Widgets f&uuml;r Pinterest (Pinterest, Inc.)</p>
				<p>Die &bdquo;Pin it&ldquo;-Schaltfl&auml;che und sozialen Widgets f&uuml;r Pinterest sind Dienste f&uuml;r die Interaktion mit dem sozialen Netzwerk Pinterest von Pinterest Inc.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policy.pinterest.com/de/privacy-policy">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Kommerzielle Bindung</p>
				<p>Mit dieser Art von Diensten kann diese Anwendung Werbung f&uuml;r die Produkte oder Dienstleistungen Dritter anzeigen. Werbeanzeigen k&ouml;nnen entweder als Werbelinks oder als Banner mit unterschiedlichen Grafikarten angezeigt werden.<br/>Klicks auf das von der Anwendung ver&ouml;ffentlichte Icon oder Banner werden von den nachfolgend aufgef&uuml;hrten Fremddiensten nachverfolgt, verwendet und an diese Anwendung &uuml;bermittelt.<br/>Weitere Informationen dar&uuml;ber, welche Daten erhoben werden, entnehmen Sie bitte der Datenschutzerkl&auml;rung f&uuml;r den jeweiligen Dienst.</p>

				<p>Amazon Affiliation (Amazon)</p>
				<p>Amazon Affiliation ist ein kommerzieller Partnerprogramm-Dienst von Amazon.com Inc.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.amazon.com/gp/help/customer/display.html/ref=hp_rel_topic?ie=UTF8&nodeId=468496">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Kontaktieren des Nutzers</p>

				<p>Kontaktformular (diese Anwendung)</p>
				<p>Durch Ausf&uuml;llen des Kontaktformulars mit ihren Daten autorisieren Nutzer diese Anwendung, ihre Angaben zu verwenden, um auf Bitten nach Informationen, Angebote oder sonstige Anfragen, die in der Kopfzeile des Formulars angegeben sind, zu reagieren.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Nachname; Vorname.</p>

				<p>Mailingliste oder Newsletter (diese Anwendung)</p>
				<p>Durch Anmelden zur Mailingliste oder zum Newsletter wird die E-Mail-Adresse des Nutzers der Kontaktliste der Personen hinzugef&uuml;gt, die m&ouml;glicherweise E-Mail-Nachrichten mit gewerblichen oder verkaufsf&ouml;rdernden Informationen in Bezug auf diese Anwendung erhalten. Dar&uuml;ber hinaus kann Ihre E-Mail-Adresse dieser Liste zugef&uuml;gt werden, wenn Sie sich f&uuml;r diese Anwendung angemeldet haben oder nachdem Sie einen Kauf vorgenommen haben.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Geburtsdatum; Geschlecht; Land; Nachname; Vorname.</p>
				<br/><p>Plattform-Dienste und Hosting</p>
				<p>Diese Dienste haben den Zweck, Hauptkomponenten der Applikation f&uuml;r diese Anwendung zu hosten und zu betreiben, damit diese Anwendung aus einer einheitlichen Platform angeboten werden kann. Solche Plattformen stellen dem Anbieter eine ganze Reihe von Werkzeugen zur Verf&uuml;gung &ndash; zum Beispiel Analyse- und Kommentarfunktionen, Nutzer- und Datenbankverwaltung, E-Commerce und Zahlungsabwicklung &ndash; welche die Verarbeitung von personenbezogenen Daten beinhalten.<br/>Einige dieser Dienste arbeiten mit geografisch verteilten Servern, so dass es schwierig ist, den Ort zu bestimmen, an dem die personenbezogenen Daten gespeichert sind.</p>

				<p>Apple App Store (Apple Inc.)</p>
				<p>Diese Anwendung wird im App Store von Apple vertrieben, einer Plattform f&uuml;r den Vertrieb mobiler Anwendungen, die von Apple Inc. bereitgestellt wird.</p>
				<p>&nbsp;Dadurch, dass diese Anwendung &uuml;ber diesen Kanal verteilt wird, erhebt Apple grundlegende Nutzungsstatistiken und stellt Berichtsfunktionen zur Verf&uuml;gung, die es dem Anbieter erm&ouml;glichen, Nutzungsstatistiken anzuzeigen und die Leistungsdaten dieser Anwendung (diese Anwendung) zu messen.</p>
				<p>Ein Gro&szlig;teil dieser Informationen wird auf Opt-in-Basis verarbeitet. Nutzer k&ouml;nnen diese Analysefunktion direkt &uuml;ber ihre Ger&auml;teeinstellungen deaktivieren. Weitere Informationen zur Verwaltung der Analyseeinstellungen findet der Nutzer auf <a href="https://support.apple.com/en-us/HT202100">dieser Seite</a>.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.apple.com/legal/privacy/">Datenschutzerkl&auml;rung</a>.</p>

				<p>Google Play Store</p>
				<p>Diese Anwendung wird im Google Play Store vertrieben, einer Plattform f&uuml;r den Vertrieb von mobilen Apps, die von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellt wird.</p>
				<p>Dadurch, dass diese Anwendung &uuml;ber diesen Kanal verteilt wird, erhebt Google Nutzungs- und Diagnoseinformationen und teilt diese mit dem Anbieter. Ein Gro&szlig;teil dieser Informationen wird auf Opt-in-Basis verarbeitet.</p>
				<p>Nutzer k&ouml;nnen diese Analysefunktion direkt &uuml;ber ihre Ger&auml;teeinstellungen deaktivieren. Weitere Informationen zur Verwaltung der Analyseeinstellungen findet der Nutzer auf <a href="https://support.google.com/accounts/answer/6078260">dieser Seite</a>.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Registrierung und Anmeldung</p>
				<p>Mit der Registrierung oder Anmeldung berechtigen Nutzer diese Anwendung, sie zu identifizieren und ihnen Zugriff auf spezifische Dienste zu gew&auml;hren.<br/>Je nachdem, was im Folgenden angegeben ist, k&ouml;nnen Drittanbieter Registrierungs- und Anmeldedienste bereitstellen. In diesem Fall kann diese Anwendung auf einige von diesen Drittanbietern gespeicherten Daten zu Registrierungs- oder Identifizierungszwecken zugreifen.<br/>Einige der unten aufgef&uuml;hrten Dienste erheben m&ouml;glicherweise personenbezogene Daten zu Targeting- und Profilingszwecken. Weitere Informationen dazu entnehmen Sie der Beschreibung eines jeden Dienstes.</p>

				<p>Firebase Authentication</p>

				<p>Firebase Authentication ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Registrierungs- und Anmeldedienst. Um den Anmelde- und Anmeldeprozess zu vereinfachen, kann Firebase Authentication Identit&auml;tsdienste von Drittparteien benutzen und die Informationen auf ihrer Plattform speichern.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Konten in den sozialen Netzwerken; Nachname; Nutzername; Passwort; Profilbild; Telefonnummer; Vorname.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Facebook Authentication</p>
				<p>Facebook Authentication ist ein von Facebook, Inc. oder von Facebook Ireland Ltd, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Registrierungs- und Anmeldedienst, der mit dem sozialen Netzwerk Facebook verbunden ist.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.facebook.com/help/405977429438260">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://www.facebook.com/help/405977429438260">Datenschutzerkl&auml;rung</a>.</p>

				<p>Google OAuth</p>
				<p>Google OAuth ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Registrierungs- und Anmeldedienst, der mit dem Google-Netzwerk verbunden ist.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Gmail-Berechtigungen f&uuml;r den Zugriff auf Nutzerdaten (OAuth-Zusatz)</p>
				<p>Um diesen Dienst bereitzustellen, verwendet diese Anwendung eingeschr&auml;nkte Berechtigungen f&uuml;r Gmail, die den Zugriff auf Google-Nutzerdaten erm&ouml;glichen (wie unten angegeben). Dieser Zugang wird von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, <a href="https://developers.google.com/terms/api-services-user-data-policy#additional-requirements-for-specific-api-scopes">unter strengen Bedingungen</a> bereitgestellt. Dies bedeutet folgendes f&uuml;r solche Nutzerdaten:</p>
				<p>Die Verwendung von Nutzerdaten beschr&auml;nkt sich auf die Bereitstellung oder Verbesserung von nutzerbezogenen Funktionen. Nutzerdaten werden nicht f&uuml;r die Schaltung von Anzeigen verwendet, einschlie&szlig;lich Retargeting, personalisierter oder interessenbezogener Werbung.&nbsp;Nutzerdaten werden nur dann an Dritte weitergegeben, wenn dies erforderlich ist, um nutzerorientierte Funktionen bereitzustellen oder zu verbessern und wenn dies zur Einhaltung des geltenden Rechts oder im Rahmen einer Fusion, &Uuml;bernahme oder eines Verkaufs von Verm&ouml;genswerten mit Benachrichtigung der Nutzer erforderlich ist.&nbsp;Menschen werden die Daten nicht lesen, es sei denn:&nbsp;der Anbieter hat die Zustimmung des Nutzers f&uuml;r bestimmte Nachrichten eingeholt;&nbsp;es ist f&uuml;r Sicherheitszwecke und/oder zur Einhaltung des geltenden Rechts erforderlich, oder;&nbsp;Die Nutzung beschr&auml;nkt sich auf interne Vorg&auml;nge und die Daten (einschlie&szlig;lich Ableitungen) wurden aggregiert und anonymisiert.</p>
				<p>Verarbeitete personenbezogene Daten: Einf&uuml;gen und Importieren; Metadaten lesen; Modifizieren; Nur Lesezugriff; Verfassen; Verwaltung der Grundeinstellungen; Verwaltung von sensiblen Einstellungen; Voller Zugriff.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Instagram Authentication</p>
				<p>Instagram Authentication ist ein von Facebook, Inc. oder von Facebook Ireland Ltd, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Registrierungs- und Anmeldedienst, der mit dem sozialen Netzwerk Instagram verbunden ist.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://help.instagram.com/519522125107875">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://help.instagram.com/519522125107875">Datenschutzerkl&auml;rung</a>.</p>

				<p>Mit Apple anmelden (Apple Inc.)</p>
				<p>Mit Apple anmelden ist ein von Apple Inc. bereitgestellter Registrierungs- und Anmeldedienst. Soweit Nutzer die eigene E-Mail Adresse angeben m&uuml;ssen, kann Mit Apple anmelden f&uuml;r den Nutzer eine eigene Relay Adresse generieren, die Nachrichten automatisch an die verifizierte pers&ouml;nliche E-Mail Adresse des Nutzers verschickt. Damit bleibt die tats&auml;chliche E-Mail Adresse des Nutzers dem Anbieter unbekannt.</p>
				<p>Verarbeitete personenbezogene Daten: Benutzerkennung; E-Mail; Nachname; Telefonnummer; Vorname.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.apple.com/legal/privacy/">Datenschutzerkl&auml;rung</a>.</p>
				<p>Registrierung und Anmeldung unmittelbar &uuml;ber diese Anwendung</p>
				<p>Mit der Registrierung oder Anmeldung berechtigen Nutzer diese Anwendung, sie zu identifizieren und ihnen Zugriff auf spezifische Dienste zu gew&auml;hren. Personenbezogene Daten werden ausschlie&szlig;lich zu Registrierungs- und Identifizierungszwecken erhoben und gespeichert. Betroffen sind nur solche Daten, die f&uuml;r die Erbringung der vom Nutzer gew&uuml;nschten Dienstleistung notwendig sind.</p>

				<p>Direkte Registrierung (diese Anwendung)</p>
				<p>Nutzer registrieren sich, indem sie das Anmeldeformular ausf&uuml;llen und ihre personenbezogenen Daten direkt &uuml;ber diese Anwendung angeben.</p>
				<p>Verarbeitete personenbezogene Daten: Adresse; E-Mail; Geburtsdatum; Geschlecht; Land; Nachname; Nutzername; Nutzungsdaten; Passwort; Profilbild; Sprache; Stadt; Vorname.</p>
				<br/><p>Remarketing und Behavioural-Targeting</p>
				<p>Mit dieser Art von Diensten k&ouml;nnen diese Anwendung und ihre Partner analysieren, wie diese Anwendung in vorherigen Sitzungen des Nutzers verwendet wurde, um Werbung gezielt zu verbreiten, zu optimieren und einzusetzen.<br/>Diese Aktivit&auml;t wird durch die Nachverfolgung von Nutzungsdaten und die Verwendung von Trackern erleichtert, die Informationen erheben, welche dann an die Partner &uuml;bertragen werden, die die Remarketing- und Behavioral Targeting-Aktivit&auml;ten verwalten.<br/>Einige Dienste bieten eine Remarketing-Option auf Basis von E-Mail-Adressenlisten.<br/>Zus&auml;tzlich zu den etwaig von den einzelnen nachfolgend aufgef&uuml;hrten Diensten angebotenen Widerspruchsm&ouml;glichkeiten k&ouml;nnen Nutzer &uuml;ber die <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative opt-out page</a> ihren Widerspruch erkl&auml;ren.</p>

				<p>Die Nutzer k&ouml;nnen auch bestimmte Werbefunktionen durch entsprechende Ger&auml;teeinstellungen deaktivieren, wie beispielsweise die Ger&auml;tewerbeeinstellungen f&uuml;r Mobiltelefone oder die Werbeeinstellungen im Allgemeinen</p>


				<p>AdRoll (NextRoll, Inc.)</p>
				<p>AdRoll ist ein Werbedienst der NextRoll, Inc. AdRoll kann zielgerichtete Werbung auf jedem auf denselben Nutzer zur&uuml;ckzuf&uuml;hrendes Ger&auml;t ausspielen, indem die E-Mail-Adresse solcher Nutzer&nbsp;</p>
				<p>mit der Technik des sog. &bdquo;Hashing&ldquo; verarbeitet wird</p>
				<p>AdRoll kann auch bestimmte Datenkategorien automatisch erheben, um dem Nutzer personalisierte Empfehlungen auszusprechen, wie in dieser Datenschutzerkl&auml;rung dargestellt.</p>

				<p>Verarbeitete personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Einkaufsverlauf; Ger&auml;teinformationen; Nutzungsdaten; Tracker; verschiedene Datenarten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.nextroll.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://app.adroll.com/optout/">Opt Out</a>; Irland &ndash; <a href="https://www.nextroll.com/de-DE/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://app.adroll.com/optout/">Opt Out</a>.</p>

				<p>Facebook Remarketing</p>
				<p>Facebook Remarketing ist ein Remarketing- und Behavioural-Targeting-Dienst von Facebook, Inc. oder von Facebook Ireland Ltd, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird,, der die &uuml;ber diese Anwendung stattfindende Aktivit&auml;t mit dem Facebook -Werbenetzwerk verbindet.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>; Irland &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>.</p>

				<p>Google Ads Remarketing</p>
				<p>Google Ads Remarketing ist ein Remarketing- und Behavioral Targeting-Service von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird,, der die Aktivit&auml;t von diese Anwendung mit dem Google Ads-Werbenetzwerk und dem DoubleClick Cookie verbindet.</p>
				
				<p>Nutzer k&ouml;nnen die Verwendung von Cookies durch Google ablehnen, indem sie die <a href="https://adssettings.google.com/authenticated">Anzeigeneinstellungen</a> von Google aufrufen.</p>
				<br/><p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated?hl=de">Opt Out</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated#display_optout">Opt Out</a>.</p>

				<p>Google Ad Manager Audience Extension</p>
				<p>Die Google Ad Manager Audience Extension ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Remarketing- und Behavioural-Targeting-Dienst, der die Besucher von diese Anwendung nachverfolgt und es ausgew&auml;hlten Werbepartnern erm&ouml;glicht, ihnen gezielte Anzeigenwerbung im Internet anzuzeigen.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated#display_optout">Opt Out</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated#display_optout">Opt Out</a>.</p>

				<p>Google Signals</p>
				<p>Diese Anwendung verwendet Google Signals, eine Funktion von Google Analytics, die die Besuchsinformationen, die sie &uuml;ber diese Anwendung sammelt, mit Google-Informationen von Konten von angemeldeten Google-Konto-Nutzern verkn&uuml;pft, die dieser Verbindung zum Zwecke der Anzeigenpersonalisierung zugestimmt haben. Diese Google-Informationen k&ouml;nnen den Standort des Nutzers, den Suchverlauf, den YouTube-Verlauf und Daten von Websites enthalten, die mit Google zusammenarbeiten - und werden verwendet, um aggregierte und anonymisierte Einblicke in das ger&auml;te&uuml;bergreifende Verhalten der Nutzer zu erm&ouml;glichen.</p>
				<p>Wenn ein Nutzer dem beschriebenen Zusammenhang unterliegt, <a href="https://myactivity.google.com/myactivity">kann er &uuml;ber &bdquo;Meine Aktivit&auml;ten&ldquo; von Google auf diese Daten zugreifen und/oder sie l&ouml;schen</a>.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated#display_optout">Opt Out</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated">Opt Out</a>.</p>

				<p>Facebook Custom Audience</p>
				<p>Facebook Custom Audience ist ein Remarketing- und Behavioral Targeting-Service von Facebook, Inc. oder von Facebook Ireland Ltd, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird,, der die Aktivit&auml;t von diese Anwendung mit dem Facebook-Werbenetzwerk verbindet.</p>
				<p>Nutzer k&ouml;nnen die Verwendung von Cookies zur Personalisierung von Anzeigen durch Facebook ablehnen, indem sie diese <a href="https://www.aboutads.info/choices/">Opt-out-Seite</a> besuchen.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>; Irland &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>.</p>
				<p>Soziale Funktionen</p>

				<p>Firebase Dynamic Links</p>
				<p>Firebase Dynamic Links ist eine soziale Funktion, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird. Dynamic Links werden in Firebase oder Google Analytics f&uuml;r Firebase verfolgt und informieren den Eigent&uuml;mer &uuml;ber die Details Nutzerweges zu und innerhalb dieses Dienstes (diese Anwendung).</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Invites</p>
				<p>Firebase Invites ist eine soziale Funktion, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird,, die es Nutzern erm&ouml;glicht, diese Anwendung zu teilen. Die Freigabe kann Referenzcodes oder diese Anwendung-Inhalte enthalten und per E-Mail oder SMS erfolgen. Die Freigabe wird mit Google Analytics f&uuml;r Firebase verfolgt, das den Eitgent&uuml;mer dar&uuml;ber informiert, dass er diese Anwendung &uuml;ber Invite ge&ouml;ffnet oder installiert hat.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<br/><p>Einladen und Vorschlagen von Freunden (diese Anwendung)</p>
				<p>Diese Anwendung kann die erteilten personenbezogenen Daten verwenden, um es Nutzern zu erm&ouml;glichen, ihre Freunde einzuladen &ndash; z. B. &uuml;ber das Adressbuch, falls ein Zugriff hierauf gew&auml;hrt wurde &ndash; und im Adressbuch enthaltene Freunde oder Kontakte anderen vorzuschlagen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten.</p>

				<br/><p>&Ouml;ffentliches Profil (diese Anwendung)</p>

				<p>Nutzer k&ouml;nnen &ouml;ffentliche Profile anlegen, die andere Nutzer anzeigen k&ouml;nnen. Neben den erteilten personenbezogenen Daten k&ouml;nnen solche Profile die Interaktionen des Nutzers enthalten, die &uuml;ber diese Anwendung stattfinden.</p>
				<p>Verarbeitete personenbezogene Daten: Bild; Geschlecht; Nachname; Nutzername; Vorname.</p>
				<br/><p>Standortbezogene Interaktionen</p>

				<p>Geolocation (diese Anwendung)</p>
				<p>Diese Anwendung kann Positionsdaten des Nutzers erheben, nutzen und teilen, um standortbezogene Dienste zu erbringen.<br/>Die meisten Browser und Ger&auml;te sind standardgem&auml;&szlig; so eingestellt, dass diese Funktion deaktiviert ist. Diese Anwendung kann auf die Positionsdaten des Nutzers zugreifen, falls eine ausdr&uuml;ckliche Genehmigung erteilt wurde.</p>
				<p>Verarbeitete personenbezogene Daten: Geografische Position.</p>

				<p>Nicht kontinuierliche Geolokalisierung (diese Anwendung)</p>
				<p>Diese Anwendung kann Positionsdaten des Nutzers erheben, nutzen und teilen, um standortbezogene Dienste zu erbringen.<br/>Die meisten Browser und Ger&auml;te sind standardgem&auml;&szlig; so eingestellt, dass diese Funktion deaktiviert ist. Diese Anwendung kann auf die Positionsdaten des Nutzers zugreifen, falls eine ausdr&uuml;ckliche Genehmigung erteilt wurde.<br/>Der geographische Standort des Nutzers wird in einer nicht kontinuierlichen Weise ermittelt, entweder auf ausdr&uuml;cklichen Wunsch des Nutzers oder wenn der Nutzer den aktuellen Standort nicht in das entsprechende Feld eingibt und es der Anwendung erlaubt, den Standort automatisch zu ermitteln.</p>
				<p>Verarbeitete personenbezogene Daten: Geografische Position.</p>
				<br/><p>Testen der Performance von Inhalten und Funktionen (A/B-Test)</p>
				<p>Mit den in diesem Abschnitt enthaltenen Diensten kann der Anbieter die Reaktion von Nutzern auf den Datenverkehr der Website oder ihr Verhalten, nachdem diese Anwendung in Bezug auf Struktur, Text oder sonstigen Komponenten ver&auml;ndert wurde, nachverfolgen und analysieren.</p>

				<p>Firebase Remote Config (Google LLC)</p>
				<p>Firebase Remote Config ist ein von Google LLC bereitgestellter Service zur Durchf&uuml;hrung von A/B-Tests und Konfigurationen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Google Optimize (Google LLC)</p>

				<p>Google Optimize ist ein von Google LLC (&quot;Google&quot;). bereitgestellter Service zur Durchf&uuml;hrung von A/B-Tests.</p>
				<p>Google kann personenbezogene Daten verwenden, um die Anzeigen seines eigenen Werbenetzwerks zu kontextualisieren und analysieren.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Google Optimize 360</p>
				<p>Google Optimize 360 ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, (&quot;Google&quot;) bereitgestellter Service zur Durchf&uuml;hrung von A/B-Tests.</p>
				
				<p>Dieser Dienst erm&ouml;glicht es dem Anbieter, Zielgruppen von Nutzern auf der Grundlage ihrer bisherigen Nutzung von dieser Applikation (diese Anwendung) zu erreichen. Nutzer, die sich gegen Nachverfolgung durch Google Analytics entscheiden <a href="https://tools.google.com/dlpage/gaoptout?hl">(Opt-out)</a>, werden nicht in Experimente einbezogen, die in Google Optimize 360 erstellt wurden.</p>
				
				<p>Google kann personenbezogene Daten verwenden, um die Anzeigen seines eigenen Werbenetzwerks zu kontextualisieren und analysieren.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Umgang mit Aktivit&auml;tsdaten</p>
				<p>Diese Art von Diensten erm&ouml;glichen es dem Anbieter, die vom Ger&auml;t verarbeiteten Aktivit&auml;tsdaten f&uuml;r den Betrieb oder f&uuml;r das Anbieten bestimmter Funktionen zu nutzen. Dies kann Bewegungen, Herzschlag, H&ouml;henunterschiede oder Daten &uuml;ber die Umgebung beinhalten.<br/>Je nachdem, was nachfolgend beschrieben wird, k&ouml;nnen Dritte an der Aktivit&auml;tserfassung beteiligt sein.<br/>Bei den meisten Ger&auml;ten kann der Benutzer festlegen, welche Daten abgerufen oder gespeichert werden.</p>

				<p>Apple HealthKit (Apple Inc.)</p>

				<p>HealthKit ist ein von Apple Inc. bereitgestellter Dienst f&uuml;r Aktivit&auml;tsdaten, der es dem Eigent&uuml;mer erlaubt auf diese zuzugreifen oder diese zu speichern.</p>
				<p>Verarbeitete personenbezogene Daten: Aktivit&auml;t bez&uuml;glich Schlaf; Aktivit&auml;ten bez&uuml;glich Bewegung; Aktivit&auml;ten bez&uuml;glich Ern&auml;hrung; Allgemeine Aktivit&auml;tsdaten; Blutgruppe; Geburtsdatum; Geschlecht; K&ouml;rperma&szlig;e &amp; Indizes.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.apple.com/legal/privacy/">Datenschutzerkl&auml;rung</a>.</p>

				<p>Vom Ger&auml;t aufgezeichnete Aktivit&auml;tsdaten (diese Anwendung)</p>
				<p>Diese Anwendung kann die von Ihrem Ger&auml;t aufgezeichneten Aktivit&auml;tsdaten nutzen, um bestimmte Funktionen anzubieten oder zu funktionieren.</p>
				<p>Verarbeitete personenbezogene Daten: Aktivit&auml;ten bez&uuml;glich Ern&auml;hrung; Allgemeine Aktivit&auml;tsdaten; Geburtsdatum; Geschlecht; K&ouml;rperma&szlig;e &amp; Indizes.</p>
				<br/><p>Umgang mit Zahlungen</p>
				<p>Sofern nicht anders angegeben, verarbeitet diese Anwendung alle Zahlungen per Kreditkarte, Bank&uuml;berweisung oder auf andere Weise &uuml;ber externe Zahlungsdienstleister. Im Allgemeinen und sofern nicht anders angegeben, werden die Nutzer aufgefordert, ihre Zahlungsdaten und personenbezogenen Daten direkt an diese Zahlungsdienstleister weiterzugeben.<br/>Diese Anwendung ist nicht an der Erhebung und Verarbeitung solcher Informationen beteiligt, sondern erh&auml;lt nur eine Mitteilung des jeweiligen Zahlungsdienstleisters, ob die Zahlung erfolgreich abgeschlossen wurde.</p>

				<p>Apple Pay (Apple Inc.)</p>
				<p>Apple Pay ist ein Zahlungsdienst von Apple Inc., der es Nutzern erm&ouml;glicht, mit ihren Mobiltelefonen Zahlungen vorzunehmen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.apple.com/legal/privacy/de-ww/">Datenschutzerkl&auml;rung</a>.</p>

				<p>PayPal (Paypal)</p>

				<p>PayPal ist ein Zahlungsdienst von PayPal Inc., der es Nutzern erm&ouml;glicht Online-Zahlungen vorzunehmen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Siehe die PayPal-Datenschutzerkl&auml;rung &ndash; <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Verwalten von Kontakten und Versenden von Nachrichten</p>
				<p>Diese Art von Diensten erm&ouml;glichen die Verwaltung einer Datenbank mit E-Mail-Kontakten, Rufnummern oder jeglichen weiteren Kontaktinformationen, um mit dem Nutzer zu kommunizieren.<br/>Die Dienste k&ouml;nnen auch Daten dar&uuml;ber sammeln, an welchem Datum und zu welcher Uhrzeit Nachricht vom Nutzer gelesen wurde, sowie dar&uuml;ber, wann der Nutzer mit eingehenden Nachrichten interagiert, beispielsweise durch Klicken auf Links, die darin enthalten sind.</p>

				<p>Firebase Cloud Messaging</p>
				<p>Firebase Cloud Messaging ist ein Dienst f&uuml;r den Nachrichtenversand, der von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird. bereitgestellt wird. Firebase Cloud Messaging erm&ouml;glicht dem Eigent&uuml;mer, &uuml;ber Plattformen wie Android, iOS und das Web Nachrichten und Benachrichtigungen an Nutzer zu senden. Nachrichten k&ouml;nnen an einzelne Ger&auml;te, Ger&auml;tegruppen, zu bestimmten Themen oder an bestimmte Benutzersegmente gesendet werden.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Notifications</p>
				<p>Firebase Notifications ist ein Dienst f&uuml;r den Nachrichtenversand von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird. Firebase Notifications k&ouml;nnen in Firebase Analytics integriert werden, um auf Analyse basierende Zielgruppen zu kreieren und um Konversion- und Zustellungsstatistiken zugreifen zu k&ouml;nnen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Verwalten von RSS-Feeds</p>
				<p>Mit dieser Art von Diensten kann diese Anwendung RSS-Feeds verwalten und ihre Inhalte verbreiten. Je nach den Eigenschaften des verwendeten Diensts kann dieser daf&uuml;r verwendet werden, Werbeanzeigen in die Inhalte zu platzieren und statistische Daten aus ihnen zu erheben.</p>

				<p>Feedburner (Google LLC)</p>
				<p>Feedburner ist ein von Google LLC bereitgestellter Dienst f&uuml;r die Verwaltung von RSS-Feeds. Er erm&ouml;glicht die Erhebung von statistischen Daten dar&uuml;ber, welche Inhalte aufgerufen wurden, sowie das Hinzuf&uuml;gen von Werbeanzeigen.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="http://www.google.com/intl/en/policies/privacy/">Datenschutzerkl&auml;rung</a>.</p>
				<br/><p>Verwaltung von Datenerfassung und Online-Umfragen</p>
				<p>Mit dieser Art von Diensten kann diese Anwendung die Erstellung, Bereitstellung, Verwaltung, Verteilung und Analyse von Online-Formularen und Umfragen verwalten, um Daten von allen antwortenden Nutzern zu erfassen, zu speichern und wiederzuverwenden.<br/>Die erfassten personenbezogenen Daten h&auml;ngen von den Informationen ab, die von den Nutzern im entsprechenden Online-Formular abgefragt und zur Verf&uuml;gung gestellt werden.</p>
				<p>Diese Dienste k&ouml;nnen mit einer Vielzahl von Drittanbieterdiensten integriert werden, um es dem Anbieter zu erm&ouml;glichen, mit den verarbeiteten Daten weitere Schritte zu unternehmen - z.B. Verwaltung von Kontakten, Versand von Nachrichten, Analytik, Werbung und Zahlungsabwicklung.</p>

				<p>Facebook lead ads</p>

				<p>Facebook lead ads ist ein durch Facebook, Inc. oder von Facebook Ireland Ltd, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Werbe- und Datenerhebungsdienst, durch den formularbasierte Werbung mit personenbezogenen Daten, wie z. B. Namen und E-Mail Adressen, aus dem Facebook Profil des Nutzers vervollst&auml;ndigt und angezeigt wird. Je nach Art der Werbeanzeige, k&ouml;nnten weitere Informationen vom Nutzer angefordert werden.</p>
				
				<p>Die &Uuml;bermittlung des Formularinhaltes hat zur Folge, dass der Anbieter die darin enthaltenen Daten erhebt und ausschlie&szlig;lich zu den Zwecken, die im Formular selbst und/oder in dieser Datenschutzerkl&auml;rung bestimmt sind, verarbeitet.</p>
				
				<p>Nutzer d&uuml;rfen jederzeit nach Ma&szlig;gabe der Angaben innerhalb des Abschnitts dieser Datenschutzerkl&auml;rung &uuml;ber Nutzerrechte die eigenen Rechte &ndash; einschlie&szlig;lich des Rechts auf Widerruf der Einwilligung in die Datenverarbeitung &ndash; aus&uuml;ben.</p>
				<p>Verarbeitete personenbezogene Daten: Adresse; E-Mail; Geburtsdatum; Geschlecht; Land; Nachname; Nutzungsdaten; Staat; Stadt; Telefonnummer; Tracker; Vorname; W&auml;hrend der Nutzung des Dienstes &uuml;bermittelte Daten.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">Opt out</a>; Irland &ndash; <a href="https://www.facebook.com/about/privacy/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">Opt out</a>.</p>
				<br/><p>Werbung</p>
				<p>Diese Art von Diensten erm&ouml;glicht die Nutzung von Nutzerdaten zu Werbezwecken. Werbeansprachen werden in Form von Bannern und anderen Anzeigen &uuml;ber diese Anwendung eingeblendet und m&ouml;glicherweise verhaltensbasiert angepasst. Das hei&szlig;t nicht, dass alle personenbezogenen Daten f&uuml;r diesen Zweck benutzt werden. Weitere Informationen und Nutzungsbedingungen sind nachfolgend aufgef&uuml;hrt.<br/>Einige der nachfolgend aufgef&uuml;hrten Dienste verwenden m&ouml;glicherweise Tracker ein, um Nutzer zu identifizieren, oder setzen sogenanntes Behavioural-Retargeting ein. Mit dieser Methode k&ouml;nnen auch Interessen und Surfverhaltensweisen von Nutzern identifiziert werden, die nicht &uuml;ber diese Anwendung stattfinden, um Werbeanzeigen gezielt darauf abzustimmen. Weitere Informationen entnehmen Sie bitte den Datenschutzerkl&auml;rungen der jeweiligen Dienste.<br/>Zus&auml;tzlich zu den etwaig von den einzelnen nachfolgend aufgef&uuml;hrten Diensten angebotenen Widerspruchsm&ouml;glichkeiten k&ouml;nnen Nutzer &uuml;ber die <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative opt-out page</a> ihren Widerspruch erkl&auml;ren.</p>

				<p>Die Nutzer k&ouml;nnen auch bestimmte Werbefunktionen durch entsprechende Ger&auml;teeinstellungen deaktivieren, wie beispielsweise die Ger&auml;tewerbeeinstellungen f&uuml;r Mobiltelefone oder die Werbeeinstellungen im Allgemeinen</p>

				<p>Google Ad Manager (Google LLC)</p>
				<p>Google Ad Manager ist ein Werbedienst, der von Google LLC zur Verf&uuml;gung gestellt wird und es dem Anbieter erm&ouml;glicht, Werbekampagnen in Verbindung mit externen Werbenetzwerken zu schalten. Der Anbieter steht dabei mit den Drittparteien in keinem direkten Verh&auml;ltnis, solange es in diesem Dokument nicht anders festgelegt wurde.<br/>Um die R&uuml;ckverfolgung von diversen Werbenetzwerken zu verweigern, k&ouml;nnen Nutzer von <a href="http://www.youronlinechoices.com/">Youronlinechoices</a> Gebrauch machen. Um mehr &uuml;ber die Datennutzung von Google zu erfahren, wenden Sie sich an&nbsp;<a href="https://www.google.com/intl/de/policies/privacy/partners/">Google&apos;s partner policy</a>.<br/>Dieser Dienst verwendet den &bdquo;DoubleClick&ldquo;-Cookie, um nachzuverfolgen, wie diese Anwendung genutzt wird und wie Nutzer auf Werbeanzeigen sowie auf angebotene Produkte und Dienstleistungen reagieren.</p>
				
				<p>Nutzer k&ouml;nnen sich dazu entscheiden, alle DoubleClick-Cookies zu deaktivieren, indem Sie diese Seite besuchen: <a href="https://adssettings.google.com/authenticated?hl=de">Einstellungen f&uuml;r Werbung</a>.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>AdMob (Google LLC)</p>
				<p>AdMob ist ein Werbedienst von Google LLC.</p>
				<p>Weitere Informationen zur Verwendung von Daten bei Google sind in der&nbsp;</p>
				<p><a href="https://www.google.com/intl/de/policies/privacy/partners/">Partner-Richtlinie von Google einsehbar</a>.</p>

				<p>Verarbeitete personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.google.com/policies/technologies/ads/">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated">Opt Out</a>.</p>

				<p>Direktwerbung (diese Anwendung)</p>
				<p>Diese Anwendung setzt Nutzerdaten ein, um mit den &uuml;ber diese Anwendung angebotenen Produkten oder Leistungen zusammenh&auml;ngende oder auch nicht zusammenh&auml;ngende Leistungen und Produkte Dritter vorzuschlagen.</p>
				
				<p>Direktwerbung kann u. U. durch Tracker, die das Nutzerverhalten analysieren und personalisierte Werbung ausliefern, erfolgen. Nutzer k&ouml;nnen der Werbepersonalisierung widersprechen, indem sie ihre Ger&auml;teinstellungen entsprechend festlegen oder die Anweisungen auf <a href="https://www.youronlinechoices.com/">YourOnlineChoices</a> und <a href="http://www.aboutads.info/choices/">AdChoices</a> befolgen.</p>
				
				<p>Unabh&auml;ngig von den Ger&auml;teinstellungen k&ouml;nnen Nutzer die in dem entsprechenden Abschnitt dieser Datenschutzerkl&auml;rung erw&auml;hnten Rechte aus&uuml;ben.</p>
				<p>Verarbeitete personenbezogene Daten: Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Ger&auml;teinformationen; Nutzungsdaten; Tracker; Universally Unique Identifier (UUID).</p>

				<p>Google AdSense (Google LLC)</p>
				<p>Google AdSense ist ein Werbedienst von Google LLC. Dieser Dienst verwendet den &bdquo;DoubleClick&ldquo;-Cookie, um nachzuverfolgen, wie diese Anwendung genutzt wird und wie Nutzer auf Werbeanzeigen sowie auf angebotene Produkte und Dienstleistungen reagieren.<br/>Nutzer k&ouml;nnen sich dazu entscheiden, alle DoubleClick-Cookies zu deaktivieren, indem Sie diese Seite besuchen: <a href="https://adssettings.google.com/authenticated?hl=de">Einstellungen f&uuml;r Werbung</a>.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated?hl=de">Opt Out</a>.</p>

				<p>Direct-E-Mail-Marketing (DEM) (diese Anwendung)</p>
				<p>Diese Anwendung verwendet die Nutzerdaten, um Dienstleistungen und Produkte zu empfehlen, die von Dritten angeboten werden oder mit den &uuml;ber diese Anwendung angebotenen Produkten oder Dienstleistungen nicht im Zusammenhang stehen.</p>
				<p>Verarbeitete personenbezogene Daten: E-Mail; Nachname; Vorname.</p>

				<p>Google Ads &auml;hnliche Zielgruppen (Google LLC)</p>
				<p>Google Ads &auml;hnliche Zielgruppen ist ein Werbe- und Behavioral Targeting-Service von Google LLC, der Daten aus Google Ads Remarketing verwendet, um Anzeigen f&uuml;r Nutzer mit &auml;hnlichem Verhalten zu schalten, die aufgrund ihrer fr&uuml;heren Nutzung von diese Anwendung bereits auf der Remarketing-Liste stehen.<br/>Auf der Grundlage dieser Daten werden personalisierte Anzeigen den von Google Ads &auml;hnliche Zielgruppen vorgeschlagenen Nutzern angezeigt.</p>
				
				<p>Nutzer, die nicht in Google Ads &auml;hnliche Zielgruppen aufgenommen werden m&ouml;chten, k&ouml;nnen dies ablehnen und die Verwendung von Werbe-Cookies deaktivieren: <a href="https://adssettings.google.com/authenticated">Google-Anzeigen-Einstellungen</a>.</p>
				<p>Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://adssettings.google.com/authenticated">Opt Out</a>.</p>

				<p>Pinterest Ads (Pinterest, Inc.)</p>
				<p>Pinterest Ads ist ein Werbedienst von Pinterest, Inc., der Nutzern die M&ouml;glichkeit gibt, Werbekampagnen &uuml;ber das Pinterest-Werbenetzwerk durchzuf&uuml;hren.</p>
				
				<p>Nutzer k&ouml;nnen durch entsprechende Einstellungen im eigenen Endger&auml;t <a href="https://www.pinterest.com/settings/">oder auf Pinterest</a>, sowie &uuml;ber die Seite <a href="https://www.iubenda.com/privacy-policy/optout.aboutads.info">AdChoices opt-out page</a> verhaltensbasierten Werbefunktionen widersprechen.</p>
				<p>Verarbeitete personenbezogene Daten: Benutzerkennung; E-Mail; Eindeutige Ger&auml;tekennzeichnung f&uuml;r Werbung (Google-Werbe-ID oder IDFA, beispielsweise); Ger&auml;teinformationen; Nutzungsdaten; Tracker.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policy.pinterest.com/de/privacy-policy">Datenschutzerkl&auml;rung</a> &ndash; <a href="https://policy.pinterest.com/de/privacy-policy#section-choices-you-have-about-your-info">Opt out</a>.</p>
				<br/><p>&Uuml;berwachung der Infrastruktur</p>
				<p>Mit dieser Art von Diensten kann diese Anwendung zur Verbesserung der Leistung, des Betriebs, der Wartung und der Fehlersuche die Nutzung und das Verhalten ihrer einzelnen Bestandteile &uuml;berwachen.<br/>Welche personenbezogenen Daten verarbeitet werden, h&auml;ngt von den Eigenschaften und der Art der Ausf&uuml;hrung der Dienste ab, deren Funktion das Filtern der &uuml;ber diese Anwendung stattfindenden Aktivit&auml;ten ist.</p>

				<p>Crashlytics mit User Opt-in</p>
				<p>Crashlytics mit User Opt-in ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Dienst f&uuml;r die &Uuml;berwachung von Applikationen.</p>
				
				<p>Diese Anwendung erm&ouml;glicht es Nutzern, sich f&uuml;r die Erhebung von Crash-Berichten oder Analysen zu entscheiden (Opt-in Ansatz).</p>
				<p>Verarbeitete personenbezogene Daten: Crash-Informationen; Ger&auml;teinformationen; Universally Unique Identifier (UUID).</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.google.com/policies/privacy/">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://www.google.com/policies/privacy/">Datenschutzerkl&auml;rung</a>.</p>

				<p>Crashlytics</p>
				<p>Crashlytics ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird,, bereitgestellter Dienst f&uuml;r die &Uuml;berwachung von Applikationen.</p>
				<p>Verarbeitete personenbezogene Daten: Crash-Informationen; Ger&auml;teinformationen; Universally Unique Identifier (UUID).</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://www.google.com/policies/privacy/">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<p>Firebase Performance Monitoring</p>
				<p>Firebase Performance Monitoring ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird, bereitgestellter Dienst f&uuml;r die &Uuml;berwachung von Applikationen.</p>
				<p>Verarbeitete personenbezogene Daten: verschiedene Datenarten, wie in der Datenschutzerkl&auml;rung des Dienstes beschrieben.</p>
				<p>Verarbeitungsort: Vereinigte Staaten &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>; Irland &ndash; <a href="https://policies.google.com/privacy">Datenschutzerkl&auml;rung</a>.</p>

				<br/><p>Weitere Informationen zu personenbezogenen Daten</p>

				<p>Analyse und Prognosen auf Basis der Nutzerdaten (&quot;Profiling&quot;)</p>
				<p>Der Anbieter kann die &uuml;ber diese Anwendung erhobenen personenbezogenen Daten und Nutzungsdaten verwenden, um Nutzerprofile zu erstellen oder zu aktualisieren.<br/>Diese Art der Datenverarbeitung erm&ouml;glicht es dem Anbieter, die Auswahl, die Pr&auml;ferenzen und das Verhalten des Nutzers f&uuml;r die im jeweiligen Abschnitt dieses Dokuments beschriebenen Zwecke auszuwerten. Nutzerprofile k&ouml;nnen auch durch den Einsatz von automatisierten Tools wie Algorithmen erstellt werden, die auch von Dritten zur Verf&uuml;gung gestellt werden k&ouml;nnen. Um mehr &uuml;ber die durchgef&uuml;hrten Profiling-T&auml;tigkeiten zu erfahren, k&ouml;nnen die Nutzer die entsprechenden Abschnitte dieses Dokuments nachlesen.<br/>Der Nutzer hat jederzeit das Recht, dieser Art der Profilings zu widersprechen. N&auml;heres zu den Rechten des Nutzers und deren Aus&uuml;bung k&ouml;nnen dem Abschnitt &uuml;ber die Rechte des Nutzers entnommen werden.</p>
				<br/><p>Automatisierte Entscheidungsfindung</p>
				<p>Automatisierte Entscheidungsfindung bedeutet, dass eine Entscheidung, die rechtliche oder &auml;hnlich bedeutende Auswirkungen auf den Nutzer haben kann, ausschlie&szlig;lich mit automatisierten Mitteln und ohne menschliches Zutun getroffen wird.<br/>Diese Anwendung kann die personenbezogenen Daten des Nutzers verwenden, um Entscheidungen ganz oder teilweise auf der Grundlage automatisierter Prozesse im Rahmen der in diesem Dokument beschriebenen Zwecken zu treffen.<br/>Diese Anwendung nutzt automatisierte Entscheidungsprozesse, soweit dies f&uuml;r den Abschluss oder die Durchf&uuml;hrung eines Vertrages zwischen Nutzer und Anbieter erforderlich ist oder auf der Grundlage der ausdr&uuml;cklichen Zustimmung des Nutzers, sofern dies gesetzlich vorgeschrieben ist. Automatisierte Entscheidungen werden mit technologischen Mitteln - meist auf Basis von Algorithmen nach vorgegebenen Kriterien - getroffen, die auch von Dritten zur Verf&uuml;gung gestellt werden k&ouml;nnen.<br/>Die der automatisierten Entscheidungsfindung zugrunde liegende Logik ist:</p>
				<p>den Entscheidungsprozess zu erm&ouml;glichen oder anderweitig zu verbessern;&nbsp;den Nutzern eine faire und unvoreingenommene Behandlung auf der Grundlage gleichbleibender und einheitlicher Kriterien zu gew&auml;hrleisten;&nbsp;den potenziellen Schaden aufgrund menschlichen Irrtums, pers&ouml;nlicher Vorurteile oder anderer Umst&auml;nde, die zu Diskriminierung oder Missverh&auml;ltnissen in der Behandlung nat&uuml;rlicher Personen (usw.) f&uuml;hren k&ouml;nnen, zu verringern;&nbsp;das Risiko der Nichterf&uuml;llung der vertraglichen Verpflichtungen des Nutzers zu verringern.</p>
				<p>Um mehr &uuml;ber die Zwecke, die Dienste Dritter, falls vorhanden, und eine detaillierte Erl&auml;uterung automatisierter Entscheidungen in diesem Dienst (diese Anwendung) herauszufinden, k&ouml;nnen die Nutzer die entsprechenden Abschnitte in diesem Dokument &uuml;berpr&uuml;fen.</p>

				<p>Konsequenzen automatisierter Entscheidungsprozesse f&uuml;r die Nutzer und Rechte der betroffenen Nutzer</p>

				<p>Vor diesem Hintergrund sind Nutzer, die einer solchen Verarbeitung unterliegen, berechtigt, besondere Rechte auszu&uuml;ben, die darauf abzielen, m&ouml;gliche Auswirkungen der getroffenen automatisierten Entscheidungen zu verhindern oder anderweitig zu begrenzen.</p>
				
				<p>Insbesondere haben Nutzer das Recht:</p>
				<p>eine Erkl&auml;rung &uuml;ber jede Entscheidung, die aufgrund einer automatisierten Entscheidungsfindung getroffen wurde, zu erhalten und ihren eigenen Standpunkt zu dieser Entscheidung darzulegen;&nbsp;eine Entscheidung anzufechten, indem sie den Anbieter auffordern, sie zu &uuml;berdenken oder eine neue Entscheidung auf einer anderen Grundlage zu treffen;&nbsp;auf menschlichen Eingriff des Anbieters in eine solche Verarbeitung.</p>
				<p>N&auml;heres zu den Rechten des Nutzers und deren Aus&uuml;bung k&ouml;nnen dem Abschnitt &uuml;ber die Rechte des Nutzers entnommen werden.</p>
				<br/><p>Push-Benachrichtigungen</p>
				<p>Diese Anwendung kann Push-Benachrichtigungen an den Nutzer senden, um die in dieser Datenschutzerkl&auml;rung genannten Zwecke zu erreichen.</p>
				
				<p>In den meisten F&auml;llen k&ouml;nnen Nutzer den Empfang von Push-Benachrichtigungen ablehnen, indem sie ihre Ger&auml;teeinstellungen, wie beispielsweise die Mitteilungshinweise f&uuml;r Mobiltelefone, aufrufen und diese Einstellungen dann f&uuml;r diese Anwendung, f&uuml;r einige oder alle Anwendungen auf dem jeweiligen Ger&auml;t &auml;ndern.<br/>Nutzer m&uuml;ssen sich bewusst sein, dass das Deaktivieren von Push-Benachrichtigungen diese Anwendung und den Nutzen negativ beeinflussen kann.</p>
				<p>Push-Benachrichtigungen basierend auf dem geografischen Standort des Nutzers</p>
				<p>Diese Anwendung kann den geografischen Standort des Nutzers nutzen, um Push-Benachrichtigungen f&uuml;r die in dieser Datenschutzerkl&auml;rung beschriebenen Zwecke zu versenden.</p>
				
				<p>In den meisten F&auml;llen k&ouml;nnen Nutzer den Empfang von Push-Benachrichtigungen ablehnen, indem sie ihre Ger&auml;teeinstellungen, wie beispielsweise die Mitteilungshinweise f&uuml;r Mobiltelefone, aufrufen und diese Einstellungen dann f&uuml;r diese Anwendung, f&uuml;r einige oder alle Anwendungen auf dem jeweiligen Ger&auml;t &auml;ndern.</p>
				
				<p>Nutzer m&uuml;ssen sich bewusst sein, dass das Deaktivieren von Push-Benachrichtigungen diese Anwendung und den Nutzen negativ beeinflussen kann.</p>
				<br/><p>localStorage</p>
				<p>Durch localStorage kann diese Anwendung Daten im Browser des Nutzers ohne Zeitbegrenzung speichern und abrufen.</p>
				<p>Personenbezogene Daten, die nicht beim Nutzer erhoben werden</p>
				<p>Diese Anwendung bzw. der Anbieter k&ouml;nnten, im Rahmen der im Abschnitt &uuml;ber die Rechtsgrundlage der Verarbeitung genannten Grundlagen, personenbezogene Daten der Nutzer rechtm&auml;&szlig;ig ohne deren Wissen &uuml;ber Dritte beziehen oder wiederverwenden.<br/>Wenn der Anbieter personenbezogene Daten auf diese Weise erhoben hat, kann der Nutzer in den entsprechenden Abschnitten dieses Dokuments oder durch Kontaktaufnahme mit dem Anbieter spezifische Informationen &uuml;ber die Quelle in Erfahrung bringen.</p>
				<br/><p>Push-Benachrichtigungen f&uuml;r Direktmarketing</p>
				<p>Diese Anwendung kann dem Nutzer Push-Benachrichtigungen zum Zwecke des Direktmarketings zusenden (um Dienstleistungen und Produkte anzubieten, die von Dritten angeboten werden oder nicht mit dem Produkt oder der Dienstleistung von dieser Applikation (diese Anwendung) zusammenh&auml;ngen).</p>
				
				<p>In den meisten F&auml;llen k&ouml;nnen Nutzer den Empfang von Push-Benachrichtigungen ablehnen, indem sie ihre Ger&auml;teeinstellungen, wie beispielsweise die Mitteilungshinweise f&uuml;r Mobiltelefone, aufrufen und diese Einstellungen dann f&uuml;r diese Anwendung, f&uuml;r einige oder alle Anwendungen auf dem jeweiligen Ger&auml;t &auml;ndern.</p>
				
				<p>Nutzer m&uuml;ssen sich bewusst sein, dass das Deaktivieren von Push-Benachrichtigungen diese Anwendung und den Nutzen negativ beeinflussen kann.</p>
				
				<p>Neben den anwendbaren Ger&auml;teeinstellungen kann der Nutzer auch von den im entsprechenden Abschnitt dieser Datenschutzerkl&auml;rung &uuml;ber die Rechte der Nutzer beschriebenen Rechte Gebrauch machen.</p>
				<p>Rechte angemeldeter Nutzer unter 18 aus Kalifornien</p>
				<p>Das Kalifornische &bdquo;Online Eraser&ldquo; Gesetz aus dem Kalifornischen Business and Professions Code Sections 22580-22582, verpflichtet Betreiber gewisser Webseiten und Online Dienste, die f&uuml;r Minderj&auml;hrige bestimmt sind, angemeldeten und in Kalifornien wohnhaften Nutzern unter 18 die M&ouml;glichkeit einzur&auml;umen, die L&ouml;schung der von ihnen geposteten Inhalte zu verlangen. Sofern ein angemeldeter Nutzer die beschriebenen Voraussetzungen erf&uuml;llt und Inhalte &uuml;ber diese Anwendung ver&ouml;ffentlicht hat, kann der Nutzer die L&ouml;schung des Inhalts verlangen, indem er mit dem Anbieter oder dessen Datenschutz Zust&auml;ndigen &uuml;ber die in diesem Dokument genannten Kontaktdaten in Verbindung tritt.</p>
				
				<p>Daraufhin kann der Anbieter die vom Nutzer geposteten Inhalte f&uuml;r andere Nutzer und f&uuml;r die &Ouml;ffentlichkeit unzug&auml;nglich machen (anstatt diese vollst&auml;ndig zu L&ouml;schen). In diesem Fall k&ouml;nnten die Inhalte auf den Servern des Anbieters gespeichert bleiben, bzw. &uuml;ber andere Quellen weiterhin &ouml;ffentlich zug&auml;nglich sein, sofern Dritte den Inhalt vervielf&auml;ltigt und verbreitet haben sollten.</p>
				<p>Der Dienst ist f&uuml;r Vollj&auml;hrige bestimmt</p>
				<p>Nutzer erkl&auml;ren, vollj&auml;hrig nach dem jeweils anwendbaren Recht zu sein. Minderj&auml;hrige d&uuml;rfen diese Anwendung nicht nutzen.</p>
				<p>Dieser Dienst richtet sich nicht an Kinder unter 13 Jahren</p>
				<p>Der Nutzer erkl&auml;rt, gem&auml;&szlig; den f&uuml;r ihn geltenden Rechtsvorschriften vollj&auml;hrig zu sein. Minderj&auml;hrige d&uuml;rfen diese Anwendung nur unter der Aufsicht der Eltern oder einem Vormund nutzen. Personen unter 13 Jahren d&uuml;rfen diese Anwendung unter keinen Umst&auml;nden nutzen.</p>
				<br/><br/><p>Eindeutige Ger&auml;tekennzeichung</p>
				<p>Diese Anwendung kann Nutzer durch Speichern einer eindeutigen Kennzeichnung ihres Ger&auml;ts zu analytischen Zwecken oder zur Speicherung der Einstellungen des Nutzers nachverfolgen.</p>
				<p>Benutzeridentifikation per Universally Unique Identifier (UUID)</p>
				<p>Diese Anwendung erfasst Nutzer m&ouml;glicherweise durch die Speicherung eines so genannten Universally Unique Identifier (oder kurz UUID) f&uuml;r Analysezwecke oder zur Speicherung der Benutzereinstellungen. Dieser Identifier wird bei der Installation dieser Anwendung erstellt und bleibt zwischen Anwendungsstarts und Aktualisierungen bestehen, geht jedoch verloren, wenn der Benutzer die Anwendung l&ouml;scht. Eine Neuinstallation erstellt einen neuen UUID.</p>

				<br/><p>Die Rechte der Nutzer</p>

				<p>Die Nutzer k&ouml;nnen bestimmte Rechte in Bezug auf ihre vom Anbieter verarbeiteten Daten aus&uuml;ben.</p>
				<p>Nutzer haben insbesondere das Recht, Folgendes zu tun:</p>
				<p>Die Einwilligungen jederzeit widerrufen.</p>
				<p>&nbsp;Hat der Nutzer zuvor in die Verarbeitung personenbezogener Daten eingewilligt, so kann er die eigene Einwilligung jederzeit widerrufen.</p>
				<p>Widerspruch gegen die Verarbeitung ihrer Daten einlegen.</p>
				<p>&nbsp;Der Nutzer hat das Recht, der Verarbeitung seiner Daten zu widersprechen, wenn die Verarbeitung auf einer anderen Rechtsgrundlage als der Einwilligung erfolgt. Weitere Informationen hierzu sind weiter unten aufgef&uuml;hrt.</p>
				<p>Auskunft bez&uuml;glich ihrer Daten erhalten.</p>
				<p>&nbsp;Der Nutzer hat das Recht zu erfahren, ob die Daten vom Anbieter verarbeitet werden, &uuml;ber einzelne Aspekte der Verarbeitung Auskunft zu erhalten und eine Kopie der Daten zu erhalten.</p>
				<p>&Uuml;berpr&uuml;fen und berichtigen lassen.</p>
				<p>&nbsp;Der Nutzer hat das Recht, die Richtigkeit seiner Daten zu &uuml;berpr&uuml;fen und deren Aktualisierung oder Berichtigung zu verlangen.</p>
				<p>Einschr&auml;nkung der Verarbeitung ihrer Daten verlangen.</p>
				<p>&nbsp;Die Nutzer haben das Recht, unter bestimmten Umst&auml;nden die Verarbeitung ihrer Daten einzuschr&auml;nken. In diesem Fall wird der Anbieter die Daten zu keinem anderen Zweck als der Speicherung verarbeiten.</p>
				<p>L&ouml;schung oder anderweitiges Entfernen der personenbezogenen Daten verlangen.</p>
				<p>&nbsp;Die Nutzer haben unter bestimmten Umst&auml;nden das Recht, die L&ouml;schung ihrer Daten vom Anbieter zu verlangen.</p>
				<p>Ihre Daten erhalten und an einen anderen Verantwortlichen &uuml;bertragen lassen.</p>
				<p>&nbsp;Der Nutzer hat das Recht, seine Daten in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten und, sofern technisch m&ouml;glich, ungehindert an einen anderen Verantwortlichen &uuml;bermitteln zu lassen. Diese Bestimmung ist anwendbar, sofern die Daten automatisiert verarbeitet werden und die Verarbeitung auf der Zustimmung des Nutzers, auf einem Vertrag, an dem der Nutzer beteiligt ist, oder auf vorvertraglichen Verpflichtungen beruht.</p>
				<p>Beschwerde einreichen.</p>
				<p>&nbsp;Die Nutzer haben das Recht, eine Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde einzureichen.</p>

				<p>Details zum Widerspruchsrecht bez&uuml;glich der Verarbeitung</p>
				<p>Werden personenbezogene Daten im &ouml;ffentlichen Interesse, in Aus&uuml;bung eines dem Anbieter &uuml;bertragenen hoheitlichen Befugnisses oder zur Wahrung der berechtigten Interessen des Anbieters verarbeitet, kann der Nutzer dieser Verarbeitung widersprechen, indem er einen Rechtfertigungsgrund angibt, der sich auf seine besondere Situation bezieht.</p>
				<p>Nutzer werden dar&uuml;ber informiert, dass sie der Verarbeitung der personenbezogenen Daten f&uuml;r Direktwerbung jederzeit ohne Angabe von Gr&uuml;nden widersprechen k&ouml;nnen. Ob der Anbieter personenbezogene Daten f&uuml;r Direktwerbungszwecke verarbeitet, k&ouml;nnen die Nutzer den entsprechenden Abschnitten dieses Dokuments entnehmen.</p>

				<p>Wie die Rechte ausge&uuml;bt werden k&ouml;nnen</p>
				<p>Alle Anfragen zur Aus&uuml;bung der Nutzerrechte k&ouml;nnen &uuml;ber die in diesem Dokument angegebenen Kontaktdaten an den Anbieter gerichtet werden. Antr&auml;ge k&ouml;nnen kostenlos ausge&uuml;bt werden und werden vom Anbieter so fr&uuml;h wie m&ouml;glich, sp&auml;testens innerhalb eines Monats, bearbeitet.</p>

				<br/><p>Weitere Informationen &uuml;ber die Erhebung und Verarbeitung von Daten</p>


				<p>Rechtliche Ma&szlig;nahmen</p>
				<p>Die personenbezogenen Daten des Nutzers k&ouml;nnen vom Anbieter zu Zwecken der Rechtsdurchsetzung innerhalb oder in Vorbereitung gerichtlicher Verfahren verarbeitet werden, die sich daraus ergeben, dass diese Anwendung oder die dazugeh&ouml;rigen Dienste nicht ordnungsgem&auml;&szlig; genutzt wurden.<br/>Der Nutzer erkl&auml;rt, sich dessen bewusst zu sein, dass der Anbieter von den Beh&ouml;rden zur Herausgabe von personenbezogenen Daten verpflichtet werden k&ouml;nnte.</p>

				<p>Weitere Informationen &uuml;ber die personenbezogenen Daten des Nutzers</p>

				<p>Zus&auml;tzlich zu den in dieser Datenschutzerkl&auml;rung aufgef&uuml;hrten Informationen kann diese Anwendung dem Nutzer auf Anfrage weitere kontextbezogene Informationen zur Verf&uuml;gung stellen, die sich auf bestimmte Dienste oder auf die Erhebung und Verarbeitung personenbezogener Daten beziehen.</p>

				<p>Systemprotokolle und Wartung</p>
				<p>Diese Anwendung und die Dienste von Drittanbietern k&ouml;nnen zu Betriebs- und Wartungszwecken Dateien sammeln, die die &uuml;ber diese Anwendung stattfindende Interaktion aufzeichnen (Systemprotokolle), oder andere personenbezogene Daten (z. B. IP-Adresse) zu diesem Zweck verwenden.</p>

				<p>Nicht in dieser Datenschutzerkl&auml;rung enthaltene Informationen</p>
				<p>Weitere Informationen &uuml;ber die Erhebung oder Verarbeitung personenbezogener Daten k&ouml;nnen jederzeit vom Anbieter &uuml;ber die aufgef&uuml;hrten Kontaktangaben angefordert werden.</p>

				<p>Wie &bdquo;Do Not Track&ldquo; Anfragen behandelt werden</p>
				<p>Diese Anwendung unterst&uuml;tzt keine Nicht-Verfolgen-Anfragen (&bdquo;Do Not Track&rdquo;) durch Webbrowser.<br/>Die Information, ob integrierte Drittdienste das Nicht-Verfolgen Protokoll unterst&uuml;tzen, entnehmen Nutzer der Datenschutzerkl&auml;rung des jeweiligen Dienstes.</p>

				<p>&Auml;nderungen dieser Datenschutzerkl&auml;rung</p>
				<p>Der Anbieter beh&auml;lt sich vor, jederzeit &Auml;nderungen an dieser Datenschutzerkl&auml;rung vorzunehmen, indem Nutzer auf dieser Seite und gegebenenfalls &uuml;ber diese Anwendung und/oder - soweit technisch und rechtlich m&ouml;glich &ndash; durch das Versenden einer Mitteilung &uuml;ber dem Anbieter vorliegende Kontaktdaten der Nutzer informiert werden. Nutzern wird daher nahe gelegt, diese Seite regelm&auml;&szlig;ig aufzurufen und insbesondere das am Seitenende angegebene Datum der letzten &Auml;nderung zu pr&uuml;fen.<br/><br/>Soweit &Auml;nderungen eine auf der Einwilligung des Nutzers basierte Datennutzung betreffen, so wird der Anbieter - soweit erforderlich - eine neue Einwilligung einholen.</p>

				<br/><p>Begriffsbestimmungen und rechtliche Hinweise</p>

				<br/><p>Personenbezogene Daten (oder Daten)</p>
				<p>Alle Informationen, durch die direkt oder in Verbindung mit weiteren Informationen die Identit&auml;t einer nat&uuml;rlichen Person bestimmt wird oder werden kann.</p>

				<br/><p>Nutzungsdaten</p>
				<p>Informationen, die diese Anwendung (oder Dienste Dritter, die diese Anwendung in Anspruch nimmt), automatisch erhebt, z. B.: die IP-Adressen oder Domain-Namen der Computer von Nutzern, die diese Anwendung verwenden, die URI-Adressen (Uniform Resource Identifier), den Zeitpunkt der Anfrage, die Methode, die f&uuml;r die &Uuml;bersendung der Anfrage an den Server verwendet wurde, die Gr&ouml;&szlig;e der empfangenen Antwort-Datei, der Zahlencode, der den Status der Server-Antwort anzeigt (erfolgreiches Ergebnis, Fehler etc.), das Herkunftsland, die Funktionen des vom Nutzer verwendeten Browsers und Betriebssystems, die diversen Zeitangaben pro Aufruf (z. B. wie viel Zeit auf jeder Seite der Anwendung verbracht wurde) und Angaben &uuml;ber den Pfad, dem innerhalb einer Anwendung gefolgt wurde, insbesondere die Reihenfolge der besuchten Seiten, sowie sonstige Informationen &uuml;ber das Betriebssystem des Ger&auml;ts und/oder die IT-Umgebung des Nutzers.</p>

				<br/><p>Nutzer</p>
				<p>Die diese Anwendung verwendende Person, die, soweit nicht anders bestimmt, mit dem Betroffenen &uuml;bereinstimmt.</p>

				<br/><p>Betroffener</p>
				<p>Die nat&uuml;rliche Person, auf die sich die personenbezogenen Daten beziehen.</p>

				<br/><p>Auftragsverarbeiter (oder Datenverarbeiter)</p>
				<p>Nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet, wie in dieser Datenschutzerkl&auml;rung beschrieben.</p>

				<br/><p>Verantwortlicher (oder Anbieter, teilweise auch Eigent&uuml;mer)</p>
				<p>Die nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung personenbezogener Daten sowie die hierf&uuml;r verwendeten Mittel entscheidet, einschlie&szlig;lich der Sicherheitsma&szlig;nahmen bez&uuml;glich des sich auf diese Anwendung beziehenden Betriebs und der Nutzung. Soweit nichts anderes angegeben ist, ist der Verantwortliche die nat&uuml;rliche oder juristische Person, &uuml;ber welche diese Anwendung angeboten wird.</p>

				<br/><p>Diese Anwendung</p>
				<p>Das Hardware- oder Software-Tool, mit dem die personenbezogenen Daten des Nutzers erhoben und verarbeitet werden.</p>

				<br/><p>Dienst</p>
				<p>Der durch diese Anwendung angebotene Dienst, wie in den entsprechenden Nutzungsbedingungen (falls vorhanden) und auf dieser Seite/Anwendung beschrieben.</p>

				<br/><p>Europ&auml;ische Union (oder EU)</p>
				<p>Sofern nicht anders angegeben, beziehen sich alle Verweise in diesem Dokument auf die Europ&auml;ische Union auf alle derzeitigen Mitgliedstaaten der Europ&auml;ischen Union und den Europ&auml;ischen Wirtschaftsraum (EWR).</p>

				<br/><p>Cookie</p>
				<p>Cookies sind Tracker, die aus einem kleinen, im Browser des Nutzers abgelegten Datensatz bestehen.</p>

				<br/><p>Tracker</p>
				<p>Der Begriff Tracker bezeichnet jede Technologie &ndash; z. B. Cookies, eindeutige Identifizierungen, Web Beacons, eingebettete Skripts, E-Tags oder Fingerprinting &ndash; durch die Nutzer nachverfolgt werden k&ouml;nnen, z. B. indem der Zugriff auf oder die Speicherung von Informationen auf dem Nutzerger&auml;t erm&ouml;glicht wird.</p>

				<br/><p>Voller Zugriff</p>
				<p>Vollst&auml;ndiger Zugriff auf das Nutzerkonto, einschlie&szlig;lich des permanenten L&ouml;schens von Threads und Nachrichten.</p>

				<br/><p>Nur Lesezugriff</p>
				<p>Lesen Sie alle Ressourcen und deren Metadaten - keine Schreiboperationen.</p>

				<br/><p>Metadaten lesen</p>
				<p>Zugriff auf Ressourcen-Metadaten wie Labels, Verlaufseintr&auml;ge und E-Mail-Nachrichtenk&ouml;pfe, aber nicht den Nachrichtentext oder Anh&auml;nge.</p>

				<br/><p>Modifizieren</p>
				<p>Alle Lese-/Schreiboperationen mit Ausnahme des sofortigen, permanenten L&ouml;schens von Threads und Nachrichten, wobei der Papierkorb umgangen wird.</p>

				<br/><p>Einf&uuml;gen und Importieren</p>
				<p>Nur Nachrichten einf&uuml;gen und importieren.</p>

				<br/><p>Verfassen</p>
				<p>Erstellen, Lesen, Aktualisieren und L&ouml;schen von Entw&uuml;rfen. Senden von Nachrichten und Entw&uuml;rfen.</p>

				<br/><p>Verwaltung der Grundeinstellungen</p>
				<p>Verwaltung der grundlegenden E-Mail-Einstellungen.</p>

				<br/><p>Verwaltung von sensiblen Einstellungen</p>
				<p>Verwaltung von sensiblen E-Mail-Einstellungen, einschlie&szlig;lich Weiterleitungsregeln und Aliase.</p>
				

				<br/><p>Rechtlicher Hinweis</p>
				<p>Diese Datenschutzerkl&auml;rung wurde auf der Grundlage von Bestimmungen verschiedener Gesetzgebungen verfasst, einschlie&szlig;lich Art. 13/14 der Verordnung (EU) 2016/679 (Datenschutz-Grundverordnung).</p>
				<p>Diese Datenschutzerkl&auml;rung bezieht sich ausschlie&szlig;lich auf diese Anwendung, sofern in diesem Dokument nicht anders angegeben.</p>
				<br/><p>Letzte Aktualisierung: 31 Januar 2021</p>
        	</div>
		</div>
    );
}

export default PrivacyPolicyDe;