
import React from "react";

function PrivacyPolicyEn() {
    return(
        <div className="privacypolicysection">
   <div className="container">
      <h1>Privacy Policy</h1>
      <p>This Application collects some Personal Data from its Users.</p>
      <p>This document can be printed for reference by using the print command in the settings of any browser.</p>
      <br/><p>Policy summary</p>
      <p>Personal Data collected for the following purposes and using the following services:</p>
      <br/><p>Advertising</p>
      <p>Google Ad Manager, Google AdSense, Google Ads Similar audiences and Facebook Lookalike Audience</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <p>AdMob and Facebook Audience Network</p>
      <p>Personal Data: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data</p>
      <br/><p>Direct Advertising</p>
      <p>Personal Data: device information; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Universally unique identifier (UUID); Usage Data</p>
      <p>Direct Email Marketing (DEM)</p>
      <p>Personal Data: email address; first name; last name</p>
      <p>Pinterest Ads</p>
      <p>Personal Data: device information; email address; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data; User ID</p>
      <br/><p>Analytics</p>
      <p>Google Analytics, Google Analytics with anonymized IP and Facebook Ads conversion tracking (Facebook pixel)</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <p>Google Analytics Advertising Reporting Features</p>
      <p>Personal Data: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); various types of Data as specified in the privacy policy of the service</p>
      <p>Google Analytics Demographics and Interests reports</p>
      <p>Personal Data: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example)</p>
      <p>Google Analytics for Firebase</p>
      <p>Personal Data: Application opens; Application updates; device information; first launches; geography/region; In-app purchases; number of sessions; number of Users ; operating systems; session duration; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data</p>
      <p>Facebook Analytics for Apps</p>
      <p>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</p>
      <br/><p>Beta Testing</p>
      <p>App Center</p>
      <p>Personal Data: Data communicated while using the service; email address; various types of Data as specified in the privacy policy of the service</p>
      <p>Beta by Crashlytics</p>
      <p>Personal Data: app information; country; Data communicated while using the service; date of birth; device information; device logs; email address; first name; gender; last name; Tracker; Usage Data; username; various types of Data as specified in the privacy policy of the service</p>
      <p>Google Play Beta Testing</p>
      <p>Personal Data: country; Data communicated while using the service; date of birth; device information; device logs; email address; first name; gender; last name; Tracker; Usage Data; username; various types of Data as specified in the privacy policy of the service</p>
      <p>TestFlight</p>
      <p>Personal Data: app information; country; Data communicated while using the service; device information; device logs; email address; first name; gender; last name; Tracker; Usage Data; username; various types of Data as specified in the privacy policy of the service</p>
      <p>Commercial affiliation</p>
      <p>Amazon Affiliation</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <br/><p>Contacting the User</p>
      <p>Contact form</p>
      <p>Personal Data: email address; first name; last name</p>
      <p>Mailing list or newsletter</p>
      <p>Personal Data: country; date of birth; email address; first name; gender; last name</p>
      <br/><p>Content performance and features testing (A/B testing)</p>
      <p>Firebase Remote Config</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <p>Google Optimize and Google Optimize 360</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <br/><p>Data transfer outside the EU</p>
      <p>Data transfer abroad based on consent, Data transfer abroad based on standard contractual clauses, Data transfer to countries that guarantee European standards and Other legal basis for Data transfer abroad</p>
      <p>Personal Data: various types of Data</p>
      <br/><p>Device permissions for Personal Data access</p>
      <p>Personal Data: Camera permission; Camera permission, without saving or recording; Health Data read permission; Health Data update permission ; Photo Library permission; Reminders permission; Storage permission</p>
      <p>Handling activity data</p>
      <p>Apple HealthKit</p>
      <p>Personal Data: blood type; body measurements &amp; indexes; date of birth; food related activity ; gender; general activity data; movement activity; sleeping activity</p>
      <p>Activity data tracked by your device</p>
      <p>Personal Data: body measurements &amp; indexes; date of birth; food related activity ; gender; general activity data</p>
      <br/><p>Handling payments</p>
      <p>Apple Pay and PayPal</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <br/><p>Hosting and backend infrastructure</p>
      <p>Firebase Cloud Functions, Firebase Cloud Firestore, Firebase Cloud Storage and Firebase Realtime Database</p>
      <p>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</p>
      <p>Firebase Hosting</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <br/><p>Infrastructure monitoring</p>
      <p>Crashlytics with User opt-in and Crashlytics</p>
      <p>Personal Data: Crash information; device information; Universally unique identifier (UUID)</p>
      <br/><p>Firebase Performance Monitoring</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <br/><p>Interaction with external social networks and platforms</p>
      <p>Twitter Tweet button and social widgets, Facebook Like button and social widgets and Pinterest &ldquo;Pin it&rdquo; button and social widgets</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <br/><p>Location-based interactions</p>
      <p>Geolocation and Non-continuous geolocation</p>
      <p>Personal Data: geographic position</p>
      <p>Managing contacts and sending messages</p>
      <p>Firebase Cloud Messaging and Firebase Notifications</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <p>Managing data collection and online surveys</p>
      <br/><p>Facebook lead ads</p>
      <p>Personal Data: address; city; country; Data communicated while using the service; date of birth; email address; first name; gender; last name; phone number; state; Tracker; Usage Data</p>
      <p>Platform services and hosting</p>
      <br/><p>Apple App Store and Google Play Store</p>
      <p>Personal Data: Usage Data</p>
      <br/><p>Registration and authentication</p>
      <p>Firebase Authentication</p>
      <p>Personal Data: email address; first name; last name; password; phone number; profile picture; social media accounts ; username</p>
      <p>Facebook Authentication, Google OAuth and Instagram Authentication</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <p>Gmail permissions to access User Data (OAuth addition)</p>
      <p>Personal Data: Basic settings management; Compose; Full access; Insert and import; Modify; Read metadata; Read only access; Sensitive settings management</p>
      <p>Sign in with Apple</p>
      <p>Personal Data: email address; first name; last name; phone number; User ID</p>
      <p>Registration and authentication provided directly by this Application</p>
      <br/><p>Direct registration</p>
      <p>Personal Data: address; city; country; date of birth; email address; first name; gender; language; last name; password; profile picture; Usage Data; username</p>
      <p>Remarketing and behavioral targeting</p>
      <p>AdRoll</p>
      <p>Personal Data: device information; shopping history; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data; various types of Data</p>
      <p>Facebook Remarketing, Google Ads Remarketing, Google Ad Manager Audience Extension and Google Signals</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <p>Facebook Custom Audience</p>
      <p>Personal Data: email address; Tracker</p>
      <p>RSS feed management</p>
      <p>Feedburner</p>
      <p>Personal Data: Tracker; Usage Data</p>
      <br/><p>Social features</p>
      <p>Firebase Dynamic Links and Firebase Invites</p>
      <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
      <p>Inviting and suggesting friends</p>
      <p>Personal Data: various types of Data</p>
      <p>Public profile</p>
      <p>Personal Data: first name; gender; last name; picture; username</p>
      <br/><p>Further information about Personal Data</p>
      <p>Analysis and predictions based on the User&rsquo;s Data (&ldquo;profiling&rdquo;)</p>
      <p>The Owner may use the Personal and Usage Data collected through this Application to create or update User profiles. This type of Data processing allows the Owner to evaluate User choices, preferences and behaviour for the purposes outlined in the respective section of this document.</p>
      <p>User profiles can also be created through the use of automated tools like algorithms, which can also be provided by third parties. To find out more about the profiling activities performed, Users can check the relevant sections of this document.</p>
      <p>The User always has a right to object to this kind of profiling activity. To find out more about the User&rsquo;s rights and how to exercise them, the User is invited to consult the section of this document outlining the rights of the User.</p>
      <br/><p>Automated decision-making</p>
      <p>Automated decision-making means that a decision which is likely to have legal effects or similarly significant effects on the User, is taken solely by technological means, without any human intervention. This Application may use the User&rsquo;s Personal Data to make decisions entirely or partially based on automated processes according to the purposes outlined in this document. This Application adopts automated decision-making processes as far as necessary to enter into or perform a contract between User and Owner, or on the basis of the User&rsquo;s explicit consent, where such consent is required by the law.</p>
      <p>Automated decisions are made by technological means &ndash; mostly based on algorithms subject to predefined criteria &ndash; which may also be provided by third parties.</p>
      <p>The rationale behind the automated decision making is:</p>
      <p>to enable or otherwise improve the decision-making process;</p>
      <p>to grant Users fair and unbiased treatment based on consistent and uniform criteria;</p>
      <p>to reduce the potential harm derived from human error, personal bias and the like which may potentially lead to discrimination or imbalance in the treatment of individuals etc.;</p>
      <p>to reduce the risk of User&rsquo;s failure to meet their obligation under a contract. To find out more about the purposes, the third-party services, if any, and any specific rationale for automated decisions used within this Application, Users can check the relevant sections in this document.</p>
      <p>Consequences of automated decision-making processes for Users and rights of Users subjected to it</p>
      <p>As a consequence, Users subject to such processing, are entitled to exercise specific rights aimed at preventing or otherwise limiting the potential effects of the automated decisions taken.</p>
      <p>In particular, Users have the right to:</p>
      <p>obtain an explanation about any decision taken as a result of automated decision-making and express their point of view regarding this decision;</p>
      <p>challenge a decision by asking the Owner to reconsider it or take a new decision on a different basis;</p>
      <p>request and obtain from the Owner human intervention on such processing.</p>
      <p>To learn more about the User&rsquo;s rights and the means to exercise them, the User is invited to consult the section of this document relating to the rights of the User.</p>
      <p>CCPA: Collection of personal information about consumers aged 13 to 16</p>
      <p>We collect personal information of consumers between the age of 13 and 16 and won&rsquo;t sell their data unless those consumers have opted-in.</p>
      <p>CCPA: Collection of personal information about consumers below the age of 13</p>
      <p>We collect personal information of consumers below the age of 13 and won&rsquo;t sell their data unless their parents or guardians have opted-in on behalf of those minors.</p>
      <p>CCPA: Collection of personal information about minors</p>
      <p>We do not knowingly collect personal information of consumers who are below the age of 16.</p>
      <br/><p>Push notifications</p>
      <p>This Application may send push notifications to the User to achieve the purposes outlined in this privacy policy.</p>
      <p>Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then change those settings for this Application, some or all of the apps on the particular device.</p>
      <p>Users must be aware that disabling push notifications may negatively affect the utility of this Application.</p>
      <p>Push notifications based on the User&rsquo;s geographic location</p>
      <p>This Application may use the User&rsquo;s geographic location to send push notifications for the purposes outlined in this privacy policy.</p>
      <p>Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then changing those settings for some or all of the apps on the particular device.</p>
      <p>Users must be aware that disabling push notifications may negatively affect the utility of this Application.</p>
      <br/><p>localStorage</p>
      <p>localStorage allows this Application to store and access data right in the User&rsquo;s browser with no expiration date.</p>
      <p>Personal Data collected through sources other than the User</p>
      <p>The Owner of this Application may have legitimately collected Personal Data relating to Users without their knowledge by reusing or sourcing them from third parties on the grounds mentioned in the section specifying the legal basis of processing.</p>
      <p>Where the Owner has collected Personal Data in such a manner, Users may find specific information regarding the source within the relevant sections of this document or by contacting the Owner.</p>
      <p>Push notifications for direct marketing</p>
      <p>This Application may send push notifications to the User for the purpose of direct marketing (to propose services and products provided by third parties or unrelated to the product or service provided by this Application).</p>
      <p>Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then changing those settings for this Application or all of the apps on the particular device.</p>
      <p>Users must be aware that disabling push notifications may negatively affect the utility of this Application.</p>
      <p>Besides applicable device settings, the User may also make use of the rights described under User rights in the relevant section of this privacy policy.</p>
      <p>Rights for registered California Users under the age of 18</p>
      <p>California&rsquo;s &ldquo;Online Eraser&rdquo; law, part of California&rsquo;s Business and Professions Code Sections 22580-22582, requires operators of certain websites and online services targeting minors to allow registered Users who are under the age of 18 and residents of California to request removal of content they post.</p>
      <p>If a registered User fits that description and posted content on this Application, they may request removal of such content by contacting the Owner or its privacy policy coordinator at the contact details provided in this document.</p>
      <p>In response to this request, the Owner may make content posted by the registered User invisible to other registered Users and the public (rather than deleting it entirely), in which case the content may remain on the Owner&rsquo;s servers. It may also be publicly available elsewhere if a third party copied and reposted this content.</p>
      <p>The Service is intended for adults</p>
      <p>Users declare themselves to be adult according to their applicable legislation. Minors may not use this Application.</p>
      <p>The Service is not directed to children under the age of 13</p>
      <p>Users declare themselves to be adult according to their applicable legislation. Minors may use this Application only with the assistance of a parent or guardian. Under no circumstance persons under the age of 13 may use this Application.</p>
      <p>Transfer of personal information outside of Brazil based on your consent</p>
      <p>We can transfer your personal information outside of the Brazilian territory if you consent to such transfer.</p>
      <p>When we ask for your consent, we&rsquo;ll make sure to provide all the information that you need to make an educated decision and to understand the implications and consequences of providing or denying your consent.</p>
      <p>Such information will be given in clear and plain language and in such a way that you&rsquo;ll be able to clearly distinguish these requests from other consent requests that we may possibly ask.</p>
      <p>You may withdraw your consent at any time.</p>
      <p>Transfer of personal information outside of Brazil to countries that guarantee the same protection standards as LGPD</p>
      <p>We can transfer your personal information outside of the Brazilian territory, if the destination country, or the international organization which receives the personal information, provides an adequate level of protection of the personal information according to the ANPD.</p>
      <p>The ANPD authorizes such transfers whenever it considers that country to possess and provide personal information protection standards comparable to those set forth by the LGPD, having taken into account the following:</p>
      <p>the general and sectoral rules of the legislation in force in the country of destination or in the international organization;</p>
      <p>the nature of the personal information subject to the transfer;</p>
      <p>the compliance with the general principles on the protection of the personal information and on the rights of the individuals as set forth in the LGPD;</p>
      <p>the adoption of suitable security measures;</p>
      <p>the existence of judicial and institutional guarantees for the respect of personal information protection rights; and</p>
      <p>any other pertinent circumstance related to the relevant transfer.</p>
      <p>Transfer of personal information outside of Brazil based on contracts and other legal means</p>
      <p>We can transfer your personal information outside of the Brazilian territory provided that we are able to ensure that any further processing of your personal information will be in compliance with the principles and the rules established by the LGPD, and your rights are safeguarded.</p>
      <p>To do so, we may use one of the following legal means:</p>
      <p>specific contractual clauses for each given transfer. This means that we will enter into an agreement with the recipient of your personal information to make sure that such transfers meet the requirements explained above. Such an agreement shall be subject to the ANPD&rsquo;s prior verification;</p>
      <p>standard contractual clauses. These clauses set terms and conditions for the transfer of personal information and are adopted by the ANPD;</p>
      <p>global corporate clauses. These clauses set terms and conditions for the transfer of personal information within an organisation and, before they come into force, are subject to the ANPD&rsquo;s prior verification;</p>
      <p>seals of approval, certificates and codes of conduct regularly issued by the ANPD. These legal instruments allow us to transfer your personal information provided that we abide by their rules. They are subject to the previous approval of the ANPD.</p>
      <br/><p>Unique device identification</p>
      <p>This Application may track Users by storing a unique identifier of their device, for analytics purposes or for storing Users&rsquo; preferences.</p>
      <p>User identification via a universally unique identifier (UUID)</p>
      <p>This Application may track Users by storing a so-called universally unique identifier (or short UUID) for analytics purposes or for storing Users&rsquo; preferences. This identifier is generated upon installation of this Application, it persists between Application launches and updates, but it is lost when the User deletes the Application. A reinstall generates a new UUID.</p>
      <br/><p>Contact information</p>
      <p>Owner and Data Controller</p>
      <p>Max Rosenblattl</p>
      <p>Fischergasse 35</p>
      <p>8010 Graz</p>
      <p>Owner contact email: support@simpleveganapp.com</p>
      <p>&nbsp;</p>
      <br/><p>Full policy</p>
      <br/><p>Owner and Data Controller</p>
      <p>Max Rosenblattl</p>
      <p>Fischergasse 35</p>
      <p>8010 Graz</p>
      <p>Owner contact email: support@simpleveganapp.com</p>
      <br/><p>Types of Data collected</p>
      <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Tracker; Usage Data; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Universally unique identifier (UUID); device information; email address; first name; last name; User ID; geography/region; number of Users ; number of sessions; session duration; In-app purchases; Application opens; Application updates; first launches; operating systems; Data communicated while using the service; app information; country; date of birth; device logs; gender; username; various types of Data; Camera permission; Storage permission; Reminders permission; Photo Library permission; Health Data read permission; Health Data update permission ; Camera permission, without saving or recording; general activity data; movement activity; body measurements &amp; indexes; food related activity ; sleeping activity; blood type; Crash information; geographic position; address; city; phone number; state; password; profile picture; social media accounts ; shopping history; language; Full access; Read only access; Read metadata; Modify; Insert and import; Compose; Basic settings management; Sensitive settings management; picture.</p>
      <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.</p>
      <p>Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.</p>
      <p>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.</p>
      <p>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.</p>
      <p>Any use of Cookies &ndash; or of other tracking tools &ndash; by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
      <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party&rsquo;s consent to provide the Data to the Owner.</p>
      <p>Mode and place of processing the Data</p>
      <br/><p>Methods of processing</p>
      <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.</p>
      <p>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
      <br/><p>Legal basis of processing</p>
      <p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
      <p>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (&ldquo;opt-out&rdquo;), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</p>
      <p>provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</p>
      <p>processing is necessary for compliance with a legal obligation to which the Owner is subject;</p>
      <p>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</p>
      <p>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</p>
      <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
      <br/><p>Place</p>
      <p>The Data is processed at the Owner&rsquo;s operating offices and in any other places where the parties involved in the processing are located.</p>
      <p>Depending on the User&rsquo;s location, data transfers may involve transferring the User&rsquo;s Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p>
      <p>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.</p>
      <p>If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</p>
      <br/><p>Retention time</p>
      <p>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
      <p>Therefore:</p>
      <p>Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</p>
      <p>Personal Data collected for the purposes of the Owner&rsquo;s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</p>
      <p>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.</p>
      <p>Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</p>
      <br/><p>The purposes of processing</p>
      <p>The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Advertising, Analytics, Beta Testing, Commercial affiliation, Contacting the User, Content performance and features testing (A/B testing), Data transfer outside the EU, Device permissions for Personal Data access, Handling activity data, Handling payments, Hosting and backend infrastructure, Infrastructure monitoring, Interaction with external social networks and platforms, Location-based interactions, Managing contacts and sending messages, Managing data collection and online surveys, Platform services and hosting, Registration and authentication, Remarketing and behavioral targeting, Registration and authentication provided directly by this Application, RSS feed management and Social features.</p>
      <p>For specific information about the Personal Data used for each purpose, the User may refer to the section &ldquo;Detailed information on the processing of Personal Data&rdquo;.</p>
      <p>Device permissions for Personal Data access</p>
      <p>Depending on the User&rsquo;s specific device, this Application may request certain permissions that allow it to access the User&rsquo;s device Data as described below.</p>
      <p>By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document.</p>
      <p>The exact procedure for controlling app permissions may be dependent on the User&rsquo;s device and software.</p>
      <p>Please note that the revoking of such permissions might impact the proper functioning of this Application.</p>
      <p>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by this Application.</p>
      <p>Camera permission</p>
      <p>Used for accessing the camera or capturing images and video from the device.</p>
      <p>Camera permission, without saving or recording</p>
      <p>Used for accessing the camera or capturing images and video from the device.</p>
      <p>This Application does not save or record the camera output.</p>
      <p>Health Data read permission</p>
      <p>Allows reading the User&rsquo;s health Data.</p>
      <p>Health Data update permission</p>
      <p>Allows the updating of the User&rsquo;s health Data.</p>
       <br/><p>Photo Library permission</p>
      <p>Allows access to the User&rsquo;s Photo Library.</p>
      <p>Reminders permission</p>
      <p>Used for accessing the Reminders app on the User&rsquo;s device, including the reading, adding and removing of entries.</p>
      <p>Storage permission</p>
      <p>Used for accessing shared external storage, including the reading and adding of any items.</p>
      <p>Detailed information on the processing of Personal Data</p>
      <p>Personal Data is collected for the following purposes and using the following services:</p>
      <br/><p>Advertising</p>
      <p>This type of service allows User Data to be utilized for advertising communication purposes. These communications are displayed in the form of banners and other advertisements on this Application, possibly based on User interests.</p>
      <p>This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.</p>
      <p>Some of the services listed below may use Trackers to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User&rsquo;s interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services.</p>
      <p>In addition to any opt-out feature offered by any of the services below, Users may opt out by visiting the Network Advertising Initiative opt-out page.</p>
      <p>Users may also opt-out of certain advertising features through applicable device settings, such as the device advertising settings for mobile phones or ads settings in general.</p>
      <p>Google Ad Manager (Google LLC)</p>
      <p>Google Ad Manager is an advertising service provided by Google LLC that allows the Owner to run advertising campaigns in conjunction with external advertising networks that the Owner, unless otherwise specified in this document, has no direct relationship with. In order to opt out from being tracked by various advertising networks, Users may make use of Youronlinechoices. In order to understand Google&rsquo;s use of data, consult Google&rsquo;s partner policy.</p>
      <p>This service uses the &ldquo;DoubleClick&rdquo; Cookie, which tracks use of this Application and User behavior concerning ads, products and services offered. &nbsp; Users may decide to disable all the DoubleClick Cookies by going to: Google Ad Settings.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>AdMob (Google LLC)</p>
      <p>AdMob is an advertising service provided by Google LLC.</p>
      <p>In order to understand Google&rsquo;s use of Data, consult Google&rsquo;s partner policy.</p>
      <p>Personal Data processed: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Direct Advertising (this Application)</p>
      <p>This Application uses User Data to propose services or products provided by third parties, related or unrelated to the product or service provided by this Application. Direct Advertising may involve the use of Trackers to monitor Users&rsquo; behavior and serve personalized ads. Users may opt-out of ad personalization by visiting their device settings or following the instructions provided by YourOnlineChoices and AdChoices. Besides applicable device settings, the User may also make use of the rights described under User rights in the relevant section of this privacy policy.</p>
      <p>Personal Data processed: device information; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Universally unique identifier (UUID); Usage Data.</p>
      <p>Google AdSense (Google LLC)</p>
      <p>Google AdSense is an advertising service provided by Google LLC. This service uses the &ldquo;DoubleClick&rdquo; Cookie, which tracks use of this Application and User behavior concerning ads, products and services offered.</p>
      <p>Users may decide to disable all the DoubleClick Cookies by going to: Google Ad Settings.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Direct Email Marketing (DEM) (this Application)</p>
      <p>This Application uses the User Data to propose services and products provided by third parties or unrelated to the product or service provided by this Application.</p>
      <p>Personal Data processed: email address; first name; last name.</p>
      <p>Google Ads Similar audiences (Google LLC)</p>
      <p>Similar audiences is an advertising and behavioral targeting service provided by Google LLC that uses Data from Google Ads Remarketing in order to display ads to Users with similar behavior to Users who are already on the remarketing list due to their past use of this Application.</p>
      <p>On the basis of this Data, personalized ads will be shown to Users suggested by Google Ads Similar audiences. Users who don&rsquo;t want to be included in Similar audiences can opt out and disable the use of advertising cookies by going to: Google Ad Settings.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Pinterest Ads (Pinterest, Inc.)</p>
      <p>Pinterest Ads is an advertising service provided by Pinterest, Inc. that allows the Owner to run advertising campaigns on the Pinterest advertising network. Users may opt out of behavioral advertising features through their device settings, their Pinterest personalization settings or by visiting the AdChoices opt-out page.</p>
      <p>Personal Data processed: device information; email address; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data; User ID.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt out.</p>
      <p>Facebook Lookalike Audience</p>
      <p>Facebook Lookalike Audience is an advertising and behavioral targeting service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, that uses Data collected through Facebook Custom Audience in order to display ads to Users with similar behavior to Users who are already in a Custom Audience list on the base of their past use of this Application or engagement with relevant content across the Facebook apps and services.</p>
      <p>On the base of these Data, personalized ads will be shown to Users suggested by Facebook Lookalike Audience. Users can opt out of Facebook&rsquo;s use of cookies for ads personalization by visiting this opt-out page.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Facebook Audience Network</p>
      <p>Facebook Audience Network is an advertising service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, In order to understand Facebook&rsquo;s use of Data, consult Facebook&rsquo;s data policy.</p>
      <p>This Application may use identifiers for mobile devices (including Android Advertising ID or Advertising Identifier for iOS, respectively) and technologies similar to cookies to run the Facebook Audience Network service. One of the ways Audience Network shows ads is by using the User&rsquo;s ad preferences. The User can control this in the Facebook ad settings. Users may opt-out of certain Audience Network targeting through applicable device settings, such as the device advertising settings for mobile phones or by following the instructions in other Audience Network related sections of this privacy policy, if available.</p>
      <p>Personal Data processed: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <br/><p>Analytics</p>
      <p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
      <p>Google Analytics (Google LLC)</p>
      <p>Google Analytics is a web analysis service provided by Google LLC (&ldquo;Google&rdquo;). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.</p>
      <p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Google Analytics Advertising Reporting Features (Google LLC)</p>
      <p>Google Analytics on this Application has Advertising Reporting Features activated, which collects additional information from the DoubleClick cookie (web activity) and from device advertising IDs (app activity). It allows the Owner to analyze specific behavior and interests Data (traffic Data and Users&rsquo; ads interaction Data) and, if enabled, demographic Data (information about the age and gender). Users can opt out of Google&rsquo;s use of cookies by visiting Google&rsquo;s Ads Settings.</p>
      <p>Personal Data processed: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Google Analytics Demographics and Interests reports (Google LLC)</p>
      <p>Google Analytics Demographics and Interests reports is a Google Advertising Reporting feature that makes available demographic and interests Data inside Google Analytics for this Application (demographics means age and gender Data). Users can opt out of Google&rsquo;s use of cookies by visiting Google&rsquo;s Ads Settings.</p>
      <p>Personal Data processed: Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example).</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Google Analytics for Firebase (Google LLC)</p>
      <p>Google Analytics for Firebase or Firebase Analytics is an analytics service provided by Google LLC.</p>
      <p>In order to understand Google&rsquo;s use of Data, consult Google&rsquo;s partner policy. Firebase Analytics may share Data with other tools provided by Firebase, such as Crash Reporting, Authentication, Remote Config or Notifications. The User may check this privacy policy to find a detailed explanation about the other tools used by the Owner. This Application uses identifiers for mobile devices and technologies similar to cookies to run the Firebase Analytics service. Users may opt-out of certain Firebase features through applicable device settings, such as the device advertising settings for mobile phones or by following the instructions in other Firebase related sections of this privacy policy, if available.</p>
      <p>Personal Data processed: Application opens; Application updates; device information; first launches; geography/region; In-app purchases; number of sessions; number of Users ; operating systems; session duration; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Google Analytics with anonymized IP (Google LLC)</p>
      <p>Google Analytics is a web analysis service provided by Google LLC (&ldquo;Google&rdquo;). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.</p>
      <p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
      <p>This integration of Google Analytics anonymizes your IP address. It works by shortening Users&rsquo; IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Facebook Analytics for Apps</p>
      <p>Facebook Analytics for Apps is an analytics service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from,</p>
      <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Facebook Ads conversion tracking (Facebook pixel)</p>
      <p>Facebook Ads conversion tracking (Facebook pixel) is an analytics service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, that connects data from the Facebook advertising network with actions performed on this Application. The Facebook pixel tracks conversions that can be attributed to ads on Facebook, Instagram and Audience Network.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <br/><p>Beta Testing</p>
      <p>Beta by Crashlytics (Google LLC)</p>
      <p>Beta by Crashlytics is a beta testing service provided by Google LLC.</p>
      <p>Personal Data processed: app information; country; Data communicated while using the service; date of birth; device information; device logs; email address; first name; gender; last name; Tracker; Usage Data; username; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Google Play Beta Testing (Google LLC)</p>
      <p>Google Play Beta Testing is a beta testing service provided by Google LLC.</p>
      <p>Personal Data processed: country; Data communicated while using the service; date of birth; device information; device logs; email address; first name; gender; last name; Tracker; Usage Data; username; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>TestFlight (Apple Inc.)</p>
      <p>TestFlight is a beta testing service provided by Apple Inc.</p>
      <p>Personal Data processed: app information; country; Data communicated while using the service; device information; device logs; email address; first name; gender; last name; Tracker; Usage Data; username; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Commercial affiliation</p>
      <p>This type of service allows this Application to display advertisements for third-party products or services. Ads can be displayed either as advertising links or as banners using various kinds of graphics.</p>
      <p>Clicks on the icon or banner posted on the Application are tracked by the third-party services listed below, and are shared with this Application.</p>
      <p>For details of which data are collected, please refer to the privacy policy of each service.</p>
      <p>Amazon Affiliation (Amazon)</p>
      <p>Amazon Affiliation is a commercial affiliation service provided by Amazon.com Inc.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <br/><p>Contacting the User</p>
      <p>Contact form (this Application)</p>
      <p>By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form&rsquo;s header.</p>
      <p>Personal Data processed: email address; first name; last name.</p>
      <p>Mailing list or newsletter (this Application)</p>
      <p>By registering on the mailing list or for the newsletter, the User&rsquo;s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Application. Your email address might also be added to this list as a result of signing up to this Application or after making a purchase.</p>
      <p>Personal Data processed: country; date of birth; email address; first name; gender; last name.</p>
      <br/><p>Content performance and features testing (A/B testing)</p>
      <p>The services contained in this section allow the Owner to track and analyze the User response concerning web traffic or behavior regarding changes to the structure, text or any other component of this Application.</p>
      <p>Firebase Remote Config (Google LLC)</p>
      <p>Firebase Remote Config is an A/B testing and configuration service provided by Google LLC.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Google Optimize (Google LLC)</p>
      <p>Google Optimize is an A/B testing service provided by Google LLC (&ldquo;Google&rdquo;). Google may use Personal Data to contextualize and personalize the ads of its own advertising network.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Google Optimize 360</p>
      <p>Google Optimize 360 is an A/B testing service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from, (&ldquo;Google&rdquo;). This service allows the Owner to target groups of Users on the base of their past use of this Application. Users that opt-out of tracking by Google Analytics will not be included in experiments created in Google Optimize 360. Google may use Personal Data to contextualize and personalize the ads of its own advertising network.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <br/><p>Data transfer outside the EU</p>
      <p>The Owner is allowed to transfer Personal Data collected within the EU to third countries (i.e. any country not part of the EU) only pursuant to a specific legal basis. Any such Data transfer is based on one of the legal bases described below.</p>
      <p>Users can inquire with the Owner to learn which legal basis applies to which specific service.</p>
      <p>Data transfer abroad based on consent (this Application)</p>
      <p>If this is the legal basis, Personal Data of Users shall be transferred from the EU to third countries only if the User has explicitly consented to such transfer, after having been informed of the possible risks due to the absence of an adequacy decision and appropriate safeguards.</p>
      <p>In such cases, the Owner shall inform Users appropriately and collect their explicit consent via this Application.</p>
      <p>Personal Data processed: various types of Data.</p>
      <p>Data transfer abroad based on standard contractual clauses (this Application)</p>
      <p>If this is the legal basis, the transfer of Personal Data from the EU to third countries is carried out by the Owner according to &ldquo;standard contractual clauses&rdquo; provided by the European Commission.</p>
      <p>This means that Data recipients have committed to process Personal Data in compliance with the data protection standards set forth by EU data protection legislation. For further information, Users are requested to contact the Owner through the contact details provided in the present document.</p>
      <p>Personal Data processed: various types of Data.</p>
      <p>Data transfer to countries that guarantee European standards (this Application)</p>
      <p>If this is the legal basis, the transfer of Personal Data from the EU to third countries is carried out according to an adequacy decision of the European Commission.</p>
      <p>The European Commission adopts adequacy decisions for specific countries whenever it considers that country to possess and provide Personal Data protection standards comparable to those set forth by EU data protection legislation. Users can find an updated list of all adequacy decisions issued on the European Commission&rsquo;s website.</p>
      <p>Personal Data processed: various types of Data.</p>
      <p>Other legal basis for Data transfer abroad (this Application)</p>
      <p>If no other legal basis applies, Personal Data shall be transferred from the EU to third countries only if at least one of the following conditions is met:</p>
      <p>the transfer is necessary for the performance of a contract between the User and the Owner or of pre-contractual measures taken at the User&rsquo;s request;</p>
      <p>the transfer is necessary for the conclusion or performance of a contract concluded in the interest of the User between the Owner and another natural or legal person;</p>
      <p>the transfer is necessary for important reasons of public interest;</p>
      <p>the transfer is necessary for establishment, exercise or defence of legal claims;</p>
      <p>the transfer is necessary in order to protect the vital interests of the data subject or of other persons, where the data subject is physically or legally incapable of giving consent. In such cases, the Owner shall inform the User about the legal bases the transfer is based on via this Application.</p>
      <p>Personal Data processed: various types of Data.</p>
      <p>Device permissions for Personal Data access</p>
      <p>This Application requests certain permissions from Users that allow it to access the User&rsquo;s device Data as described below.</p>
      <p>Device permissions for Personal Data access (this Application)</p>
      <p>This Application requests certain permissions from Users that allow it to access the User&rsquo;s device Data as summarized here and described within this document.</p>
      <p>Personal Data processed: Camera permission; Camera permission, without saving or recording; Health Data read permission; Health Data update permission ; Photo Library permission; Reminders permission; Storage permission.</p>
      <br/><p>Handling activity data</p>
      <p>This type of service allows the Owner to use the activity data collected by your device in order for this Application to operate or to provide specific features. This may include movements, heartbeat, change in altitude or data about the surroundings.</p>
      <p>Depending on what is described below, third parties may be involved in the activity tracking.</p>
      <p>Most devices allow for the User to control which Data is accessed or stored.</p>
      <p>Apple HealthKit (Apple Inc.)</p>
      <p>HealthKit is an activity data handling service provided by Apple Inc. that enables the Owner to access or store activity data.</p>
      <p>Personal Data processed: blood type; body measurements &amp; indexes; date of birth; food related activity ; gender; general activity data; movement activity; sleeping activity.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Activity data tracked by your device (this Application)</p>
      <p>This Application uses some activity data tracked by your device to operate or to provide specific features.</p>
      <p>Personal Data processed: body measurements &amp; indexes; date of birth; food related activity ; gender; general activity data.</p>
      <br/><p>Handling payments</p>
      <p>Unless otherwise specified, this Application processes any payments by credit card, bank transfer or other means via external payment service providers. In general and unless where otherwise stated, Users are requested to provide their payment details and personal information directly to such payment service providers. This Application isn&rsquo;t involved in the collection and processing of such information: instead, it will only receive a notification by the relevant payment service provider as to whether payment has been successfully completed.</p>
      <p>Apple Pay (Apple Inc.)</p>
      <p>Apple Pay is a payment service provided by Apple Inc., which allows Users to make payments using their mobile phones.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <br/><p>Hosting and backend infrastructure</p>
      <p>This type of service has the purpose of hosting Data and files that enable this Application to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Application.</p>
      <p>Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>
      <p>Firebase Cloud Functions</p>
      <p>Firebase Cloud Functions is a hosting and backend service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Cloud Firestore</p>
      <p>Firebase Cloud Firestore is a hosting and backend service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Hosting</p>
      <p>Firebase Hosting is a hosting service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Cloud Storage</p>
      <p>Firebase Cloud Storage is a hosting service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Realtime Database</p>
      <p>Firebase Realtime Database is a hosting and backend service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <br/><p>Infrastructure monitoring</p>
      <p>This type of service allows this Application to monitor the use and behavior of its components so its performance, operation, maintenance and troubleshooting can be improved.</p>
      <p>Which Personal Data are processed depends on the characteristics and mode of implementation of these services, whose function is to filter the activities of this Application.</p>
      <p>Crashlytics with User opt-in</p>
      <p>Crashlytics with User opt-in is a monitoring service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. This Application allows Users to choose to opt in to the collection of crash reports or analytics.</p>
      <p>Personal Data processed: Crash information; device information; Universally unique identifier (UUID).</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <br/><p>Crashlytics</p>
      <p>Crashlytics is a monitoring service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: Crash information; device information; Universally unique identifier (UUID).</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Performance Monitoring</p>
      <p>Firebase Performance Monitoring is a monitoring service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Interaction with external social networks and platforms</p>
      <p>This type of service allows interaction with social networks or other external platforms directly from the pages of this Application.</p>
      <p>The interaction and information obtained through this Application are always subject to the User&rsquo;s privacy settings for each social network.</p>
      <p>This type of service might still collect traffic data for the pages where the service is installed, even when Users do not use it.</p>
      <p>It is recommended to log out from the respective services in order to make sure that the processed data on this Application isn&rsquo;t being connected back to the User&rsquo;s profile.</p>
      <p>Twitter Tweet button and social widgets (Twitter, Inc.)</p>
      <p>The Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter, Inc.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Facebook Like button and social widgets</p>
      <p>The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from,</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Pinterest &ldquo;Pin it&rdquo; button and social widgets (Pinterest, Inc.)</p>
      <p>The Pinterest &ldquo;Pin it&rdquo; button and social widgets are services allowing interaction with the Pinterest platform provided by Pinterest Inc.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Location-based interactions</p>
      <br/><p>Geolocation (this Application)</p>
      <p>This Application may collect, use, and share User location Data in order to provide location-based services.</p>
      <p>Most browsers and devices provide tools to opt out from this feature by default. If explicit authorization has been provided, the User&rsquo;s location data may be tracked by this Application.</p>
      <p>Personal Data processed: geographic position.</p>
      <br/><p>Non-continuous geolocation (this Application)</p>
      <p>This Application may collect, use, and share User location Data in order to provide location-based services.</p>
      <p>Most browsers and devices provide tools to opt out from this feature by default. If explicit authorization has been provided, the User&rsquo;s location data may be tracked by this Application.</p>
      <p>The geographic location of the User is determined in a manner that isn&rsquo;t continuous, either at the specific request of the User or when the User doesn&rsquo;t point out its current location in the appropriate field and allows the application to detect the position automatically.</p>
      <p>Personal Data processed: geographic position.</p>
      <br/><p>Managing contacts and sending messages</p>
      <p>This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.</p>
      <p>These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.</p>
      <p>Firebase Cloud Messaging</p>
      <p>Firebase Cloud Messaging is a message sending service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. Firebase Cloud Messaging allows the Owner to send messages and notifications to Users across platforms such as Android, iOS, and the web. Messages can be sent to single devices, groups of devices, or specific topics or User segments.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Notifications</p>
      <p>Firebase Notifications is a message sending service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. Firebase Notifications can be integrated with Firebase Analytics to target analytics-based audiences and track opening and conversion events.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <br/><p>Managing data collection and online surveys</p>
      <p>This type of service allows this Application to manage the creation, deployment, administration, distribution and analysis of online forms and surveys in order to collect, save and reuse Data from any responding Users.</p>
      <p>The Personal Data collected depend on the information asked and provided by the Users in the corresponding online form.</p>
      <p>These services may be integrated with a wide range of third-party services to enable the Owner to take subsequent steps with the Data processed &ndash; e.g. managing contacts, sending messages, analytics, advertising and payment processing.</p>
      <p>Facebook lead ads</p>
      <p>Facebook lead ads is an advertising and data collection service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, that allows form-based ads to be shown to Users pre-populated with Personal Data from their Facebook profiles, such as names and email addresses. Depending on the type of advertisement, Users may be requested to provide further information. Form submission results in the collection and processing of these Data by the Owner under this privacy policy, and only for the specific purpose outlined on the form and/or inside this privacy policy, where provided. Users may exercise their rights, at any time, including the right to withdraw their consent to the processing of their Data, as specified in the section containing information about User rights in this privacy policy.</p>
      <p>Personal Data processed: address; city; country; Data communicated while using the service; date of birth; email address; first name; gender; last name; phone number; state; Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt out; Ireland &ndash; Privacy Policy &ndash; Opt out.</p>
      <p>Platform services and hosting</p>
      <p>These services have the purpose of hosting and running key components of this Application, therefore allowing the provision of this Application from within a unified platform. Such platforms provide a wide range of tools to the Owner &ndash; e.g. analytics, user registration, commenting, database management, e-commerce, payment processing &ndash; that imply the collection and handling of Personal Data.</p>
      <p>Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>
      <p>Apple App Store (Apple Inc.)</p>
      <p>This Application is distributed on Apple&rsquo;s App Store, a platform for the distribution of mobile apps, provided by Apple Inc. By virtue of being distributed via this app store, Apple collects basic analytics and provides reporting features that enables the Owner to view usage analytics data and measure the performance of this Application. Much of this information is processed on an opt-in basis. Users may opt-out of this analytics feature directly through their device settings. More information on how to manage analysis settings can be found on this page.</p>
      <p>Personal Data processed: Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <p>Google Play Store</p>
      <p>This Application is distributed on the Google Play Store, a platform for the distribution of mobile apps, provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. By virtue of being distributed via this app store, Google collects usage and diagnostics data and share aggregate information with the Owner. Much of this information is processed on an opt-in basis. Users may opt-out of this analytics feature directly through their device settings. More information on how to manage analysis settings can be found on this page.</p>
      <p>Personal Data processed: Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <br/><p>Registration and authentication</p>
      <p>By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services.</p>
      <p>Depending on what is described below, third parties may provide registration and authentication services. In this case, this Application will be able to access some Data, stored by these third-party services, for registration or identification purposes.</p>
      <p>Some of the services listed below may also collect Personal Data for targeting and profiling purposes; to find out more, please refer to the description of each service.</p>
      <p>Firebase Authentication</p>
      <p>Firebase Authentication is a registration and authentication service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. To simplify the registration and authentication process, Firebase Authentication can make use of third-party identity providers and save the information on its platform.</p>
      <p>Personal Data processed: email address; first name; last name; password; phone number; profile picture; social media accounts ; username.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Facebook Authentication</p>
      <p>Facebook Authentication is a registration and authentication service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, and is connected to the Facebook social network.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Google OAuth</p>
      <p>Google OAuth is a registration and authentication service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from, and is connected to the Google network.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Gmail permissions to access User Data (OAuth addition)</p>
      <p>To provide its service, this Application uses restricted scopes for Gmail which allow access to User Data related to this Google service (as indicated below). This access is provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from, under strict terms. Here&rsquo;s what this means for such User Data:</p>
      <p>The use of User Data is limited to providing or improving User-facing features. User Data will not be used for serving ads, including retargeting, personalized, or interest-based advertising.</p>
      <p>User Data will be transferred to third parties only if necessary, to provide or improve User-facing features and as necessary to comply with applicable law or as part of a merger, acquisition, or sale of assets with notice to Users.</p>
      <p>Humans will not read the Data unless:</p>
      <p>the Owner has obtained the User&rsquo;s affirmative agreement for specific messages;</p>
      <p>it is necessary for security purposes and/or to comply with applicable law, or;</p>
      <p>the use is limited to internal operations and the Data (including derivations) have been aggregated and anonymized.</p>
      <p>Personal Data processed: Basic settings management; Compose; Full access; Insert and import; Modify; Read metadata; Read only access; Sensitive settings management.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Instagram Authentication</p>
      <p>Instagram Authentication is a registration and authentication service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, and is connected to the Instagram social network.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Sign in with Apple (Apple Inc.)</p>
      <p>Sign in with Apple is a registration and authentication service provided by Apple Inc. In cases where Users are required to provide their email address, Sign in with Apple may generate a private relay address on behalf of Users that automatically forwards messages to their verified personal email account &ndash; therefore shielding their actual email address from the Owner.</p>
      <p>Personal Data processed: email address; first name; last name; phone number; User ID.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>

       <br/><p>Registration and authentication provided directly by this Application</p>
      <p>By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services. The Personal Data is collected and stored for registration or identification purposes only. The Data collected are only those necessary for the provision of the service requested by the Users.</p>
      <p>Direct registration (this Application)</p>
      <p>The User registers by filling out the registration form and providing the Personal Data directly to this Application.</p>
      <p>Personal Data processed: address; city; country; date of birth; email address; first name; gender; language; last name; password; profile picture; Usage Data; username.</p>
      <br/><p>Remarketing and behavioral targeting</p>
      <p>This type of service allows this Application and its partners to inform, optimize and serve advertising based on past use of this Application by the User.</p>
      <p>This activity is facilitated by tracking Usage Data and by using Trackers to collect information which is then transferred to the partners that manage the remarketing and behavioral targeting activity.</p>
      <p>Some services offer a remarketing option based on email address lists.</p>
      <p>In addition to any opt-out feature provided by any of the services below, Users may opt out by visiting the Network Advertising Initiative opt-out page.</p>
      <p>Users may also opt-out of certain advertising features through applicable device settings, such as the device advertising settings for mobile phones or ads settings in general.</p>
      <p>AdRoll (NextRoll, Inc.)</p>
      <p>AdRoll is an advertising service provided by NextRoll, Inc. AdRoll can serve targeted advertising on any device connected to the User, by processing their email address using a security technique called hashing.</p>
      <p>AdRoll may also automatically collect certain types of data to serve personalized recommendations to the User, as stated in its privacy policy.</p>
      <p>Personal Data processed: device information; shopping history; Tracker; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data; various types of Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Facebook Remarketing</p>
      <p>Facebook Remarketing is a remarketing and behavioral targeting service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, that connects the activity of this Application with the Facebook advertising network.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Google Ads Remarketing</p>
      <p>Google Ads Remarketing is a remarketing and behavioral targeting service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from, that connects the activity of this Application with the Google Ads advertising network and the DoubleClick Cookie. Users can opt out of Google&rsquo;s use of cookies for ads personalization by visiting Google&rsquo;s Ads Settings.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Google Ad Manager Audience Extension</p>
      <p>Google Ad Manager Audience Extension is a remarketing and behavioral targeting service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from, that tracks the visitors of this Application and allows selected advertising partners to display targeted ads across the web to them.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Google Signals</p>
      <p>This Application uses Google Signals, a feature of Google Analytics, which will associate the visitation information that it collects from this Application with Google information from accounts of signed-in Google-account users who have consented to this association for the purpose of ads personalization. This Google information may include User location, search history, YouTube history and Data from sites that partner with Google &ndash; and is used to provide aggregated and anonymized insights into Users&rsquo; cross device behaviors. If a User falls under the described association, they may access and/or delete such Data via My Activity provided by Google.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>
      <p>Facebook Custom Audience</p>
      <p>Facebook Custom Audience is a remarketing and behavioral targeting service provided by Facebook, Inc. or by Facebook Ireland Ltd, depending on the location this Application is accessed from, that connects the activity of this Application with the Facebook advertising network. Users can opt out of Facebook&rsquo;s use of cookies for ads personalization by visiting this opt-out page.</p>
      <p>Personal Data processed: email address; Tracker.</p>
      <p>Place of processing: United States &ndash; Privacy Policy &ndash; Opt Out; Ireland &ndash; Privacy Policy &ndash; Opt Out.</p>

      <br/><p>RSS feed management</p>
      <p>This type of service allows this Application to manage RSS feeds and the distribution of their content. Depending on the characteristics of the service used, these services may be used to insert ads within the content and to gather statistics data from them.</p>
      <p>Feedburner (Google LLC)</p>
      <p>Feedburner is a service to manage RSS feeds provided by Google LLC. It allows the collection of statistics on what content is consumed and to insert ads in it.</p>
      <p>Personal Data processed: Tracker; Usage Data.</p>
      <p>Place of processing: United States &ndash; Privacy Policy.</p>
      <br/><p>Social features</p>
      <p>Firebase Dynamic Links</p>
      <p>Firebase Dynamic Links is a social feature provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. Dynamic Links are tracked within Firebase or Google Analytics for Firebase, which informs the Owner about the details of the User journey to and within this Application.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Firebase Invites</p>
      <p>Firebase Invites is a social feature provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from, that enables Users to share this Application. The sharing may contain referral codes, or content from within this Application and may be done via email or SMS. Sharing is tracked with Google Analytics for Firebase, which informs the Owner that the User has opened or installed this Application via invite.</p>
      <p>Personal Data processed: various types of Data as specified in the privacy policy of the service.</p>
      <p>Place of processing: United States &ndash; Privacy Policy; Ireland &ndash; Privacy Policy.</p>
      <p>Inviting and suggesting friends (this Application)</p>
      <p>This Application may use the Personal Data provided to allow Users to invite their friends &ndash; for example through the address book, if access has been provided &ndash; and to suggest friends or connections inside it.</p>
      <p>Personal Data processed: various types of Data.</p>
      <p>Public profile (this Application)</p>
      <p>Users may have public profiles that other Users can display. In addition to the Personal Data provided, this profile may contain Users&rsquo; interactions with this Application.</p>
      <p>Personal Data processed: first name; gender; last name; picture; username.</p>
      <p>Further information about Personal Data</p>
      <p>Analysis and predictions based on the User&rsquo;s Data (&ldquo;profiling&rdquo;)</p>
      <p>The Owner may use the Personal and Usage Data collected through this Application to create or update User profiles. This type of Data processing allows the Owner to evaluate User choices, preferences and behaviour for the purposes outlined in the respective section of this document.</p>
      <p>User profiles can also be created through the use of automated tools like algorithms, which can also be provided by third parties. To find out more about the profiling activities performed, Users can check the relevant sections of this document.</p>
      <p>The User always has a right to object to this kind of profiling activity. To find out more about the User&rsquo;s rights and how to exercise them, the User is invited to consult the section of this document outlining the rights of the User.</p>
      <br/><p>Automated decision-making</p>
      <p>Automated decision-making means that a decision which is likely to have legal effects or similarly significant effects on the User, is taken solely by technological means, without any human intervention. This Application may use the User&rsquo;s Personal Data to make decisions entirely or partially based on automated processes according to the purposes outlined in this document. This Application adopts automated decision-making processes as far as necessary to enter into or perform a contract between User and Owner, or on the basis of the User&rsquo;s explicit consent, where such consent is required by the law.</p>
      <p>Automated decisions are made by technological means &ndash; mostly based on algorithms subject to predefined criteria &ndash; which may also be provided by third parties.</p>
      <p>The rationale behind the automated decision making is:</p>
      <p>to enable or otherwise improve the decision-making process;</p>
      <p>to grant Users fair and unbiased treatment based on consistent and uniform criteria;</p>
      <p>to reduce the potential harm derived from human error, personal bias and the like which may potentially lead to discrimination or imbalance in the treatment of individuals etc.;</p>
      <p>to reduce the risk of User&rsquo;s failure to meet their obligation under a contract. To find out more about the purposes, the third-party services, if any, and any specific rationale for automated decisions used within this Application, Users can check the relevant sections in this document.</p>
      <p>Consequences of automated decision-making processes for Users and rights of Users subjected to it</p>
      <p>As a consequence, Users subject to such processing, are entitled to exercise specific rights aimed at preventing or otherwise limiting the potential effects of the automated decisions taken.</p>
      <p>In particular, Users have the right to:</p>
      <p>obtain an explanation about any decision taken as a result of automated decision-making and express their point of view regarding this decision;</p>
      <p>challenge a decision by asking the Owner to reconsider it or take a new decision on a different basis;</p>
      <p>request and obtain from the Owner human intervention on such processing.</p>
      <p>To learn more about the User&rsquo;s rights and the means to exercise them, the User is invited to consult the section of this document relating to the rights of the User.</p>
      <br/><p>Push notifications</p>
      <p>This Application may send push notifications to the User to achieve the purposes outlined in this privacy policy. Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then change those settings for this Application, some or all of the apps on the particular device.</p>
      <p>Users must be aware that disabling push notifications may negatively affect the utility of this Application.</p>
      <p>Push notifications based on the User&rsquo;s geographic location</p>
      <p>This Application may use the User&rsquo;s geographic location to send push notifications for the purposes outlined in this privacy policy. Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then changing those settings for some or all of the apps on the particular device. Users must be aware that disabling push notifications may negatively affect the utility of this Application.</p>
      <br/><p>localStorage</p>
      <p>localStorage allows this Application to store and access data right in the User&rsquo;s browser with no expiration date.</p>
      <p>Personal Data collected through sources other than the User</p>
      <p>The Owner of this Application may have legitimately collected Personal Data relating to Users without their knowledge by reusing or sourcing them from third parties on the grounds mentioned in the section specifying the legal basis of processing.</p>
      <p>Where the Owner has collected Personal Data in such a manner, Users may find specific information regarding the source within the relevant sections of this document or by contacting the Owner.</p>
      <br/><p>Push notifications for direct marketing</p>
      <p>This Application may send push notifications to the User for the purpose of direct marketing (to propose services and products provided by third parties or unrelated to the product or service provided by this Application). Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then changing those settings for this Application or all of the apps on the particular device. Users must be aware that disabling push notifications may negatively affect the utility of this Application. Besides applicable device settings, the User may also make use of the rights described under User rights in the relevant section of this privacy policy.</p>
      <br/><p>Rights for registered California Users under the age of 18</p>
      <p>California&rsquo;s &ldquo;Online Eraser&rdquo; law, part of California&rsquo;s Business and Professions Code Sections 22580-22582, requires operators of certain websites and online services targeting minors to allow registered Users who are under the age of 18 and residents of California to request removal of content they post. If a registered User fits that description and posted content on this Application, they may request removal of such content by contacting the Owner or its privacy policy coordinator at the contact details provided in this document. In response to this request, the Owner may make content posted by the registered User invisible to other registered Users and the public (rather than deleting it entirely), in which case the content may remain on the Owner&rsquo;s servers. It may also be publicly available elsewhere if a third party copied and reposted this content.</p>

      <p>The Service is intended for adults</p>
      <p>Users declare themselves to be adult according to their applicable legislation. Minors may not use this Application.</p>

      <p>The Service is not directed to children under the age of 13</p>
      <p>Users declare themselves to be adult according to their applicable legislation. Minors may use this Application only with the assistance of a parent or guardian. Under no circumstance persons under the age of 13 may use this Application.</p>

      <br/><p>Unique device identification</p>
      <p>This Application may track Users by storing a unique identifier of their device, for analytics purposes or for storing Users&rsquo; preferences.</p>
      <p>User identification via a universally unique identifier (UUID)</p>
      <p>This Application may track Users by storing a so-called universally unique identifier (or short UUID) for analytics purposes or for storing Users&rsquo; preferences. This identifier is generated upon installation of this Application, it persists between Application launches and updates, but it is lost when the User deletes the Application. A reinstall generates a new UUID.</p>
      <br/><p>The rights of Users</p>
      <p>Users may exercise certain rights regarding their Data processed by the Owner.</p>
      <p>In particular, Users have the right to do the following:</p>
      <p>Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</p>
      <p>Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</p>
      <p>Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</p>
      <p>Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</p>
      <p>Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</p>
      <p>Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</p>
      <p>Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User&rsquo;s consent, on a contract which the User is part of or on pre-contractual obligations thereof.</p>
      <p>Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.</p>
      <p>Details about the right to object to processing</p>
      <p>Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</p>
      <p>Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.</p>
      <br/><p>How to exercise these rights</p>
      <p>Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</p>
      <p>Additional information about Data collection and processing</p>
      <br/><p>Legal action</p>
      <p>The User&rsquo;s Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.</p>
      <p>The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</p>
      <p>Additional information about User&rsquo;s Personal Data</p>
      <p>In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</p>
      <p>System logs and maintenance</p>
      <p>For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.</p>
      <p>Information not contained in this policy</p>
      <p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>
      <p>How &ldquo;Do Not Track&rdquo; requests are handled</p>
      <p>This Application does not support &ldquo;Do Not Track&rdquo; requests.</p>
      <p>To determine whether any of the third-party services it uses honor the &ldquo;Do Not Track&rdquo; requests, please read their privacy policies.</p>
      <p>Changes to this privacy policy</p>
      <p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or &ndash; as far as technically and legally feasible &ndash; sending a notice to Users via any contact information available to the Owner. It is h3ly recommended to check this page often, referring to the date of the last modification listed at the bottom.</p>
      <p>Should the changes affect processing activities performed on the basis of the User&rsquo;s consent, the Owner shall collect new consent from the User, where required.</p>
      <p>Definitions and legal references</p>
      <br/><p>Personal Data (or Data)</p>
      <p>Any information that directly, indirectly, or in connection with other information &mdash; including a personal identification number &mdash; allows for the identification or identifiability of a natural person.</p>
      <br/><p>Usage Data</p>
      <p>Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server&rsquo;s answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User&rsquo;s IT environment.</p>
      <br/><p>User</p>
      <p>The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</p>
      <br/><p>Data Subject</p>
      <p>The natural person to whom the Personal Data refers.</p>
      <p>Data Processor (or Data Supervisor)</p>
      <p>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</p>
      <p>Data Controller (or Owner)</p>
      <p>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</p>
      <p>This Application</p>
      <p>The means by which the Personal Data of the User is collected and processed.</p>
      <br/><p>Service</p>
      <p>The service provided by this Application as described in the relative terms (if available) and on this site/application.</p>
      <p>European Union (or EU)</p>
      <p>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</p>
      <br/><p>Cookie</p>
      <p>Cookies are Trackers consisting of small sets of data stored in the User&rsquo;s browser.</p>
      <br/><p>Tracker</p>
      <p>Tracker indicates any technology &ndash; e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting &ndash; that enables the tracking of Users, for example by accessing or storing information on the User&rsquo;s device.</p>
      <br/><p>Full access</p>
      <p>Full access to the account, including permanent deletion of threads and messages.</p>
      <br/><p>Read only access</p>
      <p>Read all resources and their metadata&mdash;no write operations.</p>
      <br/><p>Read metadata</p>
      <p>Read resources metadata including labels, history records, and email message headers, but not the message body or attachments.</p>
      <br/><p>Modify</p>
      <p>All read/write operations except immediate, permanent deletion of threads and messages, bypassing Trash.</p>
      <br/><p>Insert and import</p>
      <p>Insert and import messages only.</p>
      <br/><p>Compose</p>
      <p>Create, read, update, and delete drafts. Send messages and drafts.</p>
      <br/><p>Basic settings management</p>
      <p>Manage basic mail settings.</p>
      <p>Sensitive settings management</p>
      <p>Manage sensitive mail settings, including forwarding rules and aliases.</p>
      <br/><p>Legal information</p>
      <p>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
      <p>This privacy policy relates solely to this Application, if not stated otherwise within this document.</p>
      <br/><p>Latest update: February 09, 2021</p>
   </div>
</div>
    );
}

export default PrivacyPolicyEn;