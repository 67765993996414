import Nawl2 from '../assets/newappswelove2.webp';
import Weeklyf from '../assets/weeklyfavorites.webp';
import { FaStar } from 'react-icons/fa';
import { IconContext } from "react-icons";

function Social() {
  return (
    <div className="social">
        <div className="mainsocial">
            <div class="socialsectionimg">
                <img src={Nawl2} alt="" />
                <img src={Weeklyf} alt="" />
            </div>
            <div className="ratings">
                <div class="testimonials itemleft">
                    <div class="listing-rating">
                        <IconContext.Provider value={{ color: "#F0D355", size: '2em' }}>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </IconContext.Provider>
                    </div>
                    <h3>Sophia</h3>  
                    <p>“The meal plan helped me to achieve my weight loss goal. The nutrient values gave me a great overview and I also really like the big variety of recipes.”</p>
                </div>
                <div class="testimonials itemmid">
                    <div class="listing-rating">
                        <IconContext.Provider value={{ color: "#F0D355", size: '2em' }}>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </IconContext.Provider>
                    </div>
                    <h3>Diane</h3>  
                    <p>“The guides were really helpful at the start of the transition and the recipes are super easy and delicious.”</p>
                </div>
                <div class="testimonials itemright">
                    <div class="listing-rating">
                        <IconContext.Provider value={{ color: "#F0D355", size: '2em' }}>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </IconContext.Provider>
                    </div>
                    <h3>Peter</h3>  
                    <p>“I have already tried like 20 recipes and i love it! The shopping list feature is such a nice addition!”</p>
                </div>
            </div>
        </div>
        <div class="socialend">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#FCFCF7" fill-opacity="1" d="M0,96L120,128C240,160,480,224,720,224C960,224,1200,160,1320,128L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
            </svg>
        </div>
    </div>
  )
}
export default Social;